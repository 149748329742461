import React, { useRef, useState } from 'react'
import { useNavigate, Outlet, useLocation, NavLink } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import useTimeLog from './Hooks/useTimeLog'
import moment from 'moment/moment'
import TimeLogFilters from './TimeLogFilters'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import { Button, Fab } from '@mui/material'
import Modal from '../../components/Modal'
import { uploadFileUrlTimeLog } from '../../repository/taskRepo'
import { SubTaskList, TaskCategoryList, complexityList, hoursTypeList, actutalStatusList, CKEditorCustomToolbar } from "../../constants"
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import SelectInput from '../../components/SelectInput'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import ReassignTaskForm from "./ReassignTaskForm"
import TimeLogTimeLine from './TimeLogTimeLine'
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add'
import { Fragment } from 'react'
import { Fab, Button, Tabs, Tab, AppBar } from '@mui/material'
import { calculateWorkHours, removeHtmlTags } from '../../utils'

const TimeLog = () => {
  // const { userTimeLog } = useParams()
  const fileInputRef = useRef(null)
  const scrollRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()

  const { data, methods } = useTimeLog()
  const { isLoading, TimeLogList, timeLogDetails, timeLogPermission,
    timelogFillInputs,
    timeLogError,
    taskModel,
    selectedFiles,
    userTimeLog,
    deleteModal,
    timeLineModal,
    breakHoursInput,
    breakErrors,
    breakModal,
    breakForm,
  } = data
  const { setTimeLogDetailsModel, getLogDetails, Export,
    breakUpdate,
    setBreakForm,
    setTimeLoFillInput,
    setTimeLogError,
    handleTimeLogInputChange,
    SubmitLogTime,
    ApprovalStatus,
    CloseFucntion,
    setTaskModel,
    handleLogRemoveFile,
    handleTimeLogFileChange,
    setDeleteModal,
    handleDelete,
    setTimeLineModal,
    removeBreak,
    addMultipleBreak,
    handleBrakeTime,
    setBreakErrors,
    handleBreakInput,
    setBreakModal
    // setBreakModal,
    // submitBreak,
    // CloseFucntion
  } = methods
  const { approvalSelf, approvalTeam, approvalAll,} = timeLogPermission?.userInfo?.permission?.timelog
  // console.log(TimeLogList, 'TimeLogList')
  // console.log(timeLogDetails,'timeLogDetails')

  let allTabs = [
    {
      label: 'Time Log',
      value: `/time-log/${userTimeLog}`,
    },
  ]

  const clickHandler = (event, timeLogId) => {
    if (event.detail === 1) {
      getLogDetails(timeLogId)
      setTimeLogDetailsModel((prev) => ({ ...prev, timeLogId: timeLogId, open: true }))
    }
  }
  const [showPDFModal, setShowPDFModal] = useState({ open: false, data: '' })

  const fileType = (file, fileIndex) => {
    let type = file?.split('.').pop()

    if (['png', 'jpeg', 'jpg'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={uploadFileUrlTimeLog + file} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['mp4'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['pdf'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['xlsx', 'xls'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else if (['txt', 'doc', 'odt'].includes(type)) {
      return (
        <>
          <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
        </>
      )
    } else {
      return (
        <>
          <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
        </>
      )
    }
  }


  // show Attetchment's
  const filePreview = (file) => {
    let type = file?.split('.').pop()
    if (['png', 'jpeg', 'jpg'].includes(type)) {
      setShowPDFModal({ open: true, data: file })
    } else window.open(`${uploadFileUrlTimeLog}${file}`)
  }

  const ApprovalButtons = (item) => {
    if ((approvalSelf?.value || approvalAll?.value) && timeLogPermission?.userInfo?._id === userTimeLog) {
      // console.log("one","this condition for  user has own approval permission like he can approve self task ,edit , view, delete,reassign ")
      return <div>
        <Button size="small" className="me-2"
          onClick={() => setTaskModel({ open: true, data: item?.task_id })}
        >
          <AssignmentReturnIcon /> &nbsp; ReAssign
        </Button>

        {item?.status !== "APPROVED" && <Button size="small" className="me-2"
          onClick={() => ApprovalStatus(item._id, "APPROVED")}
        >
          <CheckIcon /> &nbsp; Approve
        </Button>}
        {item?.status !== "UNAPPROVED" && <Button size="small" color="secondary" className="me-2"
          onClick={() => ApprovalStatus(item._id, "UNAPPROVED")}
        >
          <CloseIcon /> &nbsp; UnApprove
        </Button>}

        <Button size="small"
          onClick={(event) => clickHandler(event, item._id)}
        >
          {item?.status === "APPROVED" ? <><PreviewIcon /> &nbsp; View</> : <> <EditIcon /> &nbsp; Edit</>}
        </Button>

        {item?.status !== "APPROVED" && <Button size="small"
          onClick={() => setDeleteModal({ data: item._id, open: true })}
        >
          <><AutoDeleteIcon /> &nbsp; Delete</>
        </Button>}
        <Button size="small"
          onClick={() => setTimeLineModal({ data: item._id, open: true })}
        >
          <><HistoryIcon /> &nbsp; Time Line</>
        </Button>
      </div >
    }
    if ((approvalTeam?.value || approvalAll?.value) && timeLogPermission?.userInfo?._id !== userTimeLog) {
      // console.log("two","this condition for  user has team task approval permission only team mate task approve or view ")
      return <div>
        <Button size="small" className="me-2"
          onClick={() => setTaskModel({ open: true, data: item?.task_id })}
        >
          <AssignmentReturnIcon /> &nbsp; ReAssign
        </Button>

        {item?.status !== "APPROVED" && <Button size="small" className="me-2"
          onClick={() => ApprovalStatus(item._id, "APPROVED")}
        >
          <CheckIcon /> &nbsp; Approve
        </Button>}
        {item?.status !== "UNAPPROVED" && <Button size="small" color="secondary" className="me-2"
          onClick={() => ApprovalStatus(item._id, "UNAPPROVED")}
        >
          <CloseIcon /> &nbsp; UnApprove
        </Button>}
        <Button size="small"
          onClick={(event) => clickHandler(event, item._id)}
        >
          <><PreviewIcon /> &nbsp; View</>
        </Button>
        <Button size="small"
          onClick={() => setTimeLineModal({ data: item._id, open: true })}
        >
          <><HistoryIcon /> &nbsp; Time Line</>
        </Button>
      </div >
    }
    if ((!approvalTeam?.value || !approvalAll?.value) && timeLogPermission?.userInfo?._id !== userTimeLog) {
      // console.log("three","this condition for  user has no approval permission only view team mate task not approve")
      return <div>
        <Button size="small"
          onClick={(event) => clickHandler(event, item._id)}
        >
          {<><PreviewIcon /> &nbsp; View</>}
        </Button>
        <Button size="small"
          onClick={() => setTimeLineModal({ data: item._id, open: true })}
        >
          <><HistoryIcon /> &nbsp; Time Line</>
        </Button>
      </div>
    }
    if ((!approvalTeam?.value || !approvalAll?.value) && timeLogPermission?.userInfo?._id === userTimeLog) {
      // console.log("fore","this condition for  user has no approval permission only view, edit ,or delete self task")
      return <div>
        <Button size="small"
          onClick={(event) => clickHandler(event, item._id)}
        >
          {item?.status === "APPROVED" ? <><PreviewIcon /> &nbsp; View</> : <> <EditIcon /> &nbsp; Edit</>}
        </Button>
        {item?.status !== "APPROVED" && <Button size="small"
          onClick={() => setDeleteModal({ data: item._id, open: true })}
        >
          <><AutoDeleteIcon /> &nbsp; Delete</>
        </Button>}

        <Button size="small"
          onClick={() => setTimeLineModal({ data: item._id, open: true })}
        >
          <><HistoryIcon /> &nbsp; Time Line</>
        </Button>

      </div>
    }
  }
  // console.log(breakForm,'breakForm')
  // function calculateWorkHours(duration) {
  //   const hours = Math.floor(duration.asHours());
  //   const remainingMinutes = duration.minutes();
  //   let result = '';

  //   if (hours > 0) {
  //     result += `${hours} hour${hours > 1 ? 's' : ''}`;
  //   }

  //   if (remainingMinutes > 0) {
  //     result += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
  //   }
  //   return result;
  // }

  const timeLogDuration = moment.duration(timeLogDetails?.data?.workDuration, 'minutes');
  const totalWorkHours = calculateWorkHours(timeLogDuration);

  const shiftMins = moment.duration(TimeLogList[0]?.shiftMins, 'minutes');
  const newshiftMins = calculateWorkHours(shiftMins);

  // let totalLogedours = TimeLogList.reduce((count, item) => count + item?.workDuration, 0) - TimeLogList[0]?.totalBreakTaken
  let totalLogedours = TimeLogList[0]?.loggedHours
  const workDurationDuration = moment.duration(totalLogedours, 'minutes');
  const LoggedHours = calculateWorkHours(workDurationDuration);
  let spareHours = (TimeLogList[0]?.shiftMins - totalLogedours) > 0 ? TimeLogList[0]?.shiftMins - totalLogedours : 0
  spareHours = moment.duration(spareHours, 'minutes');
  let punchHours = moment.duration(TimeLogList[0]?.punchedHrs, 'minutes')

  const takeBreakTime = (item) => {
    return moment.duration(moment(item?.userBreaks?.endTime).diff(moment(item?.userBreaks?.startTime), 'minutes'), 'minutes')
  }
  const takeBreakTimeT = (item) => {
    if (item?.userBreaks && item.userBreaks.startTime && item.userBreaks.endTime) {
      const startTime = moment(item.userBreaks.startTime);
      const endTime = moment(item.userBreaks.endTime);
      const durationSeconds = endTime.diff(startTime, 'seconds');
      const durationMinutes = endTime.diff(startTime, 'minutes');

      if (durationSeconds < 60) {
        return `${durationSeconds} seconds`;
      } else {
        return `${durationMinutes} minutes`;
      }
    } else {
      // If startTime or endTime is missing, return an appropriate message or handle it according to your needs
      return "Duration not available";
    }
  }
  const breakTime = (item) => {
    return moment.duration(item?.duration, 'minutes')
  }


  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-end">
            <div className="ms-2">
              <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-lg-10 ticet">
            {/* <div className='timrloguserbg'> */}
            <div className='row'>
              <div className="col-4">
                <div className="col border rounded  shadow-sm  py-4 bg-white">
                  <b className='ps-2'>User Details</b>
                  <hr />
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Name  </b></span>
                    {TimeLogList[0]?.userName}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Role  </b></span>
                    {TimeLogList[0]?.roleTitle}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Email  </b></span>
                    {TimeLogList[0]?.email}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Date  </b></span>
                    {moment(TimeLogList[0]?.reportDate).format("ll")}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="col border rounded  shadow-sm  py-4 bg-white">
                  <b className='ps-2'>Work Details</b>
                  <hr />
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Shift Hour  </b></span>
                    {newshiftMins}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Punch Hour  </b></span>
                    {calculateWorkHours(punchHours)}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Logged Hour  </b></span>
                    {LoggedHours}
                  </div>
                  <div className='d-flex justify-content-between p-2'>
                    <span> <b>Spare Hour  </b></span>
                    {calculateWorkHours(spareHours)}
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="col border rounded  shadow-sm  py-4 bg-white" style={{ minHeight: '217px' }}>
                  <b className='ps-2'>Breaks Details</b>
                  <hr />
                  {
                    TimeLogList?.[0]?.userBreak?.map((item) =>
                      <div className='d-flex justify-content-between p-2'>
                        <span> <b>{removeHtmlTags(item?.reason)}  </b>({calculateWorkHours(breakTime(item))})</span>
                        <span className={`${takeBreakTime(item) > breakTime(item) ? 'text-danger' : 'text-dark'}`}>
                          {
                            item?.userBreaks?.startTime && item?.userBreaks?.endTime ?
                              <div>
                                {`${moment(item.userBreaks.startTime).format('LT')} - ${moment(item.userBreaks.endTime).format('LT')} (${takeBreakTimeT(item)})`}
                                {
                                  timeLogPermission?.userInfo?.permission?.breaks?.edit?.value &&
                                  <EditIcon onClick={() => {
                                    setBreakModal((...pre) => ({ ...pre, open: true }));
                                    setBreakForm({ startTime: moment(item?.userBreaks?.startTime).toDate(), endTime: moment(item?.userBreaks?.endTime).toDate(), breakId: item?.userBreaks?._id, shiftBreak_id: item?.breakId })
                                  }}
                                    sx={{ fontSize: '20px', cursor: 'pointer' }} />
                                }

                              </div> :
                              // null
                              timeLogPermission?.userInfo?.permission?.breaks?.edit?.value &&
                              <EditIcon onClick={() => {
                                setBreakModal((...pre) => ({ ...pre, open: true }));
                                setBreakForm({ startTime: moment(item?.userBreaks?.startTime).toDate(), endTime: moment(item?.userBreaks?.endTime).toDate(), breakId: item?.userBreaks?._id, shiftBreak_id: item?.breakId })
                              }}
                                sx={{ fontSize: '20px', cursor: 'pointer' }} />
                          }

                        </span>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            {/* </div> */}

            <div className="bg shadow raduus">
              {/* Time line Tab */}
              <div className="mt-2 " style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                <AppBar position="static" sx={{ background: '#fff' }}>
                  <Tabs value={location.pathname}>
                    {allTabs.map((item, i) => (
                      <Tab key={i} label={item.label} value={item.value} component={NavLink} to={item.value} />
                    ))}
                  </Tabs>
                </AppBar>
                <Outlet context={{ data, methods, ApprovalButtons, navigate }} />
              </div>



            </div>
          </div>
          <div className="col-2 ticet">
            <TimeLogFilters data={data} methods={methods} />
          </div>
        </div>
      </div>

      {/* Time Log Time Line */}
      <Modal
        size="lg"
        show={timeLineModal.open}
        onHide={() => setTimeLineModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">Time log History</h5>}
        bodyContent={
          <div className="" style={{ height: '450px', overflowY: 'auto' }} >
            <TimeLogTimeLine data={data} methods={methods} />
          </div>
        }
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{'Delete'} Time log?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {'delete'} time log? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={handleDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

      {/* View time log Detail's and edit */}
      <Modal
        size="xl"
        CloseFucntion={() => CloseFucntion("breatModal")}
        show={timeLogDetails.open}
        onHide={() => setTimeLogDetailsModel((prev) => ({ ...prev, open: true }))}
        headerContent={
          <h5 style={{ fontWeight: '600' }} className="mt-2">
            Time Log Details : {`${timeLogDetails?.data?.user_id?.firstName || "--"} ${timeLogDetails?.data?.user_id?.lastName || ""}`}
          </h5>
        }
        bodyContent={
          <div className="d-flex flex-column">
            {timeLogDetails?.data?.status === "APPROVED" || timeLogPermission?.userInfo?._id !== userTimeLog ?
              <>
                <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                  <span>
                    <b>{timeLogDetails.data.taskName}</b>
                  </span>
                  <span>
                    <b>Logged Date :</b> {moment(timeLogDetails.data?.reportDate).format('DD MMM YYYY')}
                  </span>
                </div>
                <div className="time-log-detials-border" style={{ wordWrap: "break-word", borderTop: '0' }} >
                  <span>
                    <b>Description</b>
                  </span>
                  <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.task_id?.description }}></span>
                </div>
                <div className="time-log-detials-border" style={{ borderTop: '0' }}>
                  <span>
                    <b>Notes</b>
                  </span>
                  <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.task_id?.notes }}></span>
                </div>
                <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                  <span>
                    <b>Assigned Duration :</b> {moment(timelogFillInputs?.actualStartTime).format('lll')} - {moment(timelogFillInputs?.actualEndTime).format('lll')}
                  </span>
                  <span>
                    <b>Worked Duration :</b> {moment(timeLogDetails.data?.actualStartTime).format('lll')} - {moment(timeLogDetails.data?.actualEndTime).format('lll')}
                  </span>
                </div>
                <div className="d-flex justify-content-between time-log-detials-border mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                  <div>
                    <span>
                      <b>Project :</b>{' '}
                    </span>
                    <span className="table-link" onClick={() => navigate('')}>
                      {timeLogDetails.data?.project_id?.name}
                    </span>
                  </div>
                  <span>
                    <b>Total Worked Time :</b> {totalWorkHours || "--"}
                  </span>
                </div>
                <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                  <span>
                    <b>Remarks</b>
                  </span>
                  <span className="mt-2" dangerouslySetInnerHTML={{ __html: timeLogDetails.data?.notes }}></span>
                </div>

                <div className="col-lg-12 px-0 mt-4">
                  {timeLogDetails?.data?.attachments?.length > 0 ? (
                    <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {timeLogDetails?.data?.attachments?.map((file, fileIndex) => (
                          <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => filePreview(file)}>
                            {fileType(file, fileIndex)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                      <span>No Attachments</span>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between time-log-detials-border mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                  <div>
                    <span>
                      <b>SubTask : </b>
                    </span>
                    <span className="">
                      {timeLogDetails.data?.subTask}
                    </span>
                  </div>
                  <span>
                    <b>Task Category  : </b> {timeLogDetails.data?.taskCategory}
                  </span>
                </div>
                <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                  <div className='d-flex justify-content-between'>
                    <span>
                      <b>Complexity : </b>{timeLogDetails.data?.complexity}
                    </span>
                    <span>
                      <b>Hours Type  : </b> {timeLogDetails.data?.hoursType}
                    </span>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <span>
                      <b>Actual Status  : </b> {timeLogDetails.data?.actutalStatus}
                    </span>
                  </div>
                </div>
                {timeLogDetails?.data?.task_id?.breaks?.length > 0 && (
                  <div className="row border mx-1 mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <div className="col-4 p-3"> <b>Start Break Time</b> </div>
                    <div className="col-4 p-3"><b>End Break Time</b></div>
                    <div className="col-4 p-3"><b>Reason </b></div>
                    <hr />
                    <div className="col-12 mt-3">
                      {breakHoursInput?.map((item, index) => (
                        <div className="row" key={index}>
                          <div className="col-4"><span>{moment(item?.startTime).format("lll")}</span></div>
                          <div className="col-4"><span>{moment(item?.endTime).format("lll")}</span></div>
                          <div className="col-4" style={{ wordWrap: "break-word" }}>
                            <span className="mt-2" dangerouslySetInnerHTML={{ __html: item?.reason }}></span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
              : <>
                <div className='row'>
                  <div className="col-lg-4 mt-4">
                    <SelectInput
                      Lable={'SubTask'}
                      // width={"310px"}
                      Options={SubTaskList}
                      keyName={'title'}
                      Name="subTask"
                      ClassName="form-field-design"
                      SelectValue={timelogFillInputs.subTask}
                      HandleChange={handleTimeLogInputChange}
                      // onBlur={handleBlur}
                      Error={timeLogError.subTask}
                      variant={'outlined'}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <SelectInput
                      Lable={'Task Category'}
                      // width={"310px"}
                      Options={TaskCategoryList}
                      keyName={'title'}
                      Name="taskCategory"
                      ClassName="form-field-design"
                      SelectValue={timelogFillInputs.taskCategory}
                      HandleChange={handleTimeLogInputChange}
                      // onBlur={handleBlur}
                      Error={timeLogError.taskCategory}
                      variant={'outlined'}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-lg-4 mt-4">
                    <SelectInput
                      Lable={'Complexity'}
                      // width={"310px"}
                      Options={complexityList}
                      keyName={'title'}
                      Name="complexity"
                      ClassName="form-field-design"
                      SelectValue={timelogFillInputs.complexity}
                      HandleChange={handleTimeLogInputChange}
                      // onBlur={handleBlur}
                      Error={timeLogError.complexity}
                      variant={'outlined'}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className="col-lg-4 mt-4">
                    <SelectInput
                      Lable={'Hours Type'}
                      // width={"310px"}
                      Options={hoursTypeList}
                      keyName={'title'}
                      Name="hoursType"
                      ClassName="form-field-design"
                      SelectValue={timelogFillInputs.hoursType}
                      HandleChange={handleTimeLogInputChange}
                      // onBlur={handleBlur}
                      Error={timeLogError.hoursType}
                      variant={'outlined'}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-lg-4  my-4">
                    <SelectInput
                      Lable={'Actual Status'}
                      // width={"310px"}
                      Options={actutalStatusList}
                      keyName={'title'}
                      Name="actutalStatus"
                      ClassName="form-field-design"
                      SelectValue={timelogFillInputs.actutalStatus}
                      HandleChange={handleTimeLogInputChange}
                      // onBlur={handleBlur}
                      Error={timeLogError.actutalStatus}
                      variant={'outlined'}
                      isRequired={true}
                    />
                  </div>
                  <div className="col-lg-4  my-4">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Actual Start Time'}
                      handleDateChange={(e) => {
                        setTimeLoFillInput((prevProps) => ({ ...prevProps, actualStartTime: e }))
                        setTimeLogError((prev) => ({ ...prev, actualStartTime: '' }))
                      }}
                      SelectedDate={timelogFillInputs?.actualStartTime || null}
                      Error={timeLogError?.actualStartTime}
                    />

                  </div>

                </div>
                <div className='row'>
                  <div className="col-lg-4 mb-4">

                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Actual End Time'}
                      handleDateChange={(e) => {
                        const selectedEndTime = e instanceof Date ? e : new Date(e);
                        if (
                          timelogFillInputs?.actualStartTime &&
                          selectedEndTime &&
                          selectedEndTime < timelogFillInputs.actualStartTime
                        ) {
                          setTimeLogError((prev) => ({
                            ...prev,
                            actualEndTime: 'End time must be equal to or after the start time',
                          }));
                          // toast.warn('End time must be equal to or after the start time');
                        } else {
                          setTimeLogError((prev) => ({ ...prev, actualEndTime: '' }));
                          setTimeLoFillInput((prevProps) => ({ ...prevProps, actualEndTime: selectedEndTime }));
                        }
                      }}
                      SelectedDate={timelogFillInputs?.actualEndTime || null}
                      Error={timeLogError?.actualEndTime}
                    />
                    {/* <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Actual End Time'}
                      handleDateChange={(e) => {
                        setTimeLoFillInput((prevProps) => ({ ...prevProps, actualEndTime: e }))
                        setTimeLogError((prev) => ({ ...prev, actualEndTime: '' }))
                      }}
                      SelectedDate={timelogFillInputs?.actualEndTime || null}
                      Error={timeLogError?.actualEndTime}
                    /> */}
                  </div>

                  <div className="col-lg-8 text-end mt-3">
                    <Button size='small' variant='contained' color='success' onClick={() => addMultipleBreak(0)}>
                      <AddIcon />add breaks
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div ref={scrollRef} style={{ height: `${breakHoursInput?.length > 0 ? "225px" : "0px"}`, width: "100%", overflowY: "auto" }}>
                    {breakHoursInput?.map((item, index) => <Fragment key={index}>
                      <div className="row">
                        <div className="col-lg-6 my-2">
                          <DesktopDateTimePicker
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'Break Start '}
                            handleDateChange={(date) => handleBrakeTime(date, 'startTime', index)}
                            SelectedDate={breakHoursInput[index]?.startTime}
                          />
                          {breakErrors[index]?.startTime && <span className='text-danger'>{breakErrors[index]?.startTime}</span>}
                        </div>
                        <div className="col-lg-6 my-2">
                          <DesktopDateTimePicker
                            className="w-100 form-field-design"
                            // isRequired={true}
                            Label={'Break End'}
                            handleDateChange={(date) => handleBrakeTime(date, 'endTime', index)}
                            SelectedDate={breakHoursInput[index]?.endTime}
                          />
                          {breakErrors[index]?.endTime && <span className='text-danger'>{breakErrors[index]?.endTime}</span>}
                        </div>
                        <div className="col-lg-12 my-2">
                          <label htmlFor="">
                            <sub>Reason
                              {/* <span className='text-danger'>*</span> */}
                            </sub>
                          </label>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              toolbar: CKEditorCustomToolbar,
                            }}
                            data={item?.reason}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleBreakInput({ target: { name: 'reason', value: data } }, index);
                              // Clear the reason error when there is a change
                              setBreakErrors((prevErrors) => {
                                const updatedErrors = [...prevErrors];
                                updatedErrors[index] = { reason: '' };
                                return updatedErrors;
                              });
                            }}
                            onBlur={(event, editor) => {
                              if (!item.reason) {
                                // Set the reason error if it's empty on blur
                                setBreakErrors((prevErrors) => {
                                  const updatedErrors = [...prevErrors];
                                  updatedErrors[index] = { reason: 'Please enter reason' };
                                  return updatedErrors;
                                });
                              }
                            }}
                          />
                          {breakErrors[index]?.reason && <p className="text-danger">{breakErrors[index]?.reason}</p>}
                        </div>
                      </div>
                      <div className="col-lg-12 text-end mt-3">
                        {/* <Button size='small' variant='contained' color='success' className='me-2' onClick={() => addMultipleBreak(index)}>
                      <AddIcon />
                    </Button> */}
                        {/* {breakHoursInput.length > 1 && ( // Only show the button if there is more than one task */}
                        <Button size='small' variant="outlined" color="error" onClick={() => removeBreak(index, item?._id)}>
                          <ClearIcon />
                        </Button>
                        {/* )} */}
                      </div>
                    </Fragment>)}
                  </div>
                </div>

                <div className="col-lg-12 my-2">
                  <label htmlFor="">
                    <sub>Remarks </sub>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    data={timelogFillInputs?.notes} // Set CKEditor data to projectInputs.notes
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setTimeLoFillInput((prevProps) => ({ ...prevProps, notes: data }))
                    }}
                  />
                </div>

                <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
                  <h5 className="">Attachments</h5>
                  <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
                  <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
                </div>
                {selectedFiles.length > 0 && <div className="col-lg-12 ">
                  <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                    <div>
                      <h4>Selected Files:</h4>
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {selectedFiles?.map((file, fileIndex) => (
                          <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                            {fileType(file, fileIndex, "TIMELOG")}
                            <Fab
                              className="m-2"
                              sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                              onClick={() => handleLogRemoveFile(fileIndex)}
                            >
                              <ClearIcon sx={{ fontSize: '15px' }} />
                            </Fab>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>}

                <div className=" d-flex justify-content-end ">
                  <div className="m-2">
                    <Button variant="contained" onClick={SubmitLogTime}>
                      update
                    </Button>
                    {/* */}
                  </div>
                  <div className="m-2">
                    <Button variant="outlined" onClick={() => {
                      CloseFucntion("breatModal");
                      setTimeLogDetailsModel((prev) => ({ ...prev, open: false }))
                    }}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </>}

          </div>
        }
      />

      {/* attachment Viewer */}
      <Modal
        size="lg"
        show={showPDFModal.open}
        onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
        headerContent={''}
        bodyContent={
          <div className="">
            {showPDFModal?.data ? (
              showPDFModal?.data?.split('.').pop() === 'pdf' ? (
                <iframe src={uploadFileUrlTimeLog + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
              ) : (
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlTimeLog + showPDFModal?.data} alt="" />
              )
            ) : (
              <div>No attachment available</div>
            )}
          </div>
        }
      />

      {/*Reassign Task Form */}
      <Modal
        size="xl"
        CloseFucntion={() => CloseFucntion('taskModel')}
        show={taskModel?.open}
        onHide={() => setTaskModel((prev) => ({ ...prev, open: true }))}
        headerContent={<h5 style={{ fontWeight: '600' }}>{'ReAssign Task'} </h5>}
        bodyContent={
          <>
            <ReassignTaskForm data={data} methods={methods} />
          </>
        }
      />

      {/* Edit Breaks Modal */}
      < Modal
        size="md"
        show={breakModal?.open}
        onHide={() => {
          setBreakModal((prev) => ({ ...prev, open: false }));
          setBreakForm({ startTime: null, endTime: null })
        }}
        headerContent={< h5 className="py-2" > {"Edit Break"}</h5 >}
        bodyContent={
          <div className="">
            <div className='row'>
              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'Start Date '}
                  handleDateChange={(date) => setBreakForm((pre) => ({ ...pre, startTime: date }))}
                  SelectedDate={breakForm?.startTime}
                />
                {/* 
                {leaveError?.appliedStartDate && <span className='text-danger'>{leaveError?.appliedStartDate}</span>} */}
              </div>
              <div className="col-lg-6 my-2">
                <DesktopDateTimePicker
                  className="w-100 form-field-design"
                  isRequired={true}
                  Label={'End Date'}
                  // handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                  handleDateChange={(date) => setBreakForm((pre) => ({ ...pre, endTime: date }))}
                  SelectedDate={breakForm?.endTime}
                />

                {/* {leaveError?.appliedEndDate && <span className='text-danger'>{leaveError?.appliedEndDate}</span>} */}
              </div>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setBreakModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                Cancel
              </Button>
              <Button variant="contained" className="ms-2" onClick={() => breakUpdate()}>
                Update
              </Button>
            </div>
          </div >
        }
      />
    </>
  )
}

export default TimeLog
