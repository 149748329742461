import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import Modal from '../../components/Modal'
import useLeaveList from './Hooks/useLeaveList'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import moment from 'moment'
import { CKEditorCustomToolbar, SubLeaveType, LeaveType } from '../../constants'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import SelectInput from '../../components/SelectInput'
import { Select, MenuItem, Button, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import DateInput from '../../components/DateInput'
import Fab from '@mui/material/Fab';
import ClearIcon from '@mui/icons-material/Clear'
import { uploadFileUrlLeave } from '../../repository/taskRepo';
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import MultipleSelect from "../../components/MultipleSelect"

function LeavesList() {
    const navigate = useNavigate()

    const { data, methods } = useLeaveList()
    const { leavePolicyList, statusType, totalCount, controller, isLoading, deleteModal, levePermission, approvalModal, leaveApprovalModal, agentList,
        leaveApplyModal,
        leaveForm,
        leaveError,
        leaveType,
        leavePolicyCount,
        fileInputRef,
        selectedFiles
    } = data
    const { setStatusType, resetFilter, applayFilter, handlePagination, setDeleteModal, handleDelete, Export, setApprovalModal, setLeaveApprovalModal,
        UpdateStatus,
        closeModalFun,
        setLeaveType,
        handleInputChange,
        handleDateChange,
        checkType,
        setLeaveError,
        submit,
        setLeaveApplyModal,
        setLeaveForm,
        handleTimeLogFileChange,
        handleLogRemoveFile,
        getLeaveCount,
        shift

    } = methods
    const { approval, export: exportFile } = levePermission?.userInfo?.permission?.leave

    const TableHead = [
        { label: '#', id: '' },
        { label: 'Name', id: '' },
        { label: 'Reference Number', id: '' },
        { label: 'Leave Dates', id: '' },
        // { label: 'End Date', id: '' },
        { label: 'Leave', id: '' },
        // { label: 'Code', id: '' },
        { label: 'Type', id: '' },
        { label: 'Status', id: '' },
        { label: 'Applied Date', id: '' },
        { label: 'Action', id: '' },
    ]
    const Appoovalstatus = (type) => {
        if (type === "UNAPPROVED") {
            return "text-warning p-4"
        }
        if (type === "REJECTED") {
            return "text-danger p-4"
        }
        if (type === "APPROVED") {
            return "text-success p-4"
        }
        return "text-info p-4"
    }
    const leavePadeType = [
        { title: "Paid", value: "PAID" },
        { title: "Un Paid", value: "UNPAID" },
    ]
    // Approval Header Function
    const approvalHeader = (item) => {

        if (item?.all) {
            return "All Approve leave"
        } else {
            return "All Reject leave"
        }
    }
    // Approval Body Message
    const approvalMessage = (item) => {
        if (item?.all) {
            return "Are you sure you want to approve?"
        } else {
            return "Are you sure you want to reject?"
        }
    }
    // Approvaql Modal Size
    const approvalModalSize = (item) => {

        if (item?.all) {
            return "md"
        } else {
            return "lg"
        }
    }
    // Approval Modal Close
    //   const closeModalFun = () => {
    //     setLeaveApprovalModal({ data: "", leaveType: "PAID", open: false })
    //   }

    //  Approval Buttons
    const approvalButtons = (item) => {
        if (item?.all) {
            return <Button variant="contained" onClick={() => UpdateStatus("APPROVED", "allLeave")}>
                APPROVED
            </Button>
        } else {
            return <Button variant="contained" onClick={() => UpdateStatus("REJECTED", "allLeave")} data-bs-dismiss="modal">
                REJECTED
            </Button>
        }
    }

    const SatatusArr = [
        { value: 'All', title: 'All' },
        { value: 'APPROVED', title: 'Approved' },
        { value: 'UNAPPROVED', title: 'Un Approved' },
        { value: 'REJECTED', title: 'Rejected' },
        { value: 'CANCELLED', title: 'Cancelled' },

    ]
    const fileType = (file, fileIndex) => {
        let type = file?.split('.').pop()

        if (['png', 'jpeg', 'jpg'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={uploadFileUrlLeave + file} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['mp4'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['pdf'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['xlsx', 'xls'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['txt', 'doc', 'odt'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else {
            return (
                <>
                    <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
                </>
            )
        }
    }
    return (
        <>
            <ToastContainer />
            {isLoading && <Spinner />}
            <div className="container-fluid py-3">
                <div className="row">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="row ms-2">
                                {/* <TextField placeholder="Search" name={'searchText'} type="text" value={search} onChange={onSearchChange} variant={'outlined'} sx={{ width: '210px' }} size="small" /> */}
                            </div>
                            <div className="ms-3" style={{ width: '250px' }}>
                                {/* <SelectInput
                                    Lable={'Emp Name'}
                                    // multiple={true}
                                    width={'500px'}
                                    Options={agentList}
                                    keyName={'firstName'}
                                    className="form-field-design"
                                    Name="empName"
                                    SelectValue={statusType.emp}
                                    HandleChange={(e) => setStatusType((pre) => ({ ...pre, emp: e.target.value }))}
                                    variant={'outlined'}
                                /> */}
                                <MultipleSelect
                                    label={"Emp Name"}
                                    // multiple={true}
                                    options={agentList}
                                    searchBy={"name"}
                                    keyName={"name"}
                                    name={"emp"}
                                    onChange={(e) => setStatusType((prevProps) => ({ ...prevProps, emp: e }))}
                                    value={"_id"}
                                    selectedValues={statusType.emp}
                                />
                            </div>
                            <div className="ms-3 mt-4">
                                <Select variant="outlined"
                                    sx={{ width: '210px' }}
                                    size="small" value={statusType?.statusType || 'All'}
                                    onChange={(e) => setStatusType((pre) => ({ ...pre, statusType: e.target.value }))}>
                                    {SatatusArr?.map((item, index) => (
                                        <MenuItem key={SatatusArr.length + index} value={item?.value}>
                                            {item?.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="ms-3 mt-4">
                                <TextField size="small" type="search" placeholder="Search by reference number" className="me-2" aria-label="Search" sx={{ width: '210px' }}
                                    name='referenceNo' value={statusType?.referenceNo} onChange={(e) => setStatusType((pre) => ({ ...pre, referenceNo: e.target.value }))} />
                            </div>

                            {/* Apply or Reset section */}
                            <div className=" d-flex align-items-end">
                                <Button variant="contained" size="small" onClick={applayFilter} className="ms-2 p-2" startIcon={<SearchIcon />}>
                                    Search
                                </Button>
                                <Button variant="contained" size="small" className="ms-2 p-2" onClick={resetFilter} startIcon={<RestartAltIcon />}>
                                    Reset
                                </Button>
                                <Button variant="contained" size="small" className="ms-2" style={{ padding: '8px' }}
                                    onClick={() => { setLeaveApplyModal((prev) => ({ ...prev, open: true })); getLeaveCount(); shift() }}>
                                    Apply Leave
                                </Button>
                                {exportFile?.value &&
                                    <Button className='ms-2' startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-3">
                    <div className="col-lg-12 ticet">
                        <div className="bg shadow raduus">
                            <div className="">
                                <div className="mt-2">
                                    <table className="table">
                                        <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                                        <tbody>
                                            {leavePolicyList?.map((item, index) => {

                                                return (
                                                    <tr
                                                        key={item?._id + index}
                                                        className={item?.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                                                    >
                                                        <td onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)}>{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                                                        <td className="table-link" onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)}> {item?.userDetails?.name || '--'} </td>
                                                        <td> {item?.leaveReferenceNumber || '--'} </td>
                                                        <td onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)} >{moment(item?.appliedStartDate).format(item?.policyDetails?.leaveType === 'NORMAL' && item?.leave === 'Full Day' ? "lll" : 'll') || '--'} - {moment(item?.appliedEndDate).format(item?.policyDetails?.leaveType === 'NORMAL' && item?.leave === 'Full Day' ? "lll" : 'll') || '--'}</td>
                                                        {/* <td  onClick={() => navigate(`/leaves-request/leave-details/${item._id}`)}>{moment(item.appliedEndDate).format(item.policyDetails.leaveType === 'NORMAL' && item?.leave === 'Full Day' ? "lll" : 'DD-MM-YYYY') || '--'}</td> */}
                                                        <td onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)}>{item?.policyDetails?.name || '--'} ({item?.policyDetails?.code || '--'})<br /> {item?.leave || '--'}  {item?.subLeaveType ? `(${item?.subLeaveType})` : ""}</td>
                                                        {/* <td  onClick={() => navigate(`/leaves-request/leave-details/${item._id}`)}>{item?.leave || '--'}</td> */}
                                                        <td onClick={() => navigate(`/leaves-request/leave-details/${item._id}`)}>{item.policyDetails?.leaveType || '--'}</td>
                                                        <td className={Appoovalstatus(item?.status)}><b>{item?.status || '--'}</b></td>
                                                        <td onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)}> {moment(item.appliedDate).format("LLL") || '--'} </td>
                                                        <td>
                                                            <div className="dropdown d-flex p-0 align-items-center justify-content-start" style={{ minHeight: '50px' }}>
                                                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                    {
                                                                        levePermission?.userInfo?._id !== item?.userDetails?._id && approval?.value && item?.status === "UNAPPROVED" &&
                                                                        <li style={{ cursor: 'pointer' }}
                                                                            onClick={() => setApprovalModal((prev) => ({ ...prev, data: item, open: true, }))} className="dropdown-item">
                                                                            {/* Approve */}
                                                                            Confirm
                                                                        </li>}
                                                                    <li style={{ cursor: 'pointer' }}
                                                                        onClick={() => navigate(`/leaves-request/leave-details/${item?._id}`)} className="dropdown-item">
                                                                        View
                                                                    </li>
                                                                    {/* {edit?.value && <li style={{ cursor: 'pointer' }}
                                                                            onClick={() => navigate(`/leaves/leave-form/${item._id}`)} className="dropdown-item">
                                                                            Edit
                                                                        </li>} */}
                                                                    {/* {projectDelete?.value && <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item._id, isDeleted: item.isDeleted }, open: true })}>
                                                                            {item.isDeleted ? 'Recover' : 'Delete'}
                                                                        </li>} */}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!leavePolicyList.length && <div className="text-center">No record found</div>}
                                    <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <Modal
                size="md"
                show={deleteModal.open}
                onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
                headerContent={<h5 className="py-2">{deleteModal.data.isDeleted ? 'Recover' : 'Delete'} Leave ?</h5>}
                bodyContent={
                    <div className="">
                        <p>Are you sure you want to {deleteModal.data.isDeleted ? 'recover' : 'delete'} Leave ? </p>
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="contained" onClick={handleDelete}>
                                Yes
                            </Button>
                            <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                                No
                            </Button>
                        </div>
                    </div>
                }
            />

            {/* Approval Leave Modal */}
            <Modal
                size="lg"
                show={approvalModal.open}
                onHide={() => setApprovalModal((prev) => ({ ...prev, open: false }))}
                headerContent={<h5>{!approvalModal?.view ? "Approval?" : "Leave details"}</h5>}
                bodyContent={
                    <div className="">
                        <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                            <div>
                                <span>
                                    <b>Name :</b> {approvalModal?.data?.userDetails?.name}
                                </span>
                            </div>
                            <div>
                                <span>
                                    <b>Status :</b> {approvalModal?.data?.status}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTop: '0' }}>
                            <div>
                                <span>
                                    <b>Leave Type :</b> {`${approvalModal?.data?.policyDetails?.name} (${approvalModal?.data?.policyDetails?.leaveType})`}
                                </span>
                            </div>
                            <div>
                                <span>
                                    <b>Code :</b> {`${approvalModal?.data?.policyDetails?.code}`}<b></b>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTop: '0' }}>
                            <div>
                                <span>
                                    <b>From :</b> {moment(approvalModal?.data?.appliedStartDate).format('lll')}
                                </span>
                            </div>
                            <div>
                                <span>
                                    <b>TO :</b> {moment(approvalModal?.data?.appliedEndDate).format('lll')}
                                </span>
                            </div>
                        </div>
                        <div className="time-log-detials-border" style={{ wordWrap: "break-word", borderTop: '0' }} >
                            <div>
                                <b>Reason : </b>
                                <span className="mt-2" dangerouslySetInnerHTML={{ __html: approvalModal?.data?.reason }}></span>
                            </div>
                        </div>



                        {!approvalModal?.view && <><div className='mt-2 text-primary'>
                            <b>Click Approve or Reject to change the approval status of leave. </b>
                        </div>
                            <div className="d-flex justify-content-end mt-4">
                                <Button variant="contained" onClick={() => { setLeaveApprovalModal(prev => ({ ...prev, data: approvalModal?.data?._id, open: true, all: true })); setApprovalModal((prev) => ({ ...prev, open: false })) }}>
                                    APPROVE
                                </Button>
                                <Button variant="outlined" className="ms-2" onClick={() => { setLeaveApprovalModal(prev => ({ ...prev, data: approvalModal?.data?._id, open: true, all: false })); setApprovalModal((prev) => ({ ...prev, open: false })) }} data-bs-dismiss="modal">
                                    REJECTED
                                </Button>
                            </div></>}
                    </div>
                }
            />
            {/* Confermation Modal before approve or reject  */}
            <Modal
                size={approvalModalSize(leaveApprovalModal)}
                show={leaveApprovalModal?.open}
                onHide={closeModalFun}
                headerContent={<h5 className="py-2">{approvalHeader(leaveApprovalModal)}</h5>}
                bodyContent={
                    <div className="">
                        <p>{approvalMessage(leaveApprovalModal)} </p>


                        {(leaveApprovalModal?.all === false) ? <div className="col-lg-12 my-2">
                            <label htmlFor="">
                                <sub>Reason <span className='text-danger'>*</span></sub>
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: CKEditorCustomToolbar,
                                }}
                                data={leaveApprovalModal?.reason} // Set CKEditor data to projectInputs.notes
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    setLeaveApprovalModal((prevProps) => ({ ...prevProps, reason: data }))
                                }}
                            />
                        </div>
                            :
                            <div className="col-lg-12 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Leave Type'}
                                    // multiple={true}
                                    Options={leavePadeType}
                                    keyName={'title'}
                                    // extrakey={"leaveType"}
                                    Name="leaveType"
                                    SelectValue={leaveApprovalModal?.leaveType}
                                    HandleChange={(e) => setLeaveApprovalModal((prevProps) => ({ ...prevProps, leaveType: e.target.value }))}
                                    // Error={leaveError.leaveType}
                                    variant={'outlined'}
                                    isRequired={true}
                                // disabled={true}
                                />
                            </div>
                        }

                        <div className="d-flex justify-content-end mt-4">
                            {
                                approvalButtons(leaveApprovalModal)
                            }

                            {/* <Button variant="outlined" className="mx-2" onClick={closeModalFun}>
                Cancel
              </Button> */}
                        </div>
                    </div>
                }
            />

            {/* Leave Apply Form */}
            < Modal
                size="lg"
                show={leaveApplyModal.open}
                onHide={() => {
                    setLeaveApplyModal((prev) => ({ ...prev, open: false }));
                    setLeaveForm({
                        leaveType: "",
                        appliedStartDate: null,
                        // appliedStartDate: null,
                        reason: "",
                        leave: null,
                        subLeaveType: null,
                    });
                    setLeaveType('')
                }}
                headerContent={< h5 className="py-2" > {"Leave Apply"}</h5 >}
                bodyContent={
                    < div className="" >
                        <div className='row'>
                            <div className="col-lg-4 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Leave'}
                                    // multiple={true}
                                    Options={leavePolicyCount}
                                    keyName={'name'}
                                    // extrakey={"leaveType"}
                                    Name="leaveType"
                                    SelectValue={leaveForm.leaveType}
                                    HandleChange={(e) => { handleInputChange(e); checkType(e.target.value); setLeaveForm((pre) => ({ ...pre, leave: null, subLeaveType: null })) }}
                                    Error={leaveError.leaveType}
                                    variant={'outlined'}
                                    isRequired={true}
                                    ClassName={"auto"}
                                // disabled={true}
                                />
                            </div>
                            {
                                leaveType === 'NORMAL' &&
                                <div className="col-lg-4 my-2">
                                    <SelectInput
                                        LabelButton
                                        Lable={'Leave Type'}
                                        // multiple={true}
                                        Options={LeaveType}
                                        keyName={'title'}
                                        extrakey={"leaveType"}
                                        Name="leave"
                                        SelectValue={leaveForm.leave}
                                        HandleChange={(e) => { handleInputChange(e); setLeaveForm((pre) => ({ ...pre, subLeaveType: null })) }}
                                        Error={leaveError.leave}
                                        variant={'outlined'}
                                        isRequired={true}
                                        ClassName={"auto"}
                                    // disabled={true}
                                    />
                                </div>
                            }
                            {
                                leaveForm.leave === 'HALF' || leaveType === 'SHORT' ?
                                    <div className="col-lg-4 my-2">
                                        <SelectInput
                                            LabelButton
                                            Lable={'Sub Leave Type'}
                                            // multiple={true}
                                            Options={SubLeaveType}
                                            keyName={'title'}
                                            extrakey={"subLeaveType"}
                                            Name="subLeaveType"
                                            SelectValue={leaveForm.subLeaveType}
                                            HandleChange={(e) => { handleInputChange(e); }}
                                            Error={leaveError.subLeaveType}
                                            variant={'outlined'}
                                            isRequired={true}
                                            ClassName={"auto"}
                                        // disabled={true}
                                        />
                                    </div>
                                    : null
                            }
                            <div className="col-lg-4 my-2">
                                {leaveType === 'HALF' ? (
                                    <DesktopDateTimePicker
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'Start Date '}
                                        handleDateChange={(date) => handleDateChange(date, 'appliedStartDate')}
                                        SelectedDate={leaveForm?.appliedStartDate}
                                    />
                                ) : (
                                    <DateInput
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'Start Date '}
                                        handleDateChange={(date) => handleDateChange(date, 'appliedStartDate')}
                                        SelectedDate={leaveForm?.appliedStartDate}
                                    />
                                )}

                                {leaveError?.appliedStartDate && <span className='text-danger'>{leaveError?.appliedStartDate}</span>}
                            </div>
                            <div className="col-lg-4 my-2">
                                {leaveType === 'HALF' ? (
                                    <DesktopDateTimePicker
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'End Date'}
                                        handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                                        SelectedDate={leaveForm?.appliedEndDate}
                                    />
                                ) : (
                                    <DateInput
                                        className="w-100 form-field-design"
                                        isRequired={true}
                                        Label={'End Date'}
                                        handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                                        SelectedDate={leaveForm?.appliedEndDate}
                                    />
                                )}
                                {leaveError?.appliedEndDate && <span className='text-danger'>{leaveError?.appliedEndDate}</span>}
                            </div>
                            <div className="col-lg-12 my-2">
                                <label htmlFor="">
                                    <sub>Description <span className='text-danger'>*</span></sub>
                                </label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: CKEditorCustomToolbar,
                                    }}
                                    data={leaveForm.reason}
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        handleInputChange({ target: { name: 'reason', value: data } });

                                        setLeaveError((prevErrors) => {
                                            let updatedErrors = { ...prevErrors };
                                            updatedErrors = { reason: '' };
                                            return updatedErrors;
                                        });
                                    }}
                                    onBlur={(event, editor) => {
                                        if (!leaveForm.reason) {
                                            // Set the reason error if it's empty on blur
                                            setLeaveError((prevErrors) => {
                                                let updatedErrors = { ...prevErrors };
                                                updatedErrors = { reason: 'Please enter reason' };
                                                return updatedErrors;
                                            });
                                        }
                                    }}
                                />
                                {leaveError?.reason && <p className="text-danger">{leaveError?.reason}</p>}
                            </div>
                        </div>
                        <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
                            <h5 className="">Attachments</h5>
                            <Button variant="contained" onClick={() => fileInputRef?.current?.click()}>choose file</Button>
                            <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
                        </div>
                        {selectedFiles?.length > 0 && <div className="col-lg-12 ">
                            <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                                <div>
                                    <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                                        {selectedFiles?.map((file, fileIndex) => (
                                            <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                                                {fileType(file, fileIndex, "LEAVE")}
                                                <Fab
                                                    className="m-2"
                                                    sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                                                    onClick={() => handleLogRemoveFile(fileIndex)}
                                                >
                                                    <ClearIcon sx={{ fontSize: '15px' }} />
                                                </Fab>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="d-flex justify-content-end mt-4">
                            <Button variant="outlined" onClick={() => setLeaveApplyModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                                Cancel
                            </Button>
                            <Button variant="contained" className="ms-2" onClick={submit}>
                                Apply
                            </Button>
                        </div>
                    </div >
                }
            />

        </>
    )
}

export default LeavesList