import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import useToAttendanceList from './Hooks/useToAttendanceList'
import { Button, InputLabel, Switch } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import Modal from '../../components/Modal'
import moment from 'moment'
import AttendanceFilters from './AttendanceFilters'
import DateInput from "../../components/DateInput"
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import LeaveForm from './LeaveForm'
const AttendanceList = () => {
  const navigate = useNavigate()
  const { data, methods } = useToAttendanceList()

  const { attandenceList, controller, isLoading, totalCount, attendancePermission,
    deleteModal,
    AttendanceView
  } = data
  const { handlePagination, Export,
    setDeleteModal,
    handleDelete,
    setAttendanceView
  } = methods
  const {  import: AttendacneImport, export: exportFile, deleteAttendance, editAttendance } = attendancePermission?.userInfo?.permission?.attendance
  // const { approval, viewSelf, import: AttendacneImport, export: exportFile, deleteAttendance, editAttendance } = attendancePermission?.userInfo?.permission?.leave


  const TableHead = [
    { label: '#', id: '' },
    { label: 'Emp Id', id: '' },
    { label: 'Emp Name', id: '' },
    { label: 'Designation', id: '' },
    { label: 'Date', id: '' },
    { label: 'In Time', id: '' },
    { label: 'Out Time', id: '' },
    { label: 'Work Hours', id: '' },
    { label: 'Work Status', id: '' },
    { label: 'Action', id: '' },
  ]

  // User Attendance Status Function
  // const statusType = (type) => {
  //   if (type === "P") {
  //     return "text-success p-4"
  //   }
  //   if (type === "A") {
  //     return "text-danger p-4"
  //   }
  //   if (type === "MIS") {
  //     return "text-warning p-4"
  //   }
  //   return "text-info p-4"
  // }
  // use Working time Status function
  const getColorBasedOnComparison = (minWorkingMins, workHrs, status) => {
    if (status === "P") {
      if (workHrs >= minWorkingMins) {
        return "#78c345e3 "; // Green
      } else {
        return "#e7d745f2 "; // Yellow
      }
    } else if (status === "A") {
      return "#f16161 "; // Red
    } else {
      return "#e7d745f2 "; // Yellow
    }
  };

  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row mb-2">
          <div className="d-flex justify-content-between">
            <div className='d-flex '>
              {
                attendancePermission?.userInfo?.role_id?.roleTitle === 'ADMIN' ? null
                  :
                  <>
                    <InputLabel className='fs-5 m-2'>{AttendanceView ? "Calendar View" : "List View"}</InputLabel>
                    <Switch name="ReminderAction" checked={AttendanceView} onChange={(e) => setAttendanceView((prev) => (!prev))} />
                  </>
              }
            </div>
            <div className="d-flex align-items-end">

              {/* <div className="me-2">
                  <Button className="dropDownButtons11" onClick={() => navigate(`/attendance/leave-form`)}>
                    <AddIcon /> Leave Apply
                  </Button>
                </div> */}
              {exportFile?.value &&
                <div className="me-2">
                  <Button className="dropDownButtons11" onClick={() => navigate(`/attendance/add-attendance`)}>
                    Add Attendance
                  </Button>
                </div>
              }
                {/* <div className="me-2">
                  <Button className="dropDownButtons11" onClick={() => navigate(`/attendance/leaves-request`)}>
                    Leave Request
                  </Button>
                </div> */}
              {deleteAttendance?.value && <div className="me-2">
                <Button startIcon={<AutoDeleteIcon />} variant="contained"
                  onClick={() => setDeleteModal({ data: { date: true }, open: true, })}
                >Delete Attendance</Button>
              </div>}
              {exportFile?.value && <div>
                <Button startIcon={<ExitToAppIcon />} variant="contained"
                  onClick={() => Export()}
                >Export</Button>
              </div>}
              {AttendacneImport?.value && (
                <div className="ms-2">
                  <Button className="dropDownButtons11" onClick={() => navigate(`/attendance/upload-attendance`)}>
                    <AddIcon /> Import
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        {AttendanceView ? <div className="row py-3">
          <div className="col-lg-10 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2 myTable" >
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {attandenceList?.map((item, index) => {
                        const WorkingTimeColor = getColorBasedOnComparison(item?.minWorkingMins, item?.workHrs, item?.status);
                        const duration = moment.duration(item?.workHrs, 'minutes');
                        const hours = Math.floor(duration.asHours());
                        const remainingMinutes = duration.minutes();
                        let workHours = '';
                        if (hours > 0) {
                          workHours += `${hours} hour${hours > 1 ? 's' : ''}`;
                        }
                        if (remainingMinutes > 0) {
                          workHours += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
                        }
                        return (
                          <tr
                            key={item._id}
                            className={`text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 table-success `}
                            // className={`bg-#0000FF`}
                            style={{ backgroundColor: WorkingTimeColor }}
                          >
                            <td>{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                            <td className="table-link text-start" >
                              {`${item.empId}` || '--'}
                            </td>
                            <td className='py-4' >{item?.name || '--'}</td>
                            <td className='py-4' >{item?.designation || '--'}</td>
                            <td className='py-4' >{moment(item?.attendanceDate).format("ll") || '--'}</td>
                            <td className='py-4' >{item?.inTime ? moment(item?.inTime).format("LT") : "--"}</td>
                            <td className='py-4' >{item?.outTime ? moment(item?.outTime).format("LT") : "--"}</td>
                            <td className='py-4' style={{ whiteSpace: "nowrap" }}><b>{workHours || "--"}</b></td>
                            <td ><b>{item?.status || "--"}</b></td>
                            <td>
                              {
                                (editAttendance?.value || deleteAttendance?.value)
                                  ?
                                  (<div className="dropdown p-0 align-items-center justify-content-center">
                                    <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      {
                                        editAttendance?.value &&
                                        <li style={{ cursor: 'pointer' }} onClick={() => navigate(`/attendance/upload-attendance/${item._id}`, { state: { ticketId: item._id }, })} className="dropdown-item">  Edit</li>}
                                      {/* {(ticketDelete?.value && item.status !== 'CLOSED') && <> */}

                                      {
                                        deleteAttendance?.value && <li className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => setDeleteModal({ data: { _id: item?._id }, open: true, })}>Delete</li>}
                                    </ul>
                                  </div>)
                                  :
                                  (<div className='pt-3' style={{ minHeight: '50px' }}>
                                    <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />
                                  </div>)
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!attandenceList?.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 ticet mt-2">
            <AttendanceFilters data={data} methods={methods} />
          </div>
        </div>
          :
          <LeaveForm />
        }


      </div >


      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{"Delete Attendance?"}</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete Attendance? </p>

            {deleteModal?.data?.date && <div className="col-lg-11 mx-auto mb-2">
              <DateInput
                size="small" // You can customize the size here
                variant="outlined" // You can customize the variant here
                className="w-100 form-field-design" // You can customize the width here
                // isRequired={true}
                Label={'Date'}
                SelectedDate={deleteModal?.date || null}
                handleDateChange={(date) => {
                  setDeleteModal((pre) => ({ ...pre, date: date }))
                }}
              />
            </div>}

            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={handleDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

    </>
  )
}

export default AttendanceList