/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import "./AttendanceClander.css"
import { Button, } from '@mui/material';
import { CKEditorCustomToolbar, LeaveType, SubLeaveType } from '../../constants';
import SelectInput from '../../components/SelectInput'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Spinner } from '../../components/Spinner/Spinner';
import { ToastContainer } from 'react-toastify';
import Modal from '../../components/Modal'
import useLeaveForm from './Hooks/useLeaveForm'
import moment from 'moment';
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import DateInput from '../../components/DateInput'
import { calculateWorkHours } from '../../utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear'
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import { uploadFileUrlLeave } from '../../repository/taskRepo';

const LeaveForm = () => {
    // const [state, setState] = useState(new Date())
    const navigate = useNavigate()
    // const fileInputRef = useRef(null)
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { data, methods } = useLeaveForm()
    const {
        spinner,
        calendarData,
        currentMonth,
        leaveForm,
        leaveError,
        leaveApplyModal,
        OneDayDetailModal,
        leavePolicyCount,
        selectedFiles,
        fileInputRef,
        leaveType
    } = data

    const {
        handleDateChange,
        handleTimeLogFileChange,
        handleLogRemoveFile,
        checkType,
        // focusHandle,
        // bgHandle,
        submit,
        handleInputChange,
        setCalendarData,
        setLeaveError,
        setLeaveForm,
        setLeaveApplyModal,
        setCurrentMonth,
        setOnedayDetailModal,
        GetDayDetails,
        getLeaveCount,
        dayDataCount,
        restDays,
        setLeaveType

    } = methods
    // const { viewAll, import: AttendacneImport, export: exportFile, deleteAttendance, editAttendance } = attendancePermission?.userInfo?.permission?.attendance
    const currentDate = moment().startOf("day")
    const thirtyDaysAgo = moment().subtract(30, 'days').startOf("day");
    //  console.log(thirtyDaysAgo,'thirtyDaysAgo')
    // console.log(OneDayDetailModal?.date,'OneDayDetailModal?.date')
    //     console.log(OneDayDetailModal?.date >= thirtyDaysAgo,'LLLLL')

    const [isActive, setIsActive] = useState({ open: false, date: moment(currentDate).format("YYYY MMMM DD") })
    useEffect(() => {
        // Update isActive to make the current date default active
        setIsActive({ open: true, date: moment(currentDate).format("YYYY MMMM DD") });
    }, []);

    // const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    // const ShiftDuration = moment.duration(OneDayDetailModal?.data?.attendance?.minWorkingMins, 'minutes');
    // const totalShiftHours = calculateWorkHours(ShiftDuration);
    // const WorkingDuration = moment.duration(OneDayDetailModal?.data?.attendanceData?.map((item)=>item.workHrs), 'minutes');
    // const WorkingDuration = moment.duration(OneDayDetailModal?.data?.attendanceData?.[0]?.workHrs, 'minutes');
    // const totalWorkingHours = calculateWorkHours(WorkingDuration);
    // console.log(WorkingDuration,'WorkingDuration')

    const fileType = (file, fileIndex) => {
        let type = file?.split('.').pop()

        if (['png', 'jpeg', 'jpg'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={uploadFileUrlLeave + file} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['mp4'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['pdf'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['xlsx', 'xls'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else if (['txt', 'doc', 'odt'].includes(type)) {
            return (
                <>
                    <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
                </>
            )
        } else {
            return (
                <>
                    <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
                </>
            )
        }
    }

    const tabs = [];
    // Day Detail Data
    (OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.compensationDay || OneDayDetailModal?.publicHoliday) && tabs.push({
        label: "Day Detail",
        content:
            <div>
                {!!OneDayDetailModal?.offDay &&
                    <h2 className='text-center'>OFF Day </h2>
                }
                {!!OneDayDetailModal?.restDay &&
                    <h2 className='text-center'>Rest Day </h2>
                }
                {!!OneDayDetailModal?.compensationDay && <>
                    <h4 className='mt-3'>Compensation OFF </h4>
                    <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <span>
                            <b>Date : </b>{OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                        </span>
                    </div>
                    <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                        <span>
                            <b>Description</b>
                        </span>
                        <span className="mt-2" dangerouslySetInnerHTML={{ __html: OneDayDetailModal?.details && OneDayDetailModal?.details.length > 0 ? OneDayDetailModal?.details[0] : '' }}></span>
                    </div>
                </>}
                {!!OneDayDetailModal?.publicHoliday && <>
                    <h4 className='mt-3'>Public Holiday </h4>
                    <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <span>
                            <b>Date : </b>{OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                        </span>
                    </div>
                    <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                        <span>
                            <b>Description</b>
                        </span>
                        <span className="mt-2" dangerouslySetInnerHTML={{ __html: OneDayDetailModal?.details && OneDayDetailModal?.details.length > 0 ? OneDayDetailModal?.details[0] : '' }}></span>
                    </div>
                </>}
            </div>
    })

    // Attendance Tab Data
    OneDayDetailModal?.data?.attendanceData?.length && tabs.push({
        label: 'Attendance',
        content: <div>
            {OneDayDetailModal?.data?.attendanceData.map((item) => {
                const WorkingDuration = moment.duration(item.workHrs, 'minutes');
                const totalWorkingHours = calculateWorkHours(WorkingDuration)
                return <>

                    <div className="d-flex justify-content-between time-log-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <span>
                            <b>Name : </b>{`${item?.user_id?.firstName && item?.user_id?.firstName}  ${item?.user_id?.lastName && item?.user_id?.lastName}`}<br />
                            {/* <b>Emp Id :</b> {item?.empId} */}
                        </span>
                        <span>
                            <b>Date :</b> {OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                            {/* <b>Shift Duration : </b>{totalShiftHours || "--"} */}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between time-log-detials-border" style={{ wordWrap: "break-word", borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }} >
                        <span>
                            <b>Status : </b>{item?.status} <br />
                            <b>In Time :</b> {item?.inTime ? moment(item?.inTime).format('LT') : "--"}
                        </span>
                        <span>
                            <b>Worked Duration :</b> {totalWorkingHours || "--"}<br />
                            <b>Out Time : </b>{item?.outTime ? moment(item?.outTime).format('LT') : "--"}
                        </span>
                    </div>
                </>
            })}
        </div>
    },)

    //  Leave Tab Data
    OneDayDetailModal?.open && tabs.push(
        {
            label: 'Leave',
            content: <div>

                {OneDayDetailModal?.date < currentDate && !OneDayDetailModal?.data?.leaveData?.length ?
                    <h4 className='text-center'>
                        No Record Found
                    </h4>
                    : <>
                        {/* Leave Currently Available or Count's Ui */}
                        {(OneDayDetailModal?.date >= currentDate) && <div className='row'>
                            {leavePolicyCount?.map((item) =>
                                <div className="col-2 border bg-white m-2" style={{ borderRadius: "20px" }} >
                                    <div className='row'>
                                        <div className='d-flex justify-content-between'>
                                            <div className="col-8 mt-3  text-center">
                                                <h3>{item?.name} <i>{item.code}</i></h3>
                                            </div>
                                            <div className="col-2 m-2 text-end">
                                                <Fab size="small" color="primary" onClick={() => {
                                                    setLeaveForm(prev => ({ ...prev, leaveType: item?._id }))
                                                    setLeaveApplyModal((prev) => ({ ...prev, open: true }))
                                                }}>
                                                    <AddIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2 text-center">
                                            <h1>{`${item?.entitledLeaves}/${item?.takenLeaves}`}</h1>
                                            {/* <p >Currently Available</p> */}
                                            <p>{item?.leaveType}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>}

                        {/* Leave Details Ui */}
                        {OneDayDetailModal?.data?.leaveData?.length && OneDayDetailModal?.data?.leaveData?.map((item) => <>
                            <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                <span>
                                    <b>Name : </b>{`${item?.user_id?.firstName && item?.user_id?.firstName}  ${item?.user_id?.lastName && item?.user_id?.lastName}`}<br />
                                    <b>Leave : </b>{item?.policy_id?.name} <br />
                                    <b>From : </b>{moment(item?.appliedStartDate).format('lll')}<br />

                                </span>
                                <span>
                                    <b>Status : </b>{item?.status}<br />
                                    <b>Type : </b>{item?.type} <br />
                                    <b>To :</b>{moment(item?.appliedEndDate).format('lll')}
                                </span>
                            </div>
                            <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                                <span>
                                    <b>Reason</b>
                                </span>
                                <span className="mt-2" dangerouslySetInnerHTML={{ __html: item?.reason ? item?.reason : '' }}></span>
                                <p className='text-end text-primary mb-0 pointer' onClick={() => navigate(`/leaves-request/leave-details/${item?.leaveId}`)}><b>Click here to see more details.</b></p>                    </div>
                        </>)}
                    </>
                }
            </div>
        },
    )

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    // console.log(leaveForm,'leaveForm')
    return (<>
        {spinner && <Spinner />}
        {<ToastContainer />}
        <div className='bg-white p-4 mx-4'>
            <div className='row'>
                <div className="col-lg-2 mb-2">
                    <DateInput
                        views={['month']}
                        size="small"
                        variant="outlined"
                        className="w-100 form-field-design"
                        // isRequired={true}
                        Label={'Month'}
                        SelectedDate={currentMonth}
                        handleDateChange={(date) => {
                            setCurrentMonth(date)
                            setCalendarData({})
                            setOnedayDetailModal((prev) => ({ ...prev, date: '' }))
                            // console.log(date,'LLLLLLLLL')
                        }}
                    // minDate={new Date()}
                    />
                    {/* <input type="month" className='form-control' id="myMonth"  onChange={(e)=>{setCurrentMonth(e.target.value);console.log(e.target.value,'LLLLLL')}} /> */}
                </div>
                <div className='col-lg-8 mt-4 d-flex justify-content-end'>
                    {
                        // (OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.compensationDay || OneDayDetailModal?.publicHoliday)
                        // || OneDayDetailModal?.data?.attendanceData?.length > 0 || (OneDayDetailModal?.date >= currentDate) &&
                        OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.publicHoliday || OneDayDetailModal?.compensationDay ? null :
                            OneDayDetailModal?.date >= thirtyDaysAgo &&
                            <div className='mt-1'>
                                <Button variant="contained" onClick={() => setLeaveApplyModal((prev) => ({ ...prev, open: true }))}>
                                    Apply Leave
                                </Button>
                            </div>
                    }
                </div>
                <div className='col-lg-2'></div>
            </div>
            <div>
                <div className='row mt-2'>
                    <div className="col-10 ">
                        <div className="row">
                            {calendarData?.days?.length > 0 ? <div className="w-100 p-2">
                                <div className="border rounded clander-main-box">
                                    <div className='calendar-header p-3'>
                                        <span className='fs-4'>{calendarData?.year}</span>
                                        <span className='fs-4'>{calendarData?.monthName}</span>
                                    </div>
                                    <div className='d-flex flex-column justify-content-center'>
                                        <div className='calendar-day-header mt-2'>
                                            {
                                                weekdays.map((weekday) => {
                                                    return <div className="weekday fw-500"><p className='m-0 p-0 fs-5'>{weekday}</p></div>
                                                })
                                            }
                                        </div>
                                        <div className='calendar-container'>
                                            {calendarData?.days?.map((innerItem, innerIndex) => {
                                                let SelectedDate = moment(`${calendarData?.year} ${calendarData?.monthName} ${innerItem.date}`, "YYYY MMMM DD").add(12, 'hours').toDate()
                                                // console.log(SelectedDate,'SelectedDate')
                                                // const colorsArray = BoxType(innerItem);
                                                // console.log('colorsArray',colorsArray)
                                                // console.log(innerItem.shiftDates,'innerItem.shiftDates')
                                                const colorsRest = restDays(innerItem);
                                                const styles = {
                                                    background: colorsRest.map((color, index) => color).join(' '), // Joining the colors into a single string
                                                };

                                                return (
                                                    innerItem.isDay ? <div className='empty-box'></div> :
                                                        <div key={`checkbox-${innerIndex}`} className="calendar-box">
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox-${innerIndex}`}
                                                                onChange={(e) => {
                                                                    setIsActive({ open: true, date: moment(SelectedDate).format("YYYY MMMM DD") });
                                                                    if (SelectedDate >= thirtyDaysAgo) {
                                                                        setLeaveForm((prev) => ({ ...prev, appliedStartDate: SelectedDate, appliedEndDate: SelectedDate, startDateFormat: innerItem?.shiftDates, endDateFormat: innerItem?.shiftDateEnd, shiftData: { startDateTime: innerItem.shiftDates, endDateTime: innerItem.shiftDateEnd } }))
                                                                        getLeaveCount()
                                                                        // handleCheckDays(e, innerIndex)
                                                                        GetDayDetails(SelectedDate)
                                                                        setOnedayDetailModal((prev) => ({ ...prev, offDay: innerItem?.offDay, restDay: innerItem?.restDay, publicHoliday: innerItem?.publicHoliday, compensationDay: innerItem?.compensationDay, details: innerItem?.details }))
                                                                    }
                                                                    else {
                                                                        setOnedayDetailModal((prev) => ({ ...prev, offDay: innerItem?.offDay, restDay: innerItem?.restDay, publicHoliday: innerItem?.publicHoliday, compensationDay: innerItem?.compensationDay, details: innerItem?.details }))
                                                                        GetDayDetails(SelectedDate)
                                                                    }
                                                                }}
                                                                style={{ display: 'none' }}
                                                            />
                                                            <div className='shadow-sm' style={styles} >
                                                                <label
                                                                    htmlFor={`checkbox-${innerIndex}`}
                                                                    className={`calendar-date-box ${isActive.open && isActive.date === moment(SelectedDate).format("YYYY MMMM DD") ? "Calendar-active" : ""}`}
                                                                    style={{
                                                                        backgroundColor: "#cccccc4a",
                                                                        // border: focusHandle(),
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    <div className="d-flex flex-column justify-content-center align-items-center text-center pb-1" style={{ paddingTop: '25px' }}>
                                                                        {/* <span className='fs-4'>{innerItem.dayOfWeek}</span> */}
                                                                        <span style={{ fontSize: '20px' }}>{innerItem.date}</span>
                                                                    </div>
                                                                    {
                                                                        dayDataCount(innerItem).map((instance, index) => (
                                                                            <span
                                                                                key={index}
                                                                                className='rounded  me-2 d-inline-block'
                                                                                style={{ width: "20px", height: "20px", backgroundColor: instance.color, textAlign: 'center' }}
                                                                            >{instance?.count}</span>
                                                                        ))
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                                :
                                <div className='text-center'>
                                    No record found
                                </div>
                            }
                        </div>
                        {/* <div className="row"> */}

                        {/* <div className="calendar">
                                <div className="calendar-header">
                                    <h2>{months[state.getMonth()]} {state.getFullYear()}</h2>
                                </div>
                                <div className="calendar-body">
                                    <div className="table-header">
                                        {
                                            weekdays.map((weekday) => {
                                                return <div className="weekday"><p>{weekday}</p></div>
                                            })
                                        }
                                    </div>
                                    <CalendarDays day={currentDay} changeCurrentDay={changeCurrentDay} calendarData={calendarData} />
                                </div>
                            </div> */}

                        {/* </div> */}
                    </div>
                    <div className="col-2 position-relative mt-2">
                        <div className=' border p-2' style={{ top: "20%", right: "2%" }}>
                            <div className="row">
                                <div className="col-12">
                                    <h4 className='text-center my-2'>LEGEND</h4>
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#02f7b1' }} />
                                    Present
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#ff0d48e3' }} />
                                    Absent
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#0d6efd3b' }} />
                                    Miss
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <h4 className='text-center my-2'>Leave Status</h4>
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#9b02f7' }} />
                                    Pending
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#02f739' }} />
                                    Approved
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#f1db2f' }} />
                                    Rejected
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <h4 className='text-center my-2'>Off-Days/Holidays</h4>
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#f3963bdb' }} />
                                    Off Day
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#70afdde0' }} />
                                    Rest Day
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#78c223c4' }} />
                                    Public Holiday
                                </div>
                                <div className="col-12 my-1 d-flex justify-content-start">
                                    <span className='rounded p-1 me-2 d-inline-block' style={{ width: "20px", height: "20px", backgroundColor: '#aa3b19cc' }} />
                                    Compensation Off
                                </div>
                            </div>
                        </div>
                        {/* {
                            (OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.compensationDay || OneDayDetailModal?.publicHoliday)
                            || OneDayDetailModal?.data?.attendanceData?.length > 0 || (OneDayDetailModal?.date >= currentDate) &&
                            <div className='mt-2 w-100'>
                                <Button variant="contained" sx={{ width: '100%' }} onClick={() => setLeaveApplyModal((prev) => ({ ...prev, open: true }))}>
                                    Request Leave
                                </Button>
                            </div>
                        } */}
                    </div>

                    <div>
                        <TabContext value={value}>
                            {/* <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {tabs.map((tab, index) => (
                                    <Tab key={index} label={tab.label} />
                                ))}
                            </Tabs>
                            {tabs.map((tab, index) => (
                                <TabPanel key={index} value={index}>
                                    {tab.content}
                                </TabPanel>
                            ))} */}
                            {/* {
                                (OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.compensationDay || OneDayDetailModal?.publicHoliday)
                                || OneDayDetailModal?.data?.attendanceData?.length > 0 || (OneDayDetailModal?.date >= currentDate) &&
                                <div className='d-flex justify-content-end'>
                                    <Button variant="contained" onClick={() => setLeaveApplyModal((prev) => ({ ...prev, open: true }))}>
                                        Add Leave
                                    </Button>
                                </div>
                            } */}
                            {
                                (OneDayDetailModal?.offDay || OneDayDetailModal?.restDay || OneDayDetailModal?.compensationDay || OneDayDetailModal?.publicHoliday) &&
                                <div>
                                    <h5 className='mt-2 fw-bold'>Day Details</h5>
                                    {!!OneDayDetailModal?.offDay &&
                                        <h2 className='text-center'>OFF Day </h2>
                                    }
                                    {!!OneDayDetailModal?.restDay &&
                                        <h2 className='text-center'>Rest Day </h2>
                                    }
                                    {!!OneDayDetailModal?.compensationDay && <>
                                        <h4 className='mt-3'>Compensation OFF </h4>
                                        <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                            <span>
                                                <b>Date : </b>{OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                                            </span>
                                        </div>
                                        <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                                            <span>
                                                <b>Description</b>
                                            </span>
                                            <span className="mt-2" dangerouslySetInnerHTML={{ __html: OneDayDetailModal?.details && OneDayDetailModal?.details.length > 0 ? OneDayDetailModal?.details[0] : '' }}></span>
                                        </div>
                                    </>}
                                    {!!OneDayDetailModal?.publicHoliday && <>
                                        <h4 className='mt-3'>Public Holiday </h4>
                                        <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                            <span>
                                                <b>Date : </b>{OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                                            </span>
                                        </div>
                                        <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                                            <span>
                                                <b>Description</b>
                                            </span>
                                            <span className="mt-2" dangerouslySetInnerHTML={{ __html: OneDayDetailModal?.details && OneDayDetailModal?.details.length > 0 ? OneDayDetailModal?.details[0] : '' }}></span>
                                        </div>
                                    </>}
                                </div>
                            }
                            {
                                OneDayDetailModal?.data?.attendanceData?.length > 0 &&
                                <>
                                    <h5 className='mt-3 fw-bold'>Attendance Details</h5>
                                    {OneDayDetailModal?.data?.attendanceData.map((item) => {

                                        const WorkingDuration = moment.duration(item.workHrs, 'minutes');
                                        const totalWorkingHours = calculateWorkHours(WorkingDuration);
                                        return <>
                                            <div className="d-flex justify-content-between time-log-detials-border mt-2" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                <span>
                                                    <b>Name : </b>{`${item?.user_id?.firstName && item?.user_id?.firstName}  ${item?.user_id?.lastName && item?.user_id?.lastName}`}<br />
                                                </span>
                                                <span>
                                                    <b>Date : </b> {OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : "--"} <br />
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between time-log-detials-border" style={{ wordWrap: "break-word", borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }} >
                                                <span>
                                                    <b>Status : </b>{item?.status} <br />
                                                    <b>In Time :</b> {item?.inTime ? moment(item?.inTime).format('LT') : "--"}
                                                </span>
                                                <span>
                                                    <b>Worked Duration :</b> {totalWorkingHours || "--"}<br />
                                                    <b>Out Time : </b>{item?.outTime ? moment(item?.outTime).format('LT') : "--"}
                                                </span>
                                            </div>
                                        </>
                                    })}
                                </>
                            }
                            {/* Leave Details Ui */}
                            {
                                OneDayDetailModal?.date < currentDate && !OneDayDetailModal?.data?.leaveData?.length ?
                                    // <>
                                    // <h5 className='mt-3 fw-bold'>Leave Details</h5>
                                    // <h4 className='text-center'>
                                    //     No Record Found
                                    // </h4>
                                    // </>
                                    null
                                    : <>
                                        {
                                            OneDayDetailModal?.data?.leaveData?.length > 0 && OneDayDetailModal?.data?.leaveData?.map((item) => <>
                                                <h5 className='mt-3 fw-bold'>Leave Details</h5>
                                                <div className="d-flex justify-content-between time-log-detials-border mt-1" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                    <span>
                                                        <b>Name : </b>{`${item?.user_id?.firstName && item?.user_id?.firstName}  ${item?.user_id?.lastName && item?.user_id?.lastName}`}<br />
                                                        <b>Leave : </b>{item?.policy_id?.name} <br />
                                                        <b>From : </b>{moment(item?.appliedStartDate).format(item?.policyDetails?.leaveType === 'NORMAL' && item?.leave === 'Full Day' ? "lll" : 'DD-MM-YYYY')}<br />
                                                    </span>
                                                    <span>
                                                        <b>Status : </b>{item?.status}<br />
                                                        <b>Type : </b>{item?.type} <br />
                                                        <b>To : </b>{moment(item?.appliedEndDate).format(item?.policyDetails?.leaveType === 'NORMAL' && item?.leave === 'Full Day' ? "lll" : 'DD-MM-YYYY')}
                                                    </span>
                                                </div>
                                                <div className="time-log-detials-border" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', wordWrap: "break-word", }}>
                                                    <span>
                                                        <b>Reason</b>
                                                    </span>
                                                    <br />
                                                    <span className="mt-2" dangerouslySetInnerHTML={{ __html: item?.reason ? item?.reason : '' }}></span>
                                                    <p className='text-end text-primary mb-0 pointer' onClick={() => navigate(`/leaves-request/leave-details/${item?.leaveId}`)}><b>Click here to see more details.</b></p>                    </div>
                                            </>)
                                        }
                                    </>}

                        </TabContext>

                    </div>
                </div>
            </div>
        </div >
        {/* Leave Apply Form */}
        < Modal
            size="lg"
            show={leaveApplyModal.open}
            onHide={() => {
                setLeaveApplyModal((prev) => ({ ...prev, open: false }));
                setLeaveForm({
                    leaveType: "",
                    appliedStartDate: null,
                    // appliedStartDate: null,
                    reason: "",
                    leave: null,
                    subLeaveType: null,
                });
                setLeaveType('')
            }}
            headerContent={< h5 className="py-2" > {"Leave Apply"}</h5 >}
            bodyContent={
                < div className="" >
                    <div className='row'>
                        <div className="col-lg-4 my-2">
                            <SelectInput
                                LabelButton
                                Lable={'Leave'}
                                // multiple={true}
                                Options={leavePolicyCount}
                                keyName={'name'}
                                // extrakey={"leaveType"}
                                Name="leaveType"
                                SelectValue={leaveForm.leaveType}
                                HandleChange={(e) => { handleInputChange(e); checkType(e.target.value); setLeaveForm((pre) => ({ ...pre, leave: null, subLeaveType: null })) }}
                                Error={leaveError.leaveType}
                                variant={'outlined'}
                                isRequired={true}
                                ClassName={"auto"}
                            // disabled={true}
                            />
                        </div>
                        {
                            leaveType === 'NORMAL' &&
                            <div className="col-lg-4 my-2">
                                <SelectInput
                                    LabelButton
                                    Lable={'Leave Type'}
                                    // multiple={true}
                                    Options={LeaveType}
                                    keyName={'title'}
                                    extrakey={"leaveType"}
                                    Name="leave"
                                    SelectValue={leaveForm.leave}
                                    HandleChange={(e) => { handleInputChange(e); setLeaveForm((pre) => ({ ...pre, subLeaveType: null })) }}
                                    Error={leaveError.leave}
                                    variant={'outlined'}
                                    isRequired={true}
                                    ClassName={"auto"}
                                // disabled={true}
                                />
                            </div>
                        }
                        {
                            leaveForm.leave === 'HALF' || leaveType === 'SHORT' ?
                                <div className="col-lg-4 my-2">
                                    <SelectInput
                                        LabelButton
                                        Lable={'Sub Leave Type'}
                                        // multiple={true}
                                        Options={SubLeaveType}
                                        keyName={'title'}
                                        extrakey={"subLeaveType"}
                                        Name="subLeaveType"
                                        SelectValue={leaveForm.subLeaveType}
                                        HandleChange={(e) => { handleInputChange(e); }}
                                        Error={leaveError.subLeaveType}
                                        variant={'outlined'}
                                        isRequired={true}
                                        ClassName={"auto"}
                                    // disabled={true}
                                    />
                                </div>
                                : null
                        }
                        <div className="col-lg-4 my-2">
                            {leaveType === 'HALF' ? (
                                <DesktopDateTimePicker
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'Start Date '}
                                    handleDateChange={(date) => handleDateChange(date, 'appliedStartDate')}
                                    SelectedDate={leaveForm?.appliedStartDate}
                                />
                            ) : (
                                <DateInput
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'Start Date '}
                                    handleDateChange={(date) => handleDateChange(date, 'appliedStartDate')}
                                    SelectedDate={leaveForm?.appliedStartDate}
                                />
                            )}

                            {leaveError?.appliedStartDate && <span className='text-danger'>{leaveError?.appliedStartDate}</span>}
                        </div>
                        <div className="col-lg-4 my-2">
                            {leaveType === 'HALF' ? (
                                <DesktopDateTimePicker
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'End Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                                    SelectedDate={leaveForm?.appliedEndDate}
                                />
                            ) : (
                                <DateInput
                                    className="w-100 form-field-design"
                                    isRequired={true}
                                    Label={'End Date'}
                                    handleDateChange={(date) => handleDateChange(date, 'appliedEndDate')}
                                    SelectedDate={leaveForm?.appliedEndDate}
                                />
                            )}
                            {leaveError?.appliedEndDate && <span className='text-danger'>{leaveError?.appliedEndDate}</span>}
                        </div>
                        <div className="col-lg-12 my-2">
                            <label htmlFor="">
                                <sub>Description <span className='text-danger'>*</span></sub>
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: CKEditorCustomToolbar,
                                }}
                                data={leaveForm.reason}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    handleInputChange({ target: { name: 'reason', value: data } });

                                    setLeaveError((prevErrors) => {
                                        let updatedErrors = { ...prevErrors };
                                        updatedErrors = { reason: '' };
                                        return updatedErrors;
                                    });
                                }}
                                onBlur={(event, editor) => {
                                    if (!leaveForm.reason) {
                                        // Set the reason error if it's empty on blur
                                        setLeaveError((prevErrors) => {
                                            let updatedErrors = { ...prevErrors };
                                            updatedErrors = { reason: 'Please enter reason' };
                                            return updatedErrors;
                                        });
                                    }
                                }}
                            />
                            {leaveError?.reason && <p className="text-danger">{leaveError?.reason}</p>}
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
                        <h5 className="">Attachments</h5>
                        <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
                        <input type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTimeLogFileChange} style={{ display: 'none' }} />
                    </div>
                    {selectedFiles.length > 0 && <div className="col-lg-12 ">
                        <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                            <div>
                                {/* <h4>Selected Files:</h4> */}
                                <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                                    {selectedFiles?.map((file, fileIndex) => (
                                        <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                                            {fileType(file, fileIndex, "LEAVE")}
                                            <Fab
                                                className="m-2"
                                                sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                                                onClick={() => handleLogRemoveFile(fileIndex)}
                                            >
                                                <ClearIcon sx={{ fontSize: '15px' }} />
                                            </Fab>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="outlined" onClick={() => setLeaveApplyModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                            Cancel
                        </Button>
                        <Button variant="contained" className="ms-2" onClick={submit}>
                            Apply
                        </Button>
                    </div>
                </div >
            }
        />
        {/* Days Detail Modal */}
        <Modal
            size="lg"
            // CloseFucntion={() => CloseFucntion("breatModal")}
            // show={OneDayDetailModal.open}
            onHide={() => setOnedayDetailModal({ data: "", open: false })}
            headerContent={
                <h5 style={{ fontWeight: '600' }} className="mt-2">
                    Details {OneDayDetailModal?.date ? moment(OneDayDetailModal?.date).format('DD MMM YYYY') : ""}
                </h5>
            }
            bodyContent={
                <div className="d-flex flex-column">

                    <>
                        {(OneDayDetailModal?.offDay ||
                            OneDayDetailModal?.compensationDay ||
                            OneDayDetailModal?.publicHoliday ||
                            OneDayDetailModal?.restDay ||
                            OneDayDetailModal?.details?.length ||
                            OneDayDetailModal?.data?.leaveData?.length ||
                            OneDayDetailModal?.data?.attendanceData?.length) ? (



                            <TabContext value={value}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {tabs.map((tab, index) => (
                                        <Tab key={index} label={tab.label} />
                                    ))}
                                </Tabs>
                                {tabs.map((tab, index) => (
                                    <TabPanel key={index} value={index}>
                                        {tab.content}
                                    </TabPanel>
                                ))}
                            </TabContext>
                        ) : (

                            <h4 className='text-center'>
                                No Record Found
                            </h4>
                        )
                        }

                    </>
                </div>
            }
        />

    </>
    );
}

export default LeaveForm