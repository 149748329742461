/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useToGetTicketList, updateTicket, TicketStatus, deleteTicket, bulkAssignTicket, AllTicketList, MergeTicket, ExportTicketList, PermanentDeleteTicket } from '../../../repository/ticketRepo'
import { useToGetUserFullList, userFullList } from '../../../repository/teamManagementRepo'
import { getAllTeamList } from '../../../repository/teamRepo'
import { allTagList } from '../../../repository/tagsRepo'
import { useToGetFullPriject } from '../../../repository/projectRepo'
import { getAllSkillList } from '../../../repository/skillsRepo'
import useReduxStore from '../../../hooks/useReduxStore'
import { DeleteFile, addTask, uploadFile } from '../../../repository/taskRepo'
import { requiredMessage, requiredSelect } from '../../../utils'
import { useLocation } from 'react-router-dom'
import { addFilter } from '../../../Slices/FiltersSlice'
import { useDispatch } from 'react-redux'
import { taskTypeList, SubTaskList, TaskCategoryList, complexityList, hoursTypeList, workTypeList } from '../../../constants'

const useTicketListing = () => {

  const priorityList = [
    { title: 'Low', value: 'LOW' },
    { title: 'Medium', value: 'MEDIUM' },
    { title: 'High', value: 'HIGH' },
  ]
  const { selector: user } = useReduxStore('auth')
  const { selector: TicketFilter } = useReduxStore('filters')
  // console.log(TicketFilter)
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [controller, setController] = useState({ page: TicketFilter?.Ticketing?.filters?.page || 1, rowsPerPage: TicketFilter?.Ticketing?.filters?.rowsPerPage || 10 })
  const [filterAction, setFilterAction] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [ticketModal, setTicketModal] = useState({
    open: false,
    data: [],
  })
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: [],
  })
  const [permanentDeleteModal, setPermanentDeleteModal] = useState({
    open: false,
    data: "",
  })
  const [assignModel, setAssignModel] = useState({
    open: false,
    data: '',
  })
  const [assignInputs, setAssignInputs] = useState([
    {
      taskName: "",
      project_id: [],
      priority: "",
      assignTo_id: "",
      description: "",
      tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      notes: "",
      ReminderAction: true,
      attachments: [],
      ticket_id: "",
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ])

  const [assignErrors, setAssignErrors] = useState([
    {
      taskName: null,
      project_id: null,
      priority: '',
      assignTo_id: '',
      description: '',
      // tags: [],
      assignStartTime: null,
      assignEndTime: null,
      minutesBeforeReminder: 15,
      // notes: '',
      ReminderAction: true,
      // attachments: [],
      workType: "",
      taskType: "",
      subTask: "",
      taskCategory: "",
      complexity: "",
      hoursType: "",
    },
  ]);
  const [mergeModel, setMergeModel] = useState({
    open: false,
    ticketData: '',
  })
  const [search, setSearchText] = useState('')
  const [statusType, setStatusType] = useState('')

  const [fullTicketList, setAllTicketList] = useState([])
  const [selectedTickets, setSelecetedTickets] = useState([])
  const [groupList, setGroupList] = useState([])
  const [agentList, setAgentList] = useState([])
  const [reportedList, setReportedList] = useState([])
  const [skillAllList, setAllSkillList] = useState([])

  const [allTagsList, setAllTagList] = useState([])
  // const [projectList, setProjectList] = useState([])
  const [ticketListInput, setTicketListInput] = useState({
    agent: TicketFilter?.Ticketing?.filters?.agent || [],
    reportedBy: TicketFilter?.Ticketing?.filters?.reportedBy || [],
    search:'',
    team: [],
    ticket: [],
    created: '',
    skill: [],
    status: state?.filterTypes?.my_ticket || state?.filterTypes?.overdue_ticket ? [] : TicketFilter?.Ticketing?.filters?.status || [],
    priority: TicketFilter?.Ticketing?.filters?.priority || [],
    Tags: TicketFilter?.Ticketing?.filters?.Tags || [],
    Projects: TicketFilter?.Ticketing?.filters?.Projects || [],
    startDate: TicketFilter?.Ticketing?.filters?.startDate || '',
    endDate: TicketFilter?.Ticketing?.filters?.endDate || ''
  })
  let initialTicketType = 'ALL_TICKETS';

  if (state?.filterTypes?.due_today) {
    initialTicketType = "DUE_TODAY";
  }

  if (state?.filterTypes?.overdue_ticket) {
    initialTicketType = "MY_OVERDUE";
  }

  const [ticketType, setTicketType] = useState(TicketFilter?.Ticketing?.filters?.ticketType || initialTicketType);
  const [searchTickets, setSearchTickets] = useState('')
  const [selectedMergeTickets, setSelectedMergeTickets] = useState([])
  const [slotId, setslotID] = useState(null)
  const [ticketStatusList, setTicketStatusList] = useState({})
  const cacheTime = { cacheTime: 10000 }

  // get All projects list
  const { data: projectListData, isLoading: isprojectLoading } = useToGetFullPriject(cacheTime)
  useEffect(() => {
    getAllTicketList()
    AllSkillList()
    getAllGroupList()
    // getAllProjectList()
    getAllTagList()
    getAgentList()
    getReportedList()
  }, [])

  useEffect(() => {
    if (assignModel?.data) {
      const { subject, project_id, priority, description, tags, _id } = assignModel?.data
      let newPriority = priorityList?.filter(item => item?.value === priority)
      let newagentList = projectListData?.data?.filter(item => item?._id === project_id)
      let newTags = allTagsList?.filter(tagItem => {
        return tags.includes(tagItem?._id);
      });

      let newTaskType = taskTypeList.filter(item => item.value === assignModel?.data?.taskType)
      let newWorkType = workTypeList.filter(item => item.value === assignModel?.data?.workType)
      let newsubTask = SubTaskList.filter(item => item.value === assignModel?.data?.subTask)
      let newTaskCategory = TaskCategoryList.filter(item => item.value === assignModel?.data?.taskCategory)
      let newComplexity = complexityList.filter(item => item.value === assignModel?.data?.complexity)
      let newHoursType = hoursTypeList.filter(item => item.value === assignModel?.data?.hoursType)

      const newAssignInputs = {
        taskName: subject,
        project_id: newagentList || [],
        priority: newPriority,
        assignTo_id: '', // Add your logic for assignTo_id
        description: description,
        tags: newTags,
        assignStartTime: null,
        assignEndTime: null,
        minutesBeforeReminder: 15,
        notes: "",
        ReminderAction: true,
        attachments: [],
        ticket_id: _id,
        taskType: newTaskType,
        workType: newWorkType,
        subTask: newsubTask,
        taskCategory: newTaskCategory,
        complexity: newComplexity,
        hoursType: newHoursType,

      };
      setAssignInputs([newAssignInputs]);
    }

  }, [assignModel?.data])

  // Get All Tickets List
  const getAllTicketList = async () => {
    try {
      setSpinner(true)
      let response = await AllTicketList()
      setAllTicketList(response?.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Group List
  const getAllGroupList = async () => {
    try {
      setSpinner(true)
      let response = await getAllTeamList()
      setGroupList(response?.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Agent  List
  const getAgentList = async (groupId) => {
    let payload = {
      team_id: groupId?._id,
    }
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setAgentList(response?.data)
      // response?.data?.forEach((item) => {
      //   if (item?._id === user?.userInfo?._id) {
      //     setTicketListInput((prevProps) => ({ ...prevProps, agent: TicketFilter?.Ticketing?.filters?.agent || [item] }));
      //   }
      // });
    } catch (error) {
      // setAgentList([]);
      setTicketListInput((prevProps) => ({ ...prevProps, agent: '' }))
    }
    setSpinner(false)
  }

  // Get All Reported By  List
  const getReportedList = async () => {
    let payload = {reporting_user :true}
    try {
      setSpinner(true)
      let response = await userFullList(payload)
      setReportedList(response?.data)
    } catch (error) {
      setReportedList([]);
    }
    setSpinner(false)
  }

  // Get All Tag List
  const getAllTagList = async () => {
    try {
      setSpinner(true)
      let response = await allTagList()
      setAllTagList(response?.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Skill List
  const AllSkillList = async () => {
    try {
      setSpinner(true)
      let response = await getAllSkillList()
      setAllSkillList(response?.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }

  // Get All Project List
  // const getAllProjectList = async () => {
  //   try {
  //     setSpinner(true)
  //     let response = await allProjectList()
  //     setProjectList(response?.data)
  //   } catch (error) {
  //     // toast.error(error.message)
  //   }
  //   setSpinner(false)
  // }

  const handleInputChange = (event, newValue) => {
    const { name, value } = event.target
    setTicketListInput((prevProps) => ({ ...prevProps, [name]: value }))
    if (name === 'team') {
      getAgentList(value)
    }
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    // Perform any other actions you need here
  }

  // Listing payLoad
  let payload = {
    count: controller.rowsPerPage,
    page: controller.page,
    isDeleted: ticketType === "TRASH" ? true : false,
    search: ticketListInput.search,
    // project_id: ticketListInput.Projects._id,
    // team_id: ticketListInput.team._id,
    // agent_id: ticketListInput.agent._id,
    // status: ticketListInput.status ? ticketListInput.status?.value : "OPEN",
    // priority: ticketListInput?.priority ? ticketListInput?.priority?.value : null,
    // // createdby_id: "",
    // skill: ticketListInput.skill._id,
    // tags: ticketListInput.Tags._id ? [ticketListInput.Tags._id] : [],
    otherFilters: ticketType,
    // startDate: ticketType == 'NEW_AND_MY_OPEN_TICKETS' ? new Date() : '',
    // endDate: ticketType == 'NEW_AND_MY_OPEN_TICKETS' ? new Date() : '',
    startDate: ticketListInput?.startDate,
    endDate: ticketListInput?.endDate,
  }
  if (typeof statusType == 'boolean') payload.isActive = statusType
  // if (ticketListInput?.priority) payload.priority = ticketListInput?.priority?.value;
  // if (ticketListInput.status) payload.status = ticketListInput.status?.value;

  if (ticketListInput?.agent?.length > 0) {
    payload.agent_ids = ticketListInput.agent.map((item) => item._id)
  }
  if (ticketListInput?.reportedBy?.length > 0) {
    payload.reportedById  = ticketListInput.reportedBy.map((item) => item._id)
  }
  if (ticketListInput?.skill?.length > 0) {
    payload.skill = ticketListInput.skill.map((item) => item._id)
  }
  if (ticketListInput?.status?.length > 0) {
    payload.status = ticketListInput.status.map((item) => item.value)
  }
  if (ticketListInput?.priority?.length > 0) {
    payload.priority = ticketListInput.priority.map((item) => item.value)
  }
  if (ticketListInput?.Tags?.length > 0) {
    payload.tags = ticketListInput.Tags.map((item) => item._id)
  }
  if (ticketListInput?.Projects?.length > 0) {
    payload.project_ids = ticketListInput.Projects.map((item) => item._id)
  }
  if (state?.filterTypes?.completed && !ticketListInput?.status?.length && filterAction === false) {
    payload.status = ["CLOSED"]
  }
  useEffect(() => {
    // Dispatch the addFilter action with the current 'Ticketing' page and the filter
    dispatch(addFilter({
      page: 'Ticketing', filter: {
        agent: ticketListInput.agent,
        reportedById:ticketListInput.reportedBy,
        status: ticketListInput.status,
        priority: ticketListInput.priority,
        Tags: ticketListInput.Tags,
        Projects: ticketListInput.Projects,
        ticketType: ticketType,
        page: controller?.page,
        rowsPerPage: controller?.rowsPerPage,
        startDate: ticketListInput?.startDate,
        endDate: ticketListInput?.endDate,
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketListInput.status, ticketListInput.agent, ticketListInput.priority, ticketListInput.Tags, ticketListInput.Projects, ticketType, controller?.page, controller?.rowsPerPage, ticketListInput?.startDate, ticketListInput?.endDate]);

  // Fetch api
  const {
    data: ticketList,
    error: ticketError,
    isFetching: ticketIsFetching,
    refetch: refetchTicket,
  } = useToGetTicketList(payload, {
    enabled: false,
    ...cacheTime,
  })

  useEffect(() => {
    refetchTicket() // Call the renamed refetch function
  }, [controller, filterAction, ticketType])

  useEffect(() => {
    if (!ticketIsFetching && !ticketError && ticketList) {
      // Initialize ticketStatusList with the initial status values from the fetched data
      const initialStatusList = {}
      ticketList?.data?.forEach((ticket) => {
        initialStatusList[ticket?._id] = ticket?.status
      })
      setTicketStatusList(initialStatusList)
    }
  }, [ticketIsFetching, ticketError, ticketList])

  const handleStatusChange = async (event, ticketId) => {
    const selectedStatus = event.target.value
    // Create a copy of the current status object and update the status for the specific row
    const updatedStatusList = { ...ticketStatusList }
    updatedStatusList[ticketId] = selectedStatus
    setTicketStatusList(updatedStatusList)

    await UpdateStatus(ticketId, selectedStatus)
  }

  // Export Ticket Data
  const Export = async () => {
    try {
      setSpinner(true)
      let response = await ExportTicketList(payload)
      // const url = window.URL.createObjectURL(new Blob([response]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "Ticket.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //     link.remove();
      window.open(response.data.fileName);
      let tempLink = document.createElement('a');
      tempLink.setAttribute('download', response.data.fileName);
      tempLink.click();

    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }


  // Status
  const status = async (id, toggle) => {
    try {
      setSpinner(true)
      let response = await TicketStatus({
        _id: id,
        isActive: toggle,
      })
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const handleDelete = async () => {
    try {
      setSpinner(true)
      let response = await deleteTicket({
        _id: deleteModal?.data?._id?._id,
        isDeleted: deleteModal?.data?.isDeleted,
      })
      toast.success(response.message)
      setDeleteModal({ data: '', open: false })
      refetchTicket()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }


  // PermanentDelete
  const ticketPermanentDelete = async () => {
    try {
      setSpinner(true)
      const response = await PermanentDeleteTicket({ ticket_id: permanentDeleteModal.data })
      refetchTicket()
      toast.success(response.message)
      setPermanentDeleteModal({ data: '', open: false })
    } catch (error) {

    }
    setSpinner(false)
  }

  // Error Toast Message
  useEffect(() => {
    if (ticketError) {
      // toast.error(ticketError?.message);
    }
  }, [ticketError])

  const UpdateStatus = async (ticketId, newStatus) => {
    setSpinner(true)
    try {
      const payload = {
        _id: ticketId,
        status: newStatus,
      }
      let response = await updateTicket(payload)
      toast.success(response.message)
      refetchTicket()
    } catch (err) {
      toast.error(err.message)
    }
    setSpinner(false)
  }
  useEffect(() => {
    if (state?.filterTypes?.completed) {
      setTicketListInput((prev) => ({
        ...prev, status:
          [{ color: '#793FDF', title: 'Closed', value: 'CLOSED' }]
      }))
    }
    refetchTicket()
  }, [state?.filterTypes?.completed])

  // Apply Search Filter
  const applayFilter = () => {
    setController({ page: 1, rowsPerPage: 10 }, () => {
      refetchTicket()
    });
  }
  // Reset Filter
  const resetFilter = () => {
    setFilterAction((prev) => !prev)
    setStatusType()
    setTicketType("ALL_TICKETS")
    setSearchText('')
    setTicketListInput({
      agent: [],
      reportedBy:[],
      search:'',
      team: [],
      ticket: [],
      created: '',
      skill: [],
      status: [],
      priority: [],
      Tags: [],
      Projects: [],
    });
    // state?.filterTypes?.completed=""
  }

  // Handle Pagination
  const handlePagination = (obj) => {
    setController(obj)
  }

  // Handle Listing Row Check
  const handleRowCheck = (event, id) => {
    if (event.target.checked) {
      setSelecetedTickets((prev) => [...prev, id])
    } else {
      setSelecetedTickets((prev) => prev.filter((item) => item !== id))
    }
  }

  // Handle All selected Tickets
  const handleSelectAllClick = () => {
    if (ticketList && ticketList.data) {
      // Filter out tickets with isMerged set to true
      const unmergedTickets = ticketList.data.filter((item) => !item.isMerged)

      if (selectedTickets.length === unmergedTickets.length) {
        setSelecetedTickets([])
      } else {
        setSelecetedTickets(unmergedTickets.map((item) => item._id))
      }
    }
  }

  const handleCheckSlots = (isCheckedValue, item, index) => {
    if (isCheckedValue) {
      setSelectedMergeTickets(item._id)
      setslotID(index)
    } else {
      setSelectedMergeTickets(null)
      setslotID(null)
    }
  }

  // Ticket Merge MergeTicket
  const TicketMerge = async () => {
    if (!selectedTickets.length) return toast.warn('Please select ticket')
    let payload = {
      ticket_ids: selectedTickets,
      mergeToTicket_id: selectedMergeTickets,
    }
    try {
      let response = await MergeTicket(payload)
      setMergeModel((prev) => ({ ...prev, open: false }))
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
  }

  // Ticket Assign
  const TicketAssign = async () => {
    if (!selectedTickets.length) return toast.warn('Please select ticket')
    let payload = {
      ticket_ids: selectedTickets,
      assignTo_id: ticketListInput?.agent._id,
    }
    try {
      let response = await bulkAssignTicket(payload)
      setAssignModel({ data: "", open: false })
      toast.success(response.message)
      setTicketListInput((prevProps) => ({ ...prevProps, team: '', agent: '' }))
    } catch (error) {
      toast.error(error.message)
    }
  }

  // Assign Multiple Task

  // handel Assign multiple group's ,agent's during edit

  // Assign TO List
  const {
    data: assignToListData,
    // error: assignToError,
    // isLoading: isassignToLoading,
    // refetch: refetassignToList,
  } = useToGetUserFullList(cacheTime);

  // handel Assign Form inputs
  const handleAssignInputChange = (event, taskIndex) => {
    const { value, name, checked } = event.target;

    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs];
      updatedTasks[taskIndex][name] = value;

      if (name === 'ReminderAction') {
        updatedTasks[taskIndex][name] = checked;
        if (checked) {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 15;
        } else {
          updatedTasks[taskIndex]['minutesBeforeReminder'] = 0;
        }
      }
      // Clear errors for the current field
      setAssignErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        if (name !== "ReminderAction") {
          updatedErrors[taskIndex][name] = null;
        }
        return updatedErrors;
      });

      return updatedTasks;
    });
  };
  const handleFileChange = async (event, taskIndex) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) return toast.warn("You can only select up to 5 files.")
    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "TASK");
    try {
      setSpinner(true)
      let response = await uploadFile(fd)
      toast.success(response.message)
      setAssignInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        if (updatedTasks[taskIndex].attachments.length >= 5) {
          toast.warn('You can only select up to 5 files.');
        } else {
          const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
          response?.data?.forEach((cur, index) => {
            if (newSelectedFiles.length >= 5) {
              toast.warn('You can only select up to 5 files.');
            } else {
              newSelectedFiles?.push(cur?.db_name);
            }
          });
          if (newSelectedFiles.length <= 5) {
            updatedTasks[taskIndex].attachments = newSelectedFiles;
          }
        }
        return updatedTasks;

        // const newSelectedFiles = [...updatedTasks[taskIndex].attachments];
        // response?.data?.forEach((cur, index) => {
        //   newSelectedFiles?.push(cur?.db_name)
        // })
        // updatedTasks[taskIndex].attachments = newSelectedFiles;
        // return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  };

  // Handle Remove File
  const handleRemoveFile = async (taskIndex, fileIndex, file) => {
    let data = {
      fileName: file,
      fileType: "TICKET"
    }

    try {
      setSpinner(true)
      let response = await DeleteFile(data)
      toast.success(response.message)
      setAssignInputs((prevTaskFormInputs) => {
        const updatedTasks = [...prevTaskFormInputs];
        updatedTasks[taskIndex].attachments = updatedTasks[taskIndex].attachments.filter(
          (_, i) => i !== fileIndex
        );
        return updatedTasks;
      });
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)

  };
  // Handle Date

  const handleDateChange = (date, field, taskIndex) => {
    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs];
      updatedTasks[taskIndex][field] = date;
      return updatedTasks;
    });

    setAssignErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[taskIndex][field] = null;
      return updatedErrors;
    });
  };




  // task error message
  const TaskFormInputsErrorMessage = {
    taskName: requiredMessage('task name'),
    description: requiredMessage('description'),
    project_id: requiredSelect('project'),
    priority: requiredSelect('priority'),
    assignTo_id: requiredSelect('assign'),
    assignStartTime: requiredSelect('assign start time'),
    assignEndTime: requiredSelect('assign end time'),
    ReminderAction: "Please select reminder",
    taskType: requiredSelect("task type"),
    workType: requiredSelect("work type"),
    subTask: requiredSelect("sub task"),
    taskCategory: requiredSelect("task category"),
    complexity: requiredSelect("complexity"),
    hoursType: requiredSelect("hours type"),
  }

  // Error handling function
  const validateAllTasks = () => {
    const errorObjArray = [];

    for (let i = 0; i < assignInputs.length; i++) {
      const task = assignInputs[i];
      const errorObj = {};

      if (!task.taskName) {
        errorObj['taskName'] = TaskFormInputsErrorMessage['taskName'];
      }
      if (!task.description) {
        errorObj['description'] = TaskFormInputsErrorMessage['description'];
      }
      if (!task.project_id) {
        errorObj['project_id'] = TaskFormInputsErrorMessage['project_id'];
      }
      if (!task.priority) {
        errorObj['priority'] = TaskFormInputsErrorMessage['priority'];
      }
      if (!task.assignTo_id) {
        errorObj['assignTo_id'] = TaskFormInputsErrorMessage['assignTo_id'];
      }
      if (!task.assignStartTime) {
        errorObj['assignStartTime'] = TaskFormInputsErrorMessage['assignStartTime'];
      }
      if (!task.assignEndTime) {
        errorObj['assignEndTime'] = TaskFormInputsErrorMessage['assignEndTime'];
      }
      if (!task.taskType) {
        errorObj['taskType'] = TaskFormInputsErrorMessage['taskType'];
      }
      if (!task.workType) {
        errorObj['workType'] = TaskFormInputsErrorMessage['workType'];
      }
      if (!task.subTask) {
        errorObj['subTask'] = TaskFormInputsErrorMessage['subTask'];
      }
      if (!task.taskCategory) {
        errorObj['taskCategory'] = TaskFormInputsErrorMessage['taskCategory'];
      }
      if (!task.complexity) {
        errorObj['complexity'] = TaskFormInputsErrorMessage['complexity'];
      }
      if (!task.hoursType) {
        errorObj['hoursType'] = TaskFormInputsErrorMessage['hoursType'];
      }
      if (task.ReminderAction) {
        delete errorObj.ReminderAction;
      }
      // Add checks for other fields as needed
      errorObjArray.push(errorObj);
    }

    setAssignErrors(errorObjArray);
    return errorObjArray.every((errorObj) => Object.keys(errorObj).length === 0);
  };


  // Assign Multiple Agent submit api
  const MultipleAgentsAssignTicket = async () => {
    const isValid = validateAllTasks();
    if (!isValid) return toast.warn("Please fill all required fields")
    const convertData = (originalData) => {
      return originalData.map((task) => {
        return {
          taskName: task?.taskName,
          project_id: task?.project_id[0]?._id,
          priority: task?.priority[0]?.value,
          assignTo_id: task?.assignTo_id[0]?._id,
          description: task?.description,
          tags: task?.tags?.map((tag) => tag?._id),
          assignStartTime: task?.assignStartTime,
          assignEndTime: task?.assignEndTime,
          minutesBeforeReminder: task?.minutesBeforeReminder,
          notes: task?.notes,
          attachments: task?.attachments,
          taskType: task?.taskType[0]?.value,
          workType: task?.workType[0]?.value,
          subTask: task?.subTask[0]?.value,
          taskCategory: task?.taskCategory[0]?.value,
          complexity: task?.complexity[0]?.value,
          hoursType: task?.hoursType[0]?.value,
          ticket_id: assignModel?.data?._id
        };
      });
    };
    const newData = convertData(assignInputs);
    let newTaskFormInputs = [...newData]
    newTaskFormInputs?.map((cur, index) => {
      delete cur?.ReminderAction
      return cur
    })
    let payload = {
      // ticket_id: id,
      data: newTaskFormInputs
    }

    setSpinner(true)
    try {
      let response = await addTask(payload)
      // navigate('/tickets')
      refetchTicket()
      setAssignModel({ data: "", open: false })
      CloseFucntion("assignModel")
      setTimeout(() => {
        toast.success(response.message)
      }, 500)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
    // }
  }

  // assignInputs.ticketFullDetails.agentData
  const AssignMultipleTask = () => {
    // Calculate the maximum number of forms that can be added
    const maxFormsToAdd = Math.max(0, 5 - (assignModel?.data?.agentData.length || 0));
    if (assignInputs.length < maxFormsToAdd) {
      setAssignInputs((prevTaskFormInputs) => [
        ...prevTaskFormInputs,
        {
          taskName: assignModel?.data?.subject,
          project_id: assignModel?.data?.project_id,
          priority: "",
          assignTo_id: "",
          description: assignModel?.data?.description,
          tags: [],
          assignStartTime: null,
          assignEndTime: null,
          minutesBeforeReminder: 15,
          notes: "",
          ReminderAction: true,
          attachments: [],
          ticket_id: assignModel?.data?._id,
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ]);
      // Clear errors for the newly added task
      setAssignErrors((prevErrors) => [
        ...prevErrors,
        {
          taskName: null,
          project_id: null,
          priority: '',
          assignTo_id: '',
          description: '',
          assignStartTime: null,
          assignEndTime: null,
          ReminderAction: false,
          minutesBeforeReminder: 15, // Reset ReminderAction error
          workType: "",
          taskType: "",
          subTask: "",
          taskCategory: "",
          complexity: "",
          hoursType: "",
        },
      ]);
    }
  }

  // Remove Assigned
  const removeAssign = (assigIndex) => {
    setAssignInputs((prevTaskFormInputs) => {
      const updatedTasks = [...prevTaskFormInputs]
      updatedTasks.splice(assigIndex, 1) // Remove the task at the specified index
      return updatedTasks
    })
  }

  // Close modal And Empty
  const CloseFucntion = (type) => {
    if (type === "assignModel") {
      setAssignModel({ open: false, data: "" })
      setAssignInputs([{
        taskName: assignModel?.data?.subject,
        project_id: assignModel?.data?.project_id,
        priority: "",
        assignTo_id: "",
        description: assignModel?.data?.description,
        tags: [],
        assignStartTime: null,
        assignEndTime: null,
        minutesBeforeReminder: 15,
        notes: "",
        ReminderAction: true,
        attachments: [],
        ticket_id: assignModel?.data?._id,
        workType: "",
        taskType: "",
        subTask: "",
        taskCategory: "",
        complexity: "",
        hoursType: "",
      }])
    }
  }



  return {
    data: {
      controller,
      isLoading: spinner || ticketIsFetching || isprojectLoading,
      totalCount: ticketError?.message === 'No record found' ? 0 : ticketList?.totalCount || 0,
      ticketList: ticketError?.message === 'No record found' ? [] : ticketList?.data || [],
      assignToListData: assignToListData?.data || [],
      ticketModal,
      deleteModal,
      search,
      statusType,
      fullTicketList: fullTicketList.filter((item) => !selectedTickets?.includes(item._id)),
      groupList,
      agentList,
      allTagsList,
      skillAllList,
      projectList: projectListData?.data || [],
      assignModel,
      ticketListInput,
      mergeModel,
      numberOfSelected: selectedTickets?.length,
      selectedTickets,
      ticketType,
      searchTickets,
      selectedMergeTickets,
      ticketStatusList,
      slotId,
      TicketPermission: user,
      assignInputs,
      assignErrors,
      permanentDeleteModal,
      taskTypeList,
      SubTaskList,
      TaskCategoryList,
      complexityList,
      hoursTypeList,
      workTypeList,
      reportedList,
    },
    methods: {
      handleInputChange,
      handlePagination,
      resetFilter,
      applayFilter,
      setTicketModal,
      status,
      handleDelete,
      setDeleteModal,
      handleSearchChange,
      setStatusType,
      refetchTicket,
      onSelectAllClick: handleSelectAllClick,
      handleRowCheck,
      setAssignModel,
      setMergeModel,
      TicketMerge,
      setTicketType,
      setSearchTickets,
      // handleCheck,
      handleCheckSlots,
      TicketAssign,
      UpdateStatus,
      handleStatusChange,
      getAgentList,
      setTicketListInput,
      AssignMultipleTask,
      removeAssign,
      CloseFucntion,
      MultipleAgentsAssignTicket,
      handleAssignInputChange,
      handleDateChange,
      handleFileChange,
      handleRemoveFile,
      setAssignErrors,
      Export,
      setPermanentDeleteModal,
      ticketPermanentDelete
    },
  }
}

export default useTicketListing
