import React from 'react'
import Table from '../../../components/Table/Table'
import { Button, InputLabel } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete';
import UseAnnouncement from './Hooks/useAnnouncement';
import Modal from '../../../components/Modal'
import TextField from '../../../components/TextField'
import DesktopDateTimePicker from '../../../components/DesktopDateTimePicker'
import { ToastContainer } from 'react-toastify';
import { Spinner } from '../../../components/Spinner/Spinner';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { Pagination } from '../../../components/pagination/Pagination';
import SelectInput from '../../../components/SelectInput'

const Announcement = () => {
    const { methods, data } = UseAnnouncement()
    const { setAnnouncementModal,
        handleInputChange,
        handleAddSubmit,
        setAnnouncementInputs,
        clearForm,
        handlePagination,
        setError } = methods
    const { announcementModal,
        error,
        spinner,
        announcementInputs,
        listData,
        totalCount,
        shiftList,
        controller } = data

    const TableHead = [
        { label: '#', id: '' },
        { label: 'Title', id: '' },
        { label: 'Description', id: '' },
        { label: 'Start Date Time', id: '' },
        { label: 'End Date Time', id: '' },
        { label: 'Action', id: '' },
    ]
    const getDetails = (item) => {
        setAnnouncementInputs((pre) => ({
            ...pre, title: item?.title,
            description: item?.description,
            startDateTime: new Date(item?.startDate),
            endDateTime: new Date(item?.endDate),
            shift:item?.shiftId
        }))
    }
    return (
        <div>
            <ToastContainer />
            {spinner && <Spinner />}
            <div className="container-fluid py-3">
                <div className="d-flex justify-content-end">
                    <Button className="dropDownButtons11" onClick={() => { setAnnouncementModal((prev) => ({ ...prev, open: true })); clearForm() }}>
                        <AddIcon /> Add New
                    </Button>
                </div>
                <div className="row py-3">
                    <div className="col-lg-12 ticet">
                        <div className="bg shadow raduus">
                            <div className="">
                                <div className="mt-2">
                                    <table className="table">
                                        <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                                        <tbody>
                                            {
                                                listData?.map((item, index) =>
                                                    <tr className='text-start rounded-row tablebess' key={index} style={{ borderRadius: '50px', backgroundColor: '#fff', }}>
                                                        <td className='py-4'>{controller.rowsPerPage * (controller.page - 1) + index + 1}</td>
                                                        <td>{item?.title}</td>
                                                        <td>{item?.description}</td>
                                                        <td>{moment(item?.startDate).format('DD-MM-YYYY')} {moment(item?.startDate).format('LT')}</td>
                                                        <td>{moment(item?.endDate).format('DD-MM-YYYY')} {moment(item?.endDate).format('LT')}</td>
                                                        <td><EditIcon color="primary" className='pointer' onClick={() => { setAnnouncementModal((prev) => ({ ...prev, data: item, open: true })); getDetails(item) }} /></td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                    {!listData?.length && <div className="text-center">No record found</div>}
                                    <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size="md"
                show={announcementModal.open}
                onHide={() => setAnnouncementModal((prev) => ({ ...prev, open: false }))}
                headerContent={<h5 className="py-2">{announcementModal?.data?._id ? 'Edit' : 'Add'} Announcement</h5>}
                bodyContent={
                    <div className="row p-3">
                        <div className="col-lg-12">
                            <TextField
                                Lable="Title"
                                Name="title"
                                variant="outlined"
                                ClassName="form-field-design "
                                Require={true}
                                HandleInputChange={handleInputChange}
                                // onBlur={(e) => handleInputChange(e, index)}
                                Value={announcementInputs.title}
                                width={100}
                                Error={error.title}
                            />
                        </div>
                        <div className="col-lg-6 mt-3">
                            <DesktopDateTimePicker
                                className="w-100 form-field-design"
                                isRequired={true}
                                Label={'Start Date Time'}
                                handleDateChange={(date) => {
                                    setAnnouncementInputs((prevProps) => ({ ...prevProps, startDateTime: date }))
                                    setError((prev) => ({ ...prev, reportDate: '' }))
                                }}
                                SelectedDate={announcementInputs?.startDateTime}
                            />
                            {error?.startDateTime && <span className='text-danger'>{error?.startDateTime}</span>}
                        </div>
                        <div className="col-lg-6 mt-3">
                            <DesktopDateTimePicker
                                className="w-100 form-field-design"
                                isRequired={true}
                                Label={'End Date Time'}
                                handleDateChange={(date) => {
                                    setAnnouncementInputs((prevProps) => ({ ...prevProps, endDateTime: date }))
                                    setError((prev) => ({ ...prev, reportDate: '' }))
                                }}
                                SelectedDate={announcementInputs?.endDateTime}
                            />
                            {error?.endDateTime && <span className='text-danger'>{error?.endDateTime}</span>}
                        </div>
                        <div className="col-lg-12 my-2">
                                <SelectInput
                                    Lable={'Shift'}
                                    // multiple={true}
                                    Options={shiftList}
                                    keyName={'name'}
                                    className="form-field-design"
                                    Name="shift"
                                    SelectValue={announcementInputs.shift}
                                    HandleChange={(e) => { handleInputChange(e) }}
                                    Error={error.shift}
                                    variant={'outlined'}
                                    isRequired={true}
                                />
                            </div>
                        <div className="col-lg-12 mt-3">
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>Description  <span className="text-danger"> *</span></InputLabel>
                            <textarea className='form-control' cols={5} rows={3} name="description" value={announcementInputs?.description} onChange={handleInputChange}></textarea>
                            {error?.description && <span className='text-danger'>{error?.description}</span>}

                        </div>
                        <div className='d-flex justify-content-end mt-5'>
                            <Button variant="outlined" onClick={() => { setAnnouncementModal((prev) => ({ ...prev, open: false })) }}>Cancel</Button>
                            {
                                announcementModal?.data?._id ?
                                    <Button variant="contained" className='ms-2' onClick={handleAddSubmit}>Update</Button>
                                    :
                                    <Button variant="contained" className='ms-2' onClick={handleAddSubmit}>Submit</Button>
                            }
                        </div>

                    </div>
                }
            />
        </div>
    )
}

export default Announcement
