import React from 'react'
import { useOutletContext } from 'react-router-dom'

const AgentPersonalDetails = () => {
    const { data } = useOutletContext()
    const { teamMemberInputs, profilePath } = data
    return (
        <div className='p-5'>
            <h3>Document</h3>
            <hr />
            {
                teamMemberInputs?.document?.map((item, index) =>
                    <div className='row'>
                        <div className="col-lg-6 mt-4">
                            <p className="">
                                <span className="fw-bold">Document :</span> <span className="text-muted">{teamMemberInputs?.document?.[index]?.docType || "--"}</span>
                            </p>
                        </div>
                        <div className="col-lg-6 mt-4">
                            <p className="">
                                <span className="fw-bold">Document Number :</span> <span className="text-muted">{teamMemberInputs?.document?.[index]?.number || "--"}</span>
                            </p>
                        </div>
                        <div className="col-lg-12 mt-4">
                            <div className='col-lg-12 Ticket_Attachment'>
                                <div className="row">
                                    {item.file?.map((cur, fileIndex) => {
                                        const filetype = cur?.split('.').pop();
                                        if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <img className='pointer' src={`${profilePath}${cur}`} alt="" onClick={() => window.open(`${profilePath}${cur}`)} />
                                                </div>
                                            );
                                        }
                                        if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <video className='pointer' src={`${profilePath}${cur}`} controls onClick={() => window.open(`${profilePath}${cur}`)}> </video>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                <i className="fa-sharp fa-regular fa-file pointer" style={{ fontSize: '48px', color: 'red' }}></i>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h3>Permanent Address</h3>
                    <hr />
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Address Line 1 :</span> <span className="text-muted">{teamMemberInputs?.perAddress1 || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Address Line 2 :</span> <span className="text-muted">{teamMemberInputs?.perAddress2 || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Country :</span> <span className="text-muted">{teamMemberInputs?.allDetails?.employeedetails?.permanentAddress?.countryName || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">State :</span> <span className="text-muted">{teamMemberInputs?.allDetails?.employeedetails?.permanentAddress?.countryName || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">City :</span> <span className="text-muted">{teamMemberInputs?.perCity || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Pin Code :</span> <span className="text-muted">{teamMemberInputs?.perPincode || "--"}</span>
                    </p>
                </div>

            </div>

            <div className="row mt-5">

                <div className="col-lg-12">
                    <h3>Current Address</h3>
                    <hr />
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Address Line 1 :</span> <span className="text-muted">{teamMemberInputs?.currAddress1 || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Address Line 2 :</span> <span className="text-muted">{teamMemberInputs?.currAddress2 || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Country :</span> <span className="text-muted">{teamMemberInputs?.allDetails?.employeedetails?.currentAddress?.countryName || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">State :</span> <span className="text-muted">{teamMemberInputs?.allDetails?.employeedetails?.currentAddress?.stateName || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">City :</span> <span className="text-muted">{teamMemberInputs?.currCity || "--"}</span>
                    </p>
                </div>
                <div className="col-lg-6 mt-1">
                    <p className="">
                        <span className="fw-bold">Pin Code :</span> <span className="text-muted">{teamMemberInputs?.currPincode || "--"}</span>
                    </p>
                </div>

            </div>
            <div className="row mt-5 mb-5">
                <div className="col-lg-12">
                    <h3>Family Details</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.familyDetails?.map((item, index) =>
                        <>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Name :</span> <span className="text-muted">{teamMemberInputs?.familyDetails?.[index]?.name || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Relation :</span> <span className="text-muted">{teamMemberInputs?.familyDetails?.[index]?.relation || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Contact Number :</span> <span className="text-muted">{teamMemberInputs?.familyDetails?.[index]?.contactNo || "--"}</span>
                                </p>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default AgentPersonalDetails
