import React from 'react'
import { Pagination } from '../../../components/pagination/Pagination'
import Table from '../../../components/Table/Table'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
const TicketsDetails = () => {
  const { data, methods } = useOutletContext()
  // const {  } = props;
  const { controller,totalCount, ticketList } = data
  const { handlePagination, } = methods

  const TableHead = [
    { label: 'Ticket ID', id: '' },
    // { label: "Name", id: "" },
    { label: 'Agent', id: '' },
    { label: 'Status', id: '' },
    { label: 'Close on', id: '' },
    { label: 'Ontime/Late', id: '' },
  ]

  return (
    <div className="col-12 ticet">
      <div className="bg shadow raduus">
        <div className="">
          <div className="mt-2">
            <table className="table">
              <Table
                TableHeadData={TableHead}
                // numberOfSelected={numberOfSelected}
                rowCount={ticketList?.length}
                // onSelectAllClick={onSelectAllClick}
              />
              <tbody>
                {ticketList.map((item, index) => (
                  <tr
                    key={item._id + index}
                    className="text-start rounded-row "
                    style={{ borderRadius: '50px', backgroundColor: '#fff' }}
                  >
                    <td className="table-link">{item?.ticketId}</td>
                    <td>{item?.agent||"--"}</td>
                    <td>{item?.status||"--"}</td>
                    <td>{item?.closedOn ? moment(item?.closedOn).calendar() : '--'}</td>
                    <td>{new Date(item.dueDate) > new Date(item.closedOn) ? 'Ontime' : 'Late'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!ticketList.length && <div className="text-center">No record found</div>}
            <Pagination count={totalCount} controller={controller} setController={handlePagination} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketsDetails
