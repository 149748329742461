/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Button, Fab } from '@mui/material'
import Table from '../../components/Table/Table'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../../components/Modal'
import { ToastContainer } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import { Pagination } from '../../components/pagination/Pagination'
import TaskFilters from './TaskFilters'
import TaskForm from './TaskForm'
import useTask from './Hooks/useTask'
import moment from 'moment'
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ClearIcon from '@mui/icons-material/Clear'
import SelectInput from '../../components/SelectInput'
import { removeHtmlTags } from '../../utils'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';

const Task = () => {
  const navigate = useNavigate()

  const { data, methods } = useTask()
  const { taskList, totalCount, controller, isLoading, deleteModal, taskModel, TaskPermission, permanentDeleteModal, importTaskModal, selectedFiles,taskType,

    breakModal,
    shiftList
  } = data
  const { handlePagination, setDeleteModal, handleDelete, setTaskModel, CloseFucntion, Export,
    taskPermanentDelete,
    setPermanentDeleteModal,
    handleTaskImportFileChange,
    fileType,
    handleTaskImportRemoveFile,
    setSelectedFiles,
    setImportTaskModal,
    TaskUpload,
    setTaskType,
    setBreakModal,
    shifts,
    addBreaks,
    setShiftList
  } = methods
  const { add,import: taskImport, export: exportFile,idleUser } = TaskPermission?.userInfo?.permission?.task

  const TableHead = [
    { label: 'Task Name', id: '' },
    { label: 'Project Name', id: '' },
    { label: 'Assign To', id: '' },
    { label: 'Work Type', id: '' },
    { label: 'Priority', id: '' },
    { label: 'Duration', id: '' },
    { label: 'Status', id: '' },
    { label: 'Tags', id: '' },
    { label: 'Action', id: '' },
  ]

  const AllTaskEnum = [
    { title: "All Tasks", value: "ALL_TASKS" },
    { title: "Due today", value: "DUE_TODAY" },
    { title: "My Overdue Tasks", value: "MY_OVERDUE" },
  ];

  const [isDragging, setIsDragging] = useState(false);
  const [fileError, setFileError] = useState("")
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];

      if (file.type === 'application/vnd.ms-excel' || file.name.endsWith('.xlsx')) {
        handleTaskImportFileChange(files);
      } else {
        setFileError('Please select a valid XLS file.');
      }
    }
  };
  // console.log(breakModal, 'breakModal')
  return (
    <>
      <ToastContainer />
      {isLoading && <Spinner />}
      <div className="container-fluid py-3">
        <div className="row">
          <div className="d-flex justify-content-between">
            {/* <div className="d-flex align-items-end"> */}
            {/* <div > */}
            {/* <RecommendOutlinedIcon color="success" /><b className='ms-2 me-4'>Approved </b>
                <BlockOutlinedIcon sx={{ color: "red" }} /><b className='ms-2 me-4'>Reject </b>
                <MoreTimeOutlinedIcon color="primary" /><b className='ms-2 me-4'>Time log filled  </b> */}
            <div className="mb-2 w-25">
              <SelectInput
                // Lable={"All Tickets"}
                Options={AllTaskEnum}
                keyName={'title'}
                Name="taskType"
                SelectValue={taskType}
                HandleChange={(e) => setTaskType(e.target.value)}
                variant={'outlined'}
              />
            </div>
            {/* </div> */}
            {/* <SearchAndStatusFilter onSearchChange={handleSearchChange} setStatusType={setStatusType} search={search} statusType={statusType} reset={resetFilter} applayFilter={applayFilter} /> */}
            {/* </div> */}
            <div className="d-flex">
              {
                TaskPermission?.userInfo?.role_id?.roleTitle === 'ADMIN' ? null
                  :
                  <div className="ms-2">
                    <Button className="dropDownButtons11"
                      onClick={() => {
                        shifts();
                        // setBreakModal((pre) => ({ ...pre, open: true, data: '', break: false }))
                      }}

                      startIcon={<FreeBreakfastIcon />}
                    >
                      Breaks
                    </Button>
                  </div>
              }

              {idleUser?.value &&
                <div className="ms-2">
                  <Button className="dropDownButtons11"
                    // onClick={() => setIdleUsersModal({ data: true, open: true })}
                    onClick={() => navigate('/idle-user')}

                  >
                    Idle Users
                  </Button>
                </div>
              }
              {taskImport?.value && (
                <div className="ms-2">
                  <Button className="dropDownButtons11" onClick={() => setImportTaskModal(prev => ({ ...prev, open: true }))}>
                    Import Task
                  </Button>
                </div>
              )}
              {/* {approval?.value && (
                <div className="ms-2">
                  <Button className="dropDownButtons11" onClick={() => navigate('/task/approve-task')}>
                    Approve Task
                  </Button>
                </div>
              )} */}
              {exportFile?.value && <div className="ms-2">
                <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={() => Export()}>Export</Button>
              </div>}
              {add?.value && (
                <div className="ms-2">
                  <Button className="dropDownButtons11" onClick={() => setTaskModel((prev) => ({ ...prev, open: true }))} >
                    <AddIcon /> Add New
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row py-3">

          <div className="col-lg-10 ticet">
            <div className="bg shadow raduus">
              <div className="">
                <div className="mt-2">
                  <table className="table">
                    <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                    <tbody>
                      {taskList?.map((item, index) => {
                        let textColor = 'gray';
                        let icon = ""
                        if (item.approvalStatus === "APPROVED" && item.isTimeLogged === true) {
                          // textColor = '#8bc34a';
                          icon = (
                            <>
                              <span title="Approved"> <RecommendOutlinedIcon color="success" sx={{ margin: "0 3px" }} /></span>
                              <span title="Time log filled"><MoreTimeOutlinedIcon color="primary" /></span>
                            </>
                          );
                        } else if (item.approvalStatus === "APPROVED") {
                          // textColor = '#8bc34a';
                          icon = <span title="Approved"> <RecommendOutlinedIcon color="success" title="Time log filled" /></span>
                        } else if (item.isTimeLogged === true) {
                          // textColor = 'rgb(132 127 127)';
                          icon = <span title="Time log filled"><MoreTimeOutlinedIcon color="primary" /></span>
                        } else if (item.approvalStatus === "REJECTED") {
                          // textColor = 'rgb(240 88 20)';
                          icon = <span title='Reject'><BlockOutlinedIcon sx={{ color: "red" }} /></span>
                        } else if (item.status === "CANCELLED") {
                          // textColor = 'rgb(132 127 127)';
                        }

                        return (
                          <tr
                            key={item._id + index}
                            className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}
                            style={{
                              borderRadius: '50px',
                              backgroundColor: '#fff',
                            }}
                          >
                            <td className="table-link" onClick={() => navigate(`/task/taskdetails/${item?._id}`)}>
                              {item?.taskName || '--'}
                            </td>
                            <td className="table-link" onClick={() => navigate(`/projects/${item?.project_id}/ticket`, { state: { projectId: item?.project_id } })}>
                              {item?.project || '--'}
                            </td>
                            <td >{item?.assignTo || '--'}</td>
                            <td>{item?.workType || '--'}</td>
                            <td>{item?.priority || '--'}</td>
                            <td>
                              <span>
                                {moment(item?.assignStartTime).format('lll')}
                                <br />
                                to
                                <br />
                                {moment(item?.assignEndTime).format('lll')}
                              </span>
                            </td>
                            <td className='p-4' style={{ color: textColor }}>{item.status || '--'}
                              {/* <br /> */}
                              {/* {(item.approvalStatus === "APPROVED" || item.isTimeLogged === true || item.approvalStatus === "REJECTED") && icon} */}
                            </td>
                            <td>
                              <span className="d-flex flex-wrap">
                                {item?.tagsData.length
                                  ? item?.tagsData?.map((tag) => {
                                    return (
                                      <div key={tag?._id} className="tag-list-chips cursor-pointer me-1 mb-1" style={{ color: '#fff', background: tag?.color, border: tag?.color }}>
                                        <span style={{ fontSize: '11px' }}>{tag?.tagName}</span>
                                      </div>
                                    )
                                  })
                                  : '--'}
                              </span>
                            </td>
                            <td>
                              {/* {!item?.isTimeLogged ?  */}

                              <div className="dropdown d-flex p-0 align-items-center" style={{ minHeight: '50px' }}>
                                <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                  <li style={{ cursor: 'pointer' }} onClick={() => setTaskModel({ clone: true, data: item?._id, open: true, })} className="dropdown-item">
                                    Clone
                                  </li>
                                  {item?.timelogStatus !== "APPROVED" && <>
                                    {(item.approvalStatus === "APPROVED") && <li style={{ cursor: 'pointer' }} onClick={() => setTaskModel({ data: item?._id, open: true })} className="dropdown-item">
                                      Edit
                                    </li>}
                                    {
                                      !item?.isDeleted ? (  // Check if both isTimeLogged and isDeleted are false
                                        <li className="dropdown-item pointer" onClick={() => setDeleteModal({ data: { _id: item?._id, isDeleted: !item?.isDeleted }, open: true })}>
                                          Delete
                                        </li>
                                      ) : (  // If at least one of them is true, render the "Recover" option
                                        <li className="dropdown-item" onClick={() => setDeleteModal({ data: { _id: item?._id, isDeleted: !item?.isDeleted }, open: true })}>
                                          Recover
                                        </li>
                                      )
                                    }
                                    {item.isDeleted && <li className="dropdown-item pointer" onClick={() => setPermanentDeleteModal({ data: item?._id, open: true })}>
                                      Permanent Delete
                                    </li>
                                    }
                                  </>
                                  }
                                </ul>
                              </div>
                              {/* : <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />} */}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {!taskList.length && <div className="text-center">No record found</div>}
                  <Pagination count={totalCount} controller={controller} setController={handlePagination} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 ticet mt-2">
            <TaskFilters data={data} methods={methods} />
          </div>
        </div>
      </div>

      {/* Break Modal */}
      <Modal
        size="md"
        show={breakModal.open}
        CloseFucntion={() => {
          if (!breakModal.disabled) {
            setBreakModal((prev) => ({ ...prev, open: false, data: '', break: false }))
            setShiftList([])
          }
        }
        }
        headerContent={<h5 className="py-2">Breaks</h5>}
        bodyContent={
          <div>
            {shiftList?.length ? (
              <div style={{ height: `140px`, width: "100%", overflowY: "auto" }}>
                {shiftList?.map((item, index) => (
                  (item.status === 'END' || shiftList.every(item => item.status === 'START' || item.status === 'COMPLETED')) ?
                    <Stack direction="row" spacing={1} className='p-2 ' key={index}>
                      <Chip
                        label={`${removeHtmlTags(item?.reason)} 
                      ${item?.matchedUserBreaks?.length ? `(${moment(item?.matchedUserBreaks[0]?.startTime).format('LT')} - ${item?.matchedUserBreaks[0]?.endTime ? moment(item?.matchedUserBreaks[0]?.endTime).format('LT') : ''})` : ''}`}
                        variant="outlined"
                        onClick={() => { setBreakModal((pre) => ({ ...pre, data: { breakId: item?.breakId } })) }}
                        disabled={item?.status === 'COMPLETED' || breakModal?.disabled}
                        sx={{
                          backgroundColor: breakModal?.data?.breakId === item?.breakId ? '#cdd1ce' : 'transparent',
                          width: '150px',
                          flex: '1 0 auto',
                        }}
                      />
                    </Stack>
                    : null
                ))}
              </div>
            )

              : (
                <div className='text-center m-3 p-4'>
                  no record found
                </div>
              )}

            <div className="d-flex justify-content-end mt-4">
              {
                breakModal?.break ?
                  <Button variant="contained" className="ms-2" disabled={breakModal?.data ? false : true} onClick={() => addBreaks('END')}>
                    End Break
                  </Button>
                  :
                  <Button variant="contained" className="ms-2" disabled={breakModal?.data ? false : true} onClick={() => addBreaks('START')}>
                    Start Break
                  </Button>
              }
            </div>
          </div>
        }
      />
      {/* Idel User Task Modal */}
      {/* <Modal
        size="md"
        show={idleUsersModal.open}
        onHide={() => {
          setIdleUsersModal((prev) => ({ ...prev, open: false }))
        }
        } headerContent={<h5 className="py-2">Idle User's</h5>}
        bodyContent={
          <div>
            {IdleUserList.length ? <div style={{ height: `225px`, width: "100%", overflowY: "auto" }}>
              {IdleUserList?.map((item, index) => {
                return <div key={index} className={index % 2 === 0 ? 'light-background' : 'dark-background'}>
                  <span> {`${toCamelCase(item?.firstName)} ${toCamelCase(item?.lastName)}`}</span>
                </div>
              })}
            </div> :
              <div className='border rounded text-center m-3 p-4'>
                no user found
              </div>
            }
          </div>
        }
      /> */}
      {/* Import Task Modal */}
      <Modal
        size="md"
        show={importTaskModal.open}
        onHide={() => {
          setSelectedFiles([])
          setImportTaskModal((prev) => ({ ...prev, open: false }))
        }
        } headerContent={<h5 className="py-2">Import Task</h5>}
        bodyContent={
          <div className="">
            {!selectedFiles.length ?


              <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: `2px dashed ${isDragging ? 'blue' : '#ccc'}`,
                  padding: '20px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <p>{isDragging ? 'Release to drop' : 'Drag and drop a file here, or click to select a file.'}</p>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={(e) => handleTaskImportFileChange(e.target.files)}
                  style={{ display: 'none' }}
                  accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <Button type="button" onClick={() => fileInputRef.current.click()}>
                  Select File
                </Button>
                {fileError && <div className='text-danger'>{fileError}</div>}
              </div>

              // <div className="col-12 bodrerer" style={{ border: `${!selectedFiles.length ? "2px dashed #aaaaaa" : "none"}` }}>
              //   <label htmlFor="inputTag">
              //     <AiOutlineCloudUpload className="fs-4" />
              //     <input id="inputTag" type="file" name="file" onChange={handleTaskImportFileChange} />
              //     <br />
              //     Drag & Drop to upload
              //     <br />
              //   </label>
              //   {error?.selectedFiles && <div className='text-danger'>{error?.selectedFiles}</div>}
              // </div>
              :
              <div className='d-flex justify-content-center p-5' style={{
                border: `2px dashed ${isDragging ? 'blue' : '#ccc'}`,
              }}>
                {selectedFiles.map((file, fileIndex) => (
                  <div key={fileIndex}>
                    <div className='d-flex justify-content-center'>
                      <span style={{ width: '80px', height: '80px' }} className="position-relative d-block">
                        {fileType(file, fileIndex, "TIMELOG")}
                        <Fab
                          className="m-2"
                          sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                          onClick={() => {
                            handleTaskImportRemoveFile(fileIndex)
                            setFileError("")
                          }}
                        >
                          <ClearIcon sx={{ fontSize: '15px' }} />
                        </Fab>
                      </span>
                    </div>
                    <div >{file.split("_").pop()}</div>
                  </div>
                ))}
              </div>}


            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => {
                setSelectedFiles([])
                setImportTaskModal((prev) => ({ ...prev, open: false }))
              }} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={TaskUpload}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{!deleteModal?.data?.isDeleted ? 'Recover' : 'Delete'} Task?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to {!deleteModal?.data?.isDeleted ? 'recover' : 'delete'} task? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={handleDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

      {/* Delete Permanent Modal */}
      <Modal
        size="md"
        show={permanentDeleteModal.open}
        onHide={() => setPermanentDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5 className="py-2">{'Permanent Delete'} Task?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to permanently delete this task?  Deleting this task will also remove the associated timelog.</p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="outlined" onClick={() => setPermanentDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
              <Button variant="contained" className="ms-2" onClick={taskPermanentDelete}>
                Yes
              </Button>
            </div>
          </div>
        }
      />

      {/* Task Form */}
      <Modal
        size="xl"
        CloseFucntion={() => CloseFucntion('taskModel')}
        show={taskModel.open}
        onHide={() => setTaskModel((prev) => ({ ...prev, open: true }))}
        headerContent={<h5 style={{ fontWeight: '600' }}>{taskModel?.data ? (taskModel?.clone ? 'Clone Task' : 'Edit Task') : 'Add Task'} </h5>}
        bodyContent={
          <>
            <TaskForm data={data} methods={methods} />
          </>
        }
      />
    </>
  )
}

export default Task
