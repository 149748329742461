import React from 'react'
import moment from 'moment'
import { useOutletContext } from 'react-router-dom'

const AgentOtherDetails = () => {
    const { data } = useOutletContext()
    const { teamMemberInputs, profilePath } = data
    return (
        <div className='p-5'>
            <div className="row mt-4">
                <div className="col-lg-12">
                    <h3>Previous Experience</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.previousExperience?.map((item, index) => (
                        <React.Fragment key={`previousExperience-${index}`}>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Company :</span> <span className="text-muted">{teamMemberInputs?.previousExperience?.[index]?.company || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Designation :</span> <span className="text-muted">{teamMemberInputs?.previousExperience?.[index]?.designation || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Start Date :</span> <span className="text-muted">{moment(teamMemberInputs?.previousExperience?.[index]?.startDate).format('DD-MM-YYYY') || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">End Date :</span> <span className="text-muted">{moment(teamMemberInputs?.previousExperience?.[index]?.endDate).format('DD-MM-YYYY') || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Duration :</span> <span className="text-muted">{teamMemberInputs?.previousExperience?.[index]?.duration || "--"}</span>
                                </p>
                            </div>
                            <div className="col-lg-6 mt-2">
                                <p className="">
                                    <span className="fw-bold">Reason To Resign :</span> <span className="text-muted">{teamMemberInputs?.previousExperience?.[index]?.reasonToResign || "--"}</span>
                                </p>
                            </div>
                            <div className='col-lg-12 Ticket_Attachment'>
                                <div className="row">
                                    {item.file?.map((cur, fileIndex) => {
                                        const filetype = cur?.split('.').pop();
                                        if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <img className='pointer' src={`${profilePath}${cur}`} alt="img" onClick={() => window.open(`${profilePath}${cur}`)}/>
                                                </div>
                                            );
                                        }
                                        if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <video className='pointer' src={`${profilePath}${cur}`} controls onClick={() => window.open(`${profilePath}${cur}`)}> </video>
                                                </div>
                                            );
                                        }
                                        return (
                                            <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>

            <div className="row mt-5">
                <div className="col-lg-12">
                    <h3>Education Qualification</h3>
                    <hr />
                </div>
                {
                    teamMemberInputs?.educationQualification?.map((item, index) => (
                        <React.Fragment key={`educationQualification-${index}`}>
                            <div className='row'>
                                <div className="col-lg-6 mt-2">
                                    <p className="">
                                        <span className="fw-bold">Course :</span> <span className="text-muted">{teamMemberInputs?.educationQualification?.[index]?.course || "--"}</span>
                                    </p>
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <p className="">
                                        <span className="fw-bold">Specialization :</span> <span className="text-muted">{teamMemberInputs?.educationQualification?.[index]?.specialization || "--"}</span>
                                    </p>
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <p className="">
                                        <span className="fw-bold">School/University :</span> <span className="text-muted">{teamMemberInputs?.educationQualification?.[index]?.school_University || "--"}</span>
                                    </p>
                                </div>
                                <div className="col-lg-6 mt-2">
                                    <p className="">
                                        <span className="fw-bold">Year :</span> <span className="text-muted">{moment(teamMemberInputs?.educationQualification?.[index]?.year).format('DD-MM-YYYY') || "--"}</span>
                                    </p>
                                </div>
                                <div className='col-lg-12 Ticket_Attachment'>
                                    <div className="row">
                                        {item.file?.map((cur, fileIndex) => {
                                            const filetype = cur?.split('.').pop();
                                            if (['jpg', 'jpeg', 'png'].includes(filetype)) {
                                                return (
                                                    <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                        <img className='pointer' src={`${profilePath}${cur}`} alt="img" onClick={() => window.open(`${profilePath}${cur}`)}/>
                                                    </div>
                                                );
                                            }
                                            if (['mp4', 'wmv', 'mov'].includes(filetype)) {
                                                return (
                                                    <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                        <video className='pointer' src={`${profilePath}${cur}`} controls onClick={() => window.open(`${profilePath}${cur}`)}> </video>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div key={fileIndex} className="col-4 mb-3 mx-0 position-relative">
                                                    <i className="fa-sharp fa-regular fa-file cursor" style={{ fontSize: '48px', color: 'red' }}></i>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}

export default AgentOtherDetails
