import React from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'

const AgentDetails = () => {
  const { data } = useOutletContext()
  // const {  } = props;
  const { leavePolicyCount, TeamPermission } = data

  const navigate = useNavigate()
  return (
    <div className="col-12 ticet">
      <div className="bg shadow raduus">
        <div className="">
          <div className="mt-2">

            <div className='row p-5'>
              {leavePolicyCount?.map((item) =>
                <div className="col-3 border bg-white m-2" style={{ borderRadius: "20px" }} >
                  <div className='row'>
                    <div className='d-flex justify-content-between'>
                      <div className="col-12 mt-3  text-center">
                        <h3>{item?.name} <i>{item.code}</i></h3>
                      </div>
                    </div>
                    <div className="col-12 mb-2 text-center">
                      <h1 title='Consumed / Alloted'>{`${item?.takenLeaves}/${item?.entitledLeaves}`}</h1>
                      {
                        item?.code==='EL'&&
                        <>
                        <p className='m-0'>This year - {item?.elMonthlyCredit}</p>
                        <p className='m-0 mb-1'>Leave Lapse - {item?.leaveLapse}</p>
                        </>
                      }
                      <p>{item?.leaveType}</p>
                      <p className='fw-bold'>{item?.type}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {
              TeamPermission?.userInfo?.permission?.leave?.addIndividual.value ?
                <div className='d-flex justify-content-end me-3 pb-5'>
                  <Button size='small' variant="contained" onClick={() => navigate(`add`)}><AddIcon /></Button>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentDetails
