
// this function return required error'sand required select.
export const requiredMessage = (name) => {
    return `Please enter ${name}`
}
export const requiredSelect = (name) => {
    return `Please select ${name}`
}

// This function is used to automatically scroll the page to the location where an error occurs.
export const scrollIntoViewHelper = (errors) => {
    const firstError = Object.keys(errors)[0];
    let el = document.querySelector(`[name="${firstError}"]`);
    if (el) {
        el.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    }
};

// Function to remove HTML tags from a string
export const removeHtmlTags = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || "";
};

export function calculateWorkHours(duration) {
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();
    let result = '';

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    if (remainingMinutes > 0) {
      result += ` ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }
    return result;
  }
