import React from 'react'
import moment from 'moment'
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useOutletContext } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";

const AgentTimeline = () => {
    const { data, methods } = useOutletContext()
    const { activityData } = data
    const { fetchMoreData } = methods
    const TimeLine = () => {
        return (
            activityData && activityData?.length > 0 && activityData?.map((item, index) =>
                <TimelineItem key={index}>
                    <TimelineOppositeContent color="text.secondary">
                        {moment(item?.createdAt).calendar()}
                        <div>
                            By : {item?.createdby_id?.firstName ? `${item?.createdby_id?.firstName} ${item?.createdby_id?.lastName}` : "--"}
                        </div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{item?.title}
                        <div>
                            {Object?.keys(item?.data)?.map((key) => {
                                let newKey = key?.split('_')?.join(' ')?.toUpperCase();
                                let value = item?.data[key];
                                if (key === "appliedStartDate" || key === "appliedEndDate") {
                                    value = `${moment(value).format('DD-MM-YYYY')}`;
                                    if (key === "appliedStartDate") {
                                        newKey = "Applied Start Date";
                                    } else if (key === "appliedEndDate") {
                                        newKey = "Applied End Date";
                                    }
                                }
                                else if (key === "biometricId") {
                                    newKey = "Biometric Id";
                                } else if (key === "empId") {
                                    newKey = "Emp Id";
                                }
                                return (
                                    <div key={key}>
                                        <strong>{newKey} : </strong>{" "}
                                        <span className="text-break" dangerouslySetInnerHTML={{ __html: value }} />
                                    </div>
                                );
                            })}
                        </div>
                    </TimelineContent>
                </TimelineItem >
            ))
    }
    return (
        <InfiniteScroll
            dataLength={activityData?.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableDiv"
        >
            <div className="col-12 ticet">
                <div className="bg shadow raduus">
                    <div className="">
                        <div className="mt-2">
                            <div className='row p-5'>
                                {activityData?.length > 0 ?
                                    <Timeline position="alternate">
                                        {TimeLine()}
                                    </Timeline> :
                                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                                        <span>No record found</span>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </InfiniteScroll>
    )
}

export default AgentTimeline

