/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { getLeaveDetails, ApprovalStatus, getLeaveTimeline, cancelLeaves, editApprovalLevel, LeaveCountList, editLeave } from '../../../repository/leavePolicyRepo';
import useReduxStore from '../../../hooks/useReduxStore';
import { getAllRoleList } from '../../../repository/auth';
import { userFullList } from '../../../repository/teamManagementRepo';
import { uploadFile } from '../../../repository/attendanceRepo';
import moment from 'moment';
import { getShiftList } from '../../../repository/shiftRepo';
import { requiredMessage, requiredSelect } from '../../../utils';

const useLeaveDetails = () => {
  const { selector: user } = useReduxStore('auth')

  const { id } = useParams()
  const [spinner, setSpinner] = useState(false)
  const [count, setCount] = useState(10)
  const [leaveFormModal, setLeaveFormModal] = useState({
    open: false,
    data: null
  })
  const [leaveDetails, setLeaveDetails] = useState(null)
  const [leaveApprovalModal, setLeaveApprovalModal] = useState({
    open: false,
    data: null,
    leaveType: "PAID"
  })
  const [leaveCancelModal, setLeaveCancelModal] = useState({
    open: false,
    data: null,
  })
  const [leaveEditModal, setLeaveEditModal] = useState({ open: false, data: {}, leaveId: '' })
  const [timeLineListData, setTimeLineListData] = useState([])
  const [allRolesList, setAllRoleList] = useState([])
  const [agentList, setAgentList] = useState([])
  const [editLeaveInputs, setEditLeaveInputs] = useState({
    role: '',
    agent: ''
  })
  const [leaveUpdateModal, setLeaveUpdateModal] = useState({ open: false, data: {}, leaveId: '' })
  const [leaveForm, setLeaveForm] = useState({
    leaveType: "",
    leave: null,
    subLeaveType: null,
    appliedStartDate: null,
    appliedEndDate: null,
    reason: ""
  })
  const [leaveType, setLeaveType] = useState('');
  const [leaveError, setLeaveError] = useState({})
  const [leavePolicyCount, setLeavePolicyCount] = useState([])
  const fileInputRef = useRef(null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [shiftList, setShiftList] = useState([])

  // console.log(leaveForm, 'leaveForm')

  useEffect(() => {
    getDetails()
    getLeaveCount()
    shift()
    // getAllRolesList()
    // getAgentList()
  }, [])

  useEffect(() => {
    getTimeline()
  }, [count])

  // console.log(leaveEditModal, 'leaveEditModal');
  // console.log(editLeaveInputs, 'editLeaveInputs');
  const getAllRolesList = async (roleTitle) => {
    try {
      setSpinner(true)
      let response = await getAllRoleList()
      const findRole = response?.data?.find((item) => item?.roleTitle === roleTitle)
      setEditLeaveInputs((pre) => ({ ...pre, role: [findRole] }))

      setAllRoleList(response.data)
    } catch (error) {
      // toast.error(error.message)
    }
    setSpinner(false)
  }
  const getAgentList = async (agentId) => {

    try {
      setSpinner(true)
      let response = await userFullList({})
      setAgentList(response.data)
      const findAgent = response?.data?.find((item) => item?._id === agentId)

      setEditLeaveInputs((pre) => ({ ...pre, agent: [findAgent] }))
    } catch (error) {
      setAgentList([])
    }
    setSpinner(false)
  }
  const getDetails = async () => {
    try {
      setSpinner(true)
      let response = await getLeaveDetails(id)
      // let { reason, } = response.data
      setLeaveDetails(response.data)
      const { policy_id, appliedStartDate, appliedEndDate, reason, leave, policyDetails, subLeaveType, attachmentsFile } = response.data
      
      setLeaveForm((pre) => ({
        ...pre,
        leaveType: policy_id,
        appliedStartDate: new Date(appliedStartDate),
        appliedEndDate: new Date(appliedEndDate),
        reason: reason,
        leave: leave === 'Full Day' ? 'FULL' : 'HALF',
        subLeaveType: subLeaveType === 'First Half' ? "FIRSTHALF" : "SECONDHALF"

      }))
      setLeaveType(policyDetails?.leaveType)
      setSelectedFiles(attachmentsFile)

    } catch (error) {

    }
    setSpinner(false)
  }
  const getTimeline = async () => {
    const payLoad = {
      leaveId: id,
      page: 1,
      count: count
    }
    try {
      // setSpinner(true)
      let response = await getLeaveTimeline(payLoad)
      setTimeLineListData(response.data)
    } catch (error) {

    }
    // setSpinner(false)
  }
  const fetchMoreData = () => setCount((prev) => prev + 10);

  // Approval status 
  const UpdateStatus = async (status, actionType, id) => {
    if (actionType && status === "REJECTED" && !leaveApprovalModal?.reason) {
      return toast.warn("Please enter reason");
    }
    if (status === "REJECTED" && !leaveApprovalModal?.reason) {
      return toast.warn("Please enter reason");
    }

    setSpinner(true)
    let payload = {
      _id: id ? id : leaveApprovalModal?.data,
      status: status,
      type: leaveApprovalModal?.leaveType
    }
    if (actionType) {
      payload.all = true
    }
    if (leaveApprovalModal?.reason) {
      payload.denialReason = leaveApprovalModal?.reason
    }
    try {
      let response = await ApprovalStatus(payload)
      // navigate('/attendance/leaves-request')
      toast.success(response.message)
      setLeaveApprovalModal({ open: false, data: null, leaveType: "PAID" })
      getDetails()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const leaveCancel = async () => {
    if (!leaveCancelModal?.reason) {
      return toast.warn("Please enter reason");
    }
    setSpinner(true)
    let payload = {
      leaveId: id,
      denialReason: leaveCancelModal?.reason
    }
    // console.log(payload,'payload')
    // return
    try {
      let response = await cancelLeaves(payload)
      toast.success(response.message)
      setLeaveCancelModal(prev => ({ ...prev, open: false, reason: '' }))
      getDetails()
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }

  const editApproval = async () => {
    if (leaveEditModal?.data?.level_type === 'Role') {
      if (!editLeaveInputs?.role) {
        return toast.warn("Please select role");
      }
    }
    else {
      if (!editLeaveInputs?.agent) {
        return toast.warn("Please select agent");
      }
    }
    setSpinner(true)
    let payload = {
      // approval_id: leaveEditModal?.data?.approval_id?.[0],
      approval_id: editLeaveInputs?.agent?.[0]?._id ? editLeaveInputs?.agent?.[0]?._id : null,
      leaveId: leaveEditModal?.leaveId,
      level: leaveEditModal?.data?.level,
      type: leaveEditModal?.data?.level_type,
      roleId: editLeaveInputs?.role?.[0]?._id ? editLeaveInputs?.role?.[0]?._id : null,


    }
    // console.log(payload, 'payload')
    // return
    try {
      let response = await editApprovalLevel(payload)
      toast.success(response.message)
      setLeaveEditModal(prev => ({ ...prev, open: false, }))
      getDetails()
      setLeaveEditModal({ open: false, data: {}, leaveId: '' })
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  // ************************** Leave Update **********************************

  const handleInputChange = (event, dateRange = null) => {
    const { value, name, } = event.target;

    setLeaveForm((prev) => {
      const updatedShiftForm = { ...prev };
      updatedShiftForm[name] = value;

      // If the date range is provided, update the appliedDate field
      if (dateRange !== null) {
        updatedShiftForm.appliedStartDate = dateRange;
      }

      // Clear errors for the current field
      setLeaveError((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (name !== "sandwichAction") {
          updatedErrors[name] = null;
        }
        return updatedErrors;
      });

      return updatedShiftForm;
    });
  };
  const handleDateChange = (date, field) => {
    setLeaveForm((prev) => {
      const updatedShiftForm = { ...prev }
      updatedShiftForm[field] = date
      return updatedShiftForm
    })
    setLeaveError((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      updatedErrors[field] = null;
      return updatedErrors;
    });
  }
  const handleTimeLogFileChange = async (event) => {
    const files = event.target.files;
    const fd = new FormData();
    if (files.length > 5) {
      return toast.warn("You can only select up to 5 files.");
    }

    for (let i = 0; i < files.length; i++) {
      const fileData = files[i];
      if (fileData.size > 100 * 1024 * 1024) {
        return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
      }
      fd.append(`files`, fileData);
    }
    fd.append('type', "LEAVE");

    try {
      setSpinner(true);
      let response = await uploadFile(fd);
      toast.success(response.message);
      const newSelectedFiles = [...selectedFiles];
      response?.data.forEach((item) => {
        newSelectedFiles.push(item.db_name);
      });
      setSelectedFiles(newSelectedFiles);
      fileInputRef.current.value = null;

    } catch (error) {
      toast.error(error.message);
    }
    setSpinner(false);
  }
  const handleLogRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(updatedFiles)
  }
  const checkType = (id) => {
    const type = leavePolicyCount?.find((item) => item?._id === id)
    setLeaveType(type?.leaveType)
  }
  const getLeaveCount = async () => {
    try {
      setSpinner(true)
      let response = await LeaveCountList()
      setLeavePolicyCount(response?.data)
    } catch (error) {

    }
    setSpinner(false)
  }
  const shift = async () => {
    const payLoad = {
      isDeleted: false,
      page: 1,
      count: 100
    }
    try {
      let response = await getShiftList(payLoad)
      let filterShift = response?.data?.filter((item) => item?._id === user?.userInfo?.shift_id)
      // console.log(filterShift, 'filterShift')
      setShiftList(filterShift)

    } catch (error) {

    }
  }
  const errorMessage = {
    leaveType: requiredSelect('leave'),
    leave: requiredSelect('leave type'),
    subLeaveType: requiredSelect('sub leave type'),
    appliedStartDate: requiredMessage('start date'),
    appliedEndDate: requiredMessage('end date'),
    reason: requiredSelect('reason'),
  }
  const isValid = () => {
    const errorObj = {}
    if (!leaveForm.leaveType) {
      errorObj['leaveType'] = errorMessage['leaveType']
    }
    if (leaveType === 'NORMAL') {
      if (!leaveForm.leave) {
        errorObj['leave'] = errorMessage['leave']
      }
    }
    if (leaveForm.leave === 'HALF' || leaveType === 'SHORT') {
      if (!leaveForm.subLeaveType) {
        errorObj['subLeaveType'] = errorMessage['subLeaveType']
      }
    }
    if (!leaveForm.reason) {
      errorObj['reason'] = errorMessage['reason']
    }
    if (!leaveForm.appliedStartDate) {
      errorObj['appliedStartDate'] = errorMessage['appliedStartDate']
    }
    if (!leaveForm.appliedEndDate) {
      errorObj['appliedEndDate'] = errorMessage['appliedEndDate']
    }

    setLeaveError(errorObj)
    return !Object.keys(errorObj)?.length
  }
  const clear=()=>{
    setLeaveUpdateModal({ open: false, data: "" })
    setLeaveForm({
      leaveType: "",
      appliedStartDate: null,
      appliedEndDate: null,
      reason: "",
      leave: null,
      subLeaveType: null,
    })
    setLeaveType('')
  }
  // Edit Leave 
  const submit = async () => {
    if (!isValid()) return

    let shiftStartDateTime = moment.utc(shiftList?.[0]?.startTime).format("HH:mm")
    let shiftEndDateTime = moment.utc(shiftList?.[0]?.endTime).format("HH:mm")

    // const fullStartTime = moment.utc(moment(leaveForm.appliedStartDate).add(12, 'hours')).set({ hour: shiftStartDateTime.split(":")[0], minutes: shiftStartDateTime.split(":")[1] })
    // const fullEndTime = moment.utc(moment(leaveForm.appliedEndDate).add(12, 'hours')).set({ hour: shiftEndDateTime.split(":")[0], minutes: shiftEndDateTime.split(":")[1] })

    // let shiftStartDateTime = moment.utc(leaveForm.shiftData.startDateTime).format("HH:mm")
    // let shiftEndDateTime = moment.utc(leaveForm.shiftData.endDateTime).format("HH:mm")

    // const fullStartTime = moment(moment(leaveForm.appliedStartDate).format('YYYY-MM-DD') + ' ' + moment(leaveForm?.startDateFormat).format("HH:mm")).utc()
    // const fullEndTime = moment(moment(leaveForm.appliedEndDate).format('YYYY-MM-DD') + ' ' + moment(leaveForm?.endDateFormat).format("HH:mm")).utc()


    // console.log('leaveForm.appliedStartDate', leaveForm.appliedStartDate)


    const fullStartTime = moment.utc(leaveForm.appliedStartDate).set({ hour: shiftStartDateTime.split(":")[0], minutes: shiftStartDateTime.split(":")[1] })
    const fullEndTime = moment.utc(leaveForm.appliedEndDate).set({ hour: shiftEndDateTime.split(":")[0], minutes: shiftEndDateTime.split(":")[1] })
    let payload = {
      policy_id: leaveForm.leaveType,
      appliedStartDate: fullStartTime,
      appliedEndDate: fullEndTime,
      reason: leaveForm.reason,
      attachments: selectedFiles,
      subLeaveType: leaveForm?.subLeaveType,
      leave: leaveForm?.leave,
      leaveIds:id
    }
    // console.log('leave apply payload', payload)
    // return
    setSpinner(true)
    try {
      let response = await editLeave(payload)
      getDetails()
      getLeaveCount()
      clear()
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    }
    setSpinner(false)
  }
  return {
    data: {
      isLoading: spinner,
      leaveApprovalModal,
      leaveId: id,
      leaveFormModal,
      leaveDetails,
      levePermission: user,
      timeLineListData,
      leaveCancelModal,
      leaveEditModal,
      allRolesList,
      agentList,
      editLeaveInputs,
      leaveUpdateModal,
      leaveForm,
      leaveType,
      leavePolicyCount,
      fileInputRef,
      selectedFiles,
      leaveError
    },
    methods: {
      setLeaveApprovalModal,
      setLeaveFormModal,
      UpdateStatus,
      leaveCancel,
      setLeaveCancelModal,
      setCount,
      fetchMoreData,
      setLeaveEditModal,
      setEditLeaveInputs,
      editApproval,
      getAllRolesList,
      getAgentList,
      setLeaveUpdateModal,
      setLeaveForm,
      setLeaveType,
      handleInputChange,
      checkType,
      handleDateChange,
      handleTimeLogFileChange,
      handleLogRemoveFile,
      setLeaveError,
      submit,
      clear

    }
  }
}

export default useLeaveDetails