/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import useReduxStore from '../../../hooks/useReduxStore'
import { useDispatch } from 'react-redux'
import { addFilter } from '../../../Slices/FiltersSlice'
import { useToGetLeaveList, deleteLeave, ExportLeaveList, ApprovalStatus, LeaveCountList, addLeave } from '../../../repository/leavePolicyRepo'
import { userFullList } from '../../../repository/teamManagementRepo'
import { requiredMessage, requiredSelect } from '../../../utils'
import moment from 'moment'
import { uploadFile } from '../../../repository/attendanceRepo'
import { getShiftList } from '../../../repository/shiftRepo'

const useLeaveList = () => {
    const { selector: user } = useReduxStore('auth')
    const { selector: LeavePolicyFilter } = useReduxStore('filters')
    const dispatch = useDispatch()
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 })
    const [filterAction, setFilterAction] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [search, setSearchText] = useState(LeavePolicyFilter?.Leave?.filters?.search || '')
    const [agentList, setAgentList] = useState([])
    const [statusType, setStatusType] = useState({
        statusType: LeavePolicyFilter?.Leave?.filters?.statusType || '',
        emp: LeavePolicyFilter?.Leave?.filters?.emp || '', referenceNo: LeavePolicyFilter?.Leave?.filters?.referenceNo || ''
    })
    // console.log(statusType,'statusType')
    // console.log(LeavePolicyFilter, 'LeavePolicyFilter');
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        data: [],
    })
    const [approvalModal, setApprovalModal] = useState({
        open: false,
        data: "",
        status: ""
    });

    const [leaveApprovalModal, setLeaveApprovalModal] = useState({
        open: false,
        data: null,
        leaveType: "PAID"
    })
    const [leaveApplyModal, setLeaveApplyModal] = useState({
        open: false,
        data: ""
    })
    const [leaveForm, setLeaveForm] = useState({
        leaveType: "",
        leave: null,
        subLeaveType: null,
        appliedStartDate: null,
        appliedEndDate: null,
        reason: ""
    })
    const [leaveType, setLeaveType] = useState('');
    const [leavePolicyCount, setLeavePolicyCount] = useState([])
    const fileInputRef = useRef(null)
    const [selectedFiles, setSelectedFiles] = useState([])
    const [shiftList, setShiftList] = useState([])


    // console.log(leaveForm, 'leaveForm')
    // console.log(selectedFiles,'selectedFiles')
    const [leaveError, setLeaveError] = useState({})
    const cacheTime = { cacheTime: 10000 }

    // Listing payLoad
    let payload = {
        count: controller.rowsPerPage,
        page: controller.page,
        // search: search,
    }
    if (statusType?.statusType) payload.status = statusType?.statusType
    if (statusType?.emp) payload.userId = statusType?.emp?.[0]?._id
    if (statusType?.referenceNo) payload.search = statusType?.referenceNo
    // if (typeof statusType == 'boolean') payload.isDeleted = statusType
    // else {
    //     statusType == 'false' && (payload.isDeleted = false)
    //     statusType == 'true' && (payload.isDeleted = true)
    // }

    // Approval Modal Close
    const closeModalFun = () => {
        setLeaveApprovalModal({ data: "", leaveType: "PAID", open: false })
    }

    useEffect(() => {
        // Dispatch the addFilter action with the current 'Projects' page and the filter
        dispatch(addFilter({
            page: 'Leave', filter: {
                search: search,
                statusType: statusType?.statusType,
                emp: statusType?.emp,
                referenceNo: statusType?.referenceNo,
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, statusType?.statusType, statusType?.emp, statusType.referenceNo, controller]);
    // Fetch api
    const {
        data: leavePolicyList,
        error: leavePolicyError,
        isFetching: leavePolicyIsFetching,
        refetch,
    } = useToGetLeaveList(payload, {
        enabled: false,
        ...cacheTime,
    })

    useEffect(() => {
        refetch()
    }, [controller, filterAction])

    // Error Toast Message
    useEffect(() => {
        if (leavePolicyError) {
            // toast.error(leavePolicyError?.message);
        }

    }, [leavePolicyError])
    useEffect(() => {
        getAgentList()
    }, [])

    const shift = async () => {
        const payLoad = {
            isDeleted: false,
            page: 1,
            count: 100
        }
        try {
            let response = await getShiftList(payLoad)
            let filterShift = response?.data?.filter((item) => item?._id === user?.userInfo?.shift_id)
            // console.log(filterShift, 'filterShift')
            setShiftList(filterShift)

        } catch (error) {

        }
    }
    const getAgentList = async () => {
        try {
            // setSpinner(true)
            let response = await userFullList({})
            setAgentList(response.data)
        } catch (error) {
            setAgentList([])
        }
        setSpinner(false)
    }

    // Export Shift Data
    const Export = async () => {
        try {
            setSpinner(true)
            let response = await ExportLeaveList(payload)
            window.open(response.data.fileName);
            let tempLink = document.createElement('a');
            tempLink.setAttribute('download', response.data.fileName);
            tempLink.click();

        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    const resetFilter = () => {
        setFilterAction((prev) => !prev)
        setSearchText('')
        setStatusType({ statusType: '', emp: '', referenceNo: '' })
    }


    // useEffect(() => {
    //     if (approvalModal?.status == "APPROVED" || approvalModal?.status == "REJECTED") {
    //         handelApprovalStatus()
    //     }
    // }, [approvalModal.status])

    // // Handel Approval Status
    // const handelApprovalStatus = async () => {
    //     try {
    //         setSpinner(true);
    //         let response = await ApprovalStatus({
    //             _id: approvalModal?.data?._id,
    //             status: approvalModal?.status,
    //         });
    //         toast.success(response.message);
    //         setApprovalModal({ data: "", open: false, status: "" });
    //         refetch();
    //     } catch (error) {
    //         toast.error(error.message);
    //     }
    //     setSpinner(false);
    // };


    // Apply Search Filter
    const applayFilter = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            refetch()
        });
    }
    // Handle Search
    const handleSearchChange = (e) => {
        setSearchText(e.target.value)
        // Perform any other actions you need here
    }

    // Handle Pagination
    const handlePagination = (obj) => {
        setController(obj)
    }

    const handleDelete = async () => {
        try {
            setSpinner(true)
            let response = await deleteLeave({
                _id: deleteModal?.data._id,
                isDeleted: !deleteModal.data.isDeleted,
            })
            toast.success(response.message)
            setDeleteModal({ data: '', open: false })
            refetch()
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }


    // Approval status 
    const UpdateStatus = async (status, actionType) => {
        if (actionType && status === "REJECTED" && !leaveApprovalModal?.reason) {
            return toast.warn("Please enter reason");
        }
        if (status === "REJECTED" && !leaveApprovalModal?.reason) {
            return toast.warn("Please enter reason");
        }

        setSpinner(true)
        let payload = {
            _id: leaveApprovalModal?.data,
            status: status,
            type: leaveApprovalModal?.leaveType
        }
        if (actionType) {
            payload.all = true
        }
        if (leaveApprovalModal?.reason) {
            payload.denialReason = leaveApprovalModal?.reason
        }
        try {
            let response = await ApprovalStatus(payload)
            refetch()
            closeModalFun()
            setApprovalModal({ data: "", open: false, status: "" });
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }

    //   Leave Apply

    const handleInputChange = (event, dateRange = null) => {
        const { value, name, } = event.target;

        setLeaveForm((prev) => {
            const updatedShiftForm = { ...prev };
            updatedShiftForm[name] = value;

            // If the date range is provided, update the appliedDate field
            if (dateRange !== null) {
                updatedShiftForm.appliedStartDate = dateRange;
            }

            // Clear errors for the current field
            setLeaveError((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                if (name !== "sandwichAction") {
                    updatedErrors[name] = null;
                }
                return updatedErrors;
            });

            return updatedShiftForm;
        });
    };

    // Handle Date
    const handleDateChange = (date, field) => {
        setLeaveForm((prev) => {
            const updatedShiftForm = { ...prev }
            updatedShiftForm[field] = date
            return updatedShiftForm
        })
        setLeaveError((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            updatedErrors[field] = null;
            return updatedErrors;
        });
    }
    const checkType = (id) => {
        const type = leavePolicyCount?.find((item) => item?._id === id)
        setLeaveType(type?.leaveType)
    }
    const getLeaveCount = async () => {
        try {
            setSpinner(true)
            let response = await LeaveCountList()
            setLeavePolicyCount(response?.data)
        } catch (error) {

        }
        setSpinner(false)
    }

    const handleTimeLogFileChange = async (event) => {
        const files = event.target.files;
        const fd = new FormData();
        if (files.length > 5) {
            return toast.warn("You can only select up to 5 files.");
        }

        for (let i = 0; i < files.length; i++) {
            const fileData = files[i];
            if (fileData.size > 100 * 1024 * 1024) {
                return toast.warn(`File "${fileData.name}" exceeds the maximum size of 100 MB.`);
            }
            fd.append(`files`, fileData);
        }
        fd.append('type', "LEAVE");

        try {
            setSpinner(true);
            let response = await uploadFile(fd);
            toast.success(response.message);
            const newSelectedFiles = [...selectedFiles];
            response?.data.forEach((item) => {
                newSelectedFiles.push(item.db_name);
            });
            setSelectedFiles(newSelectedFiles);
            fileInputRef.current.value = null;

        } catch (error) {
            toast.error(error.message);
        }
        setSpinner(false);
    }
    const handleLogRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index)
        setSelectedFiles(updatedFiles)
    }
    const errorMessage = {
        leaveType: requiredSelect('leave'),
        leave: requiredSelect('leave type'),
        subLeaveType: requiredSelect('sub leave type'),
        appliedStartDate: requiredMessage('start date'),
        appliedEndDate: requiredMessage('end date'),
        reason: requiredSelect('reason'),
    }

    // Error hendling
    const isValid = () => {
        const errorObj = {}
        if (!leaveForm.leaveType) {
            errorObj['leaveType'] = errorMessage['leaveType']
        }
        if (leaveType === 'NORMAL') {
            if (!leaveForm.leave) {
                errorObj['leave'] = errorMessage['leave']
            }
        }
        if (leaveForm.leave === 'HALF' || leaveType === 'SHORT') {
            if (!leaveForm.subLeaveType) {
                errorObj['subLeaveType'] = errorMessage['subLeaveType']
            }
        }
        if (!leaveForm.reason) {
            errorObj['reason'] = errorMessage['reason']
        }
        if (!leaveForm.appliedStartDate) {
            errorObj['appliedStartDate'] = errorMessage['appliedStartDate']
        }
        if (!leaveForm.appliedEndDate) {
            errorObj['appliedEndDate'] = errorMessage['appliedEndDate']
        }

        setLeaveError(errorObj)
        return !Object.keys(errorObj)?.length
    }
    // add Leave 
    const submit = async () => {
        if (!isValid()) return

        let shiftStartDateTime = moment.utc(shiftList?.[0]?.startTime).format("HH:mm")
        let shiftEndDateTime = moment.utc(shiftList?.[0]?.endTime).format("HH:mm")

        // console.log("Setting up date", moment.utc(moment(leaveForm.appliedStartDate).add(12, 'hours')).set({ hour: shiftStartDateTime.split(":")[0], minutes: shiftStartDateTime.split(":")[1] }))

        const fullStartTime = moment.utc(moment(leaveForm.appliedStartDate).add(12, 'hours')).set({ hour: shiftStartDateTime.split(":")[0], minutes: shiftStartDateTime.split(":")[1] })
        const fullEndTime = moment.utc(moment(leaveForm.appliedEndDate).add(12, 'hours')).set({ hour: shiftEndDateTime.split(":")[0], minutes: shiftEndDateTime.split(":")[1] })

        let payload = {
            policy_id: leaveForm.leaveType,
            appliedStartDate: fullStartTime,
            appliedEndDate: fullEndTime,
            reason: leaveForm.reason,
            attachments: selectedFiles,
            subLeaveType: leaveForm?.subLeaveType,
            leave: leaveForm?.leave
        }
        // console.log('leave apply payload', payload)
        // return
        setSpinner(true)
        try {
            let response = await addLeave(payload)
            refetch()
            setLeaveApplyModal({ open: false, data: "" })
            setLeaveForm({
                leaveType: "",
                appliedStartDate: null,
                appliedEndDate: null,
                reason: "",
                leave: null,
                subLeaveType: null,
            })
            setLeaveType()
            // setSelectedFiles([])
            toast.success(response.message)
        } catch (error) {
            toast.error(error.message)
        }
        setSpinner(false)
    }
    return {
        data: {
            controller,
            isLoading: spinner || leavePolicyIsFetching,
            totalCount: leavePolicyError?.message === "No record found" ? 0 : leavePolicyList?.totalCount || 0,
            leavePolicyList: leavePolicyError?.message === "No record found" ? [] : leavePolicyList?.data || [],
            search,
            statusType,
            deleteModal,
            levePermission: user,
            approvalModal,
            leaveApprovalModal,
            agentList,
            leaveApplyModal,
            leaveForm, leaveError,
            leaveType,
            leavePolicyCount,
            fileInputRef,
            selectedFiles
        },
        methods: {
            handlePagination,
            resetFilter,
            applayFilter,
            handleSearchChange,
            setStatusType,
            setDeleteModal,
            handleDelete,
            Export,
            setApprovalModal,
            setLeaveApprovalModal,
            UpdateStatus,
            closeModalFun,
            setLeaveApplyModal,
            setLeaveForm,
            setLeaveType,
            handleInputChange,
            handleDateChange,
            checkType,
            setLeaveError,
            submit,
            handleTimeLogFileChange,
            handleLogRemoveFile,
            getLeaveCount,
            shift

        },
    }
}

export default useLeaveList