import React from 'react'
import { Pagination } from '../../components/pagination/Pagination'
import Table from '../../components/Table/Table'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
import Chip from '@mui/material/Chip'

const ProjectTicketsDetails = () => {
  const { data, methods } = useOutletContext()
  const { controller, totalCount, ticketList, } = data
  const { handlePagination } = methods

  const TableHead = [
    { label: 'Ticket ID', id: '' },
    // { label: "Name", id: "" },
    // { label: "Group", id: "" },
    { label: 'Agent', id: '' },
    { label: 'Status', id: '' },
    { label: 'Resolved', id: '' },
    { label: 'Resolve/Closed', id: '' },
  ]

  return (
    <div className="col-12 ticet">
      <div className="bg shadow raduus">
        <div className="">
          <div className="mt-2">
            <table className="table">
              <Table
                TableHeadData={TableHead}
                // numberOfSelected={numberOfSelected}
                rowCount={TableHead?.length}
              // onSelectAllClick={onSelectAllClick}
              />
              <tbody>
                {ticketList?.map((item, index) => (
                  <tr key={item._id + index} className="text-start rounded-row">
                    <td className="table-link">{item?.ticketId || "--"}</td>
                    <td><span className="d-flex flex-wrap">
                      {item?.agentData.length
                        ? item.agentData.map((team) => {
                          return (
                            <div key={team._id} className="cursor-pointer">
                              <Chip
                                className="m-1"
                                color="secondary"
                                size="small"
                                label={team.name.charAt(0).toUpperCase() + team.name.split(' ')[1].charAt(0).toUpperCase()}
                                title={team.name.toUpperCase()}
                              />
                            </div>
                          )
                        })
                        : '--'}
                    </span></td>
                    {/* <td>{item?.createdby}</td> */}
                    {/* <td>{item?.team}</td> */}
                    {/* <td>{item?.priority}</td> */}
                    <td>{item?.status}</td>
                    <td>{item?.closedOn ? moment(item?.closedOn).calendar() : '--'}</td>
                    <td>{new Date(item?.dueDate) > new Date(item?.closedOn) ? 'Ontime' : 'Late'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!ticketList?.length && <div className="text-center">No record found</div>}
            <Pagination count={totalCount} controller={controller} setController={handlePagination} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectTicketsDetails
