// Role and Permission Inital value
// export const initialPermissions = {
//     solution: {
//         view: {
//             title: "View solutions",
//             value: true
//         },
//         approve: {
//             title: "Approve the solution",
//             value: true
//         },
//         delete: {
//             title: "Delete the solution",
//             value: true
//         }
//     },
//     customer: {
//         createOrEditContact: {
//             title: "Create or edit contact",
//             value: true
//         },
//         createOrEditCompany: {
//             title: "Create or edit company",
//             value: true
//         },
//         deleteContact: {
//             title: "Delete contact",
//             value: true
//         },
//         deleteCompany: {
//             title: "Delete contact",
//             value: true
//         },
//         exportContacts: {
//             title: "Export contacts",
//             value: true
//         }
//     },
//     analytics: {
//         view: {
//             title: "View analytics",
//             value: true
//         },
//         edit: {
//             title: "Edit analytics",
//             value: true
//         },
//         editAndExport: {
//             title: "Edit and export",
//             value: true
//         }
//     },
//     admin: {
//         manageAgents: {
//             title: "Manage agents",
//             value: true
//         },
//         provideTicketAccess: {
//             title: "Provide ticket access",
//             value: true
//         },
//         manageAllAgents: {
//             title: "Manage all agents",
//             value: true
//         },
//         assumeIdentity: {
//             title: "Assume identity",
//             value: true
//         },
//         manageAutomatic: {
//             title: "Manage automatic",
//             value: true
//         }
//     },
//     ticket: {
//         create: {
//             title: "Create a ticket",
//             value: true
//         },
//         respond: {
//             title: "Respond to a ticket",
//             value: true
//         },
//         reply: {
//             title: "Send a ticket reply",
//             value: true
//         },
//         addNotes: {
//             title: "Add notes",
//             value: true
//         },
//         forwardChats: {
//             title: "Forward chats",
//             value: true
//         },
//         editNotes: {
//             title: "Edit notes",
//             value: true
//         },
//         editEveryoneNotes: {
//             title: "Edit everyone's notes",
//             value: true
//         },
//         editOwnNotes: {
//             title: "Edit their own notes",
//             value: true
//         },
//         deleteAConversation: {
//             title: "Delete a conversation",
//             value: true
//         },
//         editTicketStatus: {
//             title: "Edit ticket status",
//             value: true
//         },
//         editInternalGroup: {
//             title: "Edit internal group",
//             value: true
//         },
//         editInternalAgent: {
//             title: "Edit internal agent",
//             value: true
//         },
//         editSkill: {
//             title: "Edit skill",
//             value: true
//         },
//         editEveryoneTime: {
//             title: "Edit everyone time entries",
//             value: true
//         },
//         editOwnTime: {
//             title: "Edit only their time",
//             value: true
//         },
//         delete: {
//             title: "Delete a ticket",
//             value: true
//         },
//         spamTicket: {
//             title: "Spam ticket",
//             value: true
//         },
//         export: {
//             title: "Exports ticket",
//             value: true
//         }
//     },
//     ticketAssignment: {
//         manageSkills: {
//             title: "Manage skills",
//             value: true
//         },
//         manageTags: {
//             title: "Manage tags",
//             value: true
//         },
//         manageEmailSettings: {
//             title: "Manage email settings",
//             value: true
//         },
//         manageTicketTemplates: {
//             title: "Manage ticket templates",
//             value: true
//         },
//         manageCustomObjects: {
//             title: "Manage custom objects",
//             value: true
//         },
//         managePortalsettings: {
//             title: "Manage portal settings",
//             value: true
//         },
//         managePortal: {
//             title: "Manage portal",
//             value: true
//         }
//     },
//     customization: {
//         manageAgentShifts: {
//             title: "Manage agent shifts",
//             value: true
//         }
//     }
// }

// Role And Permissions initial obj

export const CKEditorCustomToolbar = [
  "bold",
  "italic",
  "link",
  "bulletedList",
  "numberedList",
  "blockQuote",
  "heading",
  "indent",
  "outdent",
  "table"
];

export const initialPermissions = {
  ticket: {
    add: { title: "Add ticket", value: false },
    edit: { title: "Edit ticket", value: false },
    delete: { title: "Delete ticket", value: false },
    reply: { title: "Add reply", value: false },
    merge: { title: "Merge ticket", value: false },
    assign: { title: "Assign ticket", value: false },
    viewSelf: { title: "View own tickets", value: false },
    viewAll: { title: "View all tickets", value: false },
    export: { title: "Export ticket", value: false },
  },
  task: {
    add: { title: "Add task", value: false },
    import: { title: "Import task", value: false },
    // cancel: { title: "Cancel task", value: false },
    approval: { title: "Approve task", value: false },
    viewSelf: { title: "View own tasks", value: false },
    viewAll: { title: "View all tasks", value: false },
    viewTeam: { title: "View team's task", value: false },
    export: { title: "Export task", value: false },
    idleUser: { title: "View idle user", value: false },
  },
  timelog: {
    approvalSelf: { title: "Approve own timelog", value: false },
    approvalTeam: { title: "Approve team timelog", value: false },
    approvalAll: { title: "Approve all timelog", value: false },
    viewSelf: { title: "View own timelog", value: false },
    viewAll: { title: "View all timelog", value: false },
    viewTeam: { title: "View team's timelog", value: false },
    export: { title: "Export timelog", value: false },
  },
  project: {
    add: { title: "Add project", value: false },
    edit: { title: "Edit project", value: false },
    delete: { title: "Delete project", value: false },
    export: { title: "Export project", value: false },
  },
  attendance: {
    import: { title: "Import attendance", value: false },
    editAttendance: { title: "Edit attendance", value: false },
    deleteAttendance: { title: "Delete attendance", value: false },
    viewSelf: { title: "View own attendance", value: false },
    viewAll: { title: "View all attendance", value: false },
    export: { title: "Export attendance", value: false },
  },
  team: {
    add: { title: "Add team", value: false },
    edit: { title: "Edit team", value: false },
    delete: { title: "Delete team", value: false },
    viewSelf: { title: "View own team", value: false },
    viewAll: { title: "View all team", value: false },
    export: { title: "Export Team", value: false },
  },
  leave: {
    approval: { title: "Approve leave", value: false, },
    viewSelf: { title: "View own leave", value: false, },
    viewTeam: { title: "View team's leave", value: false, },
    viewAll: { title: "View all leave", value: false, },
    export: { title: "Export leave", value: false, },
    addIndividual: { title: "Add individual leave", value: false, },
  },
  rolesAndPermission: {
    add: { title: "Add role", value: false },
    edit: { title: "Edit role", value: false },
    view: { title: "View roles", value: false }
  },
  setting: {
    group: { title: "Access group", value: false },
    workflow: { title: "Access workflow", value: false },
    shift: { title: "View shifts", value: false },
    leavePolicy: { title: "View leave policies", value: false },
    report: { title: "View reports", value: false },
    announcement: { title: "View announcement", value: false },
  },
  shift: {
    add: { title: "Add shift", value: false },
    edit: { title: "Edit shift", value: false },
    delete: { title: "Delete shift", value: false }
  },
  breaks: {
    edit: { title: "Edit breaks", value: false },
  },

};

export const ApiStatus = {
  success: 200,
  serverError: "Server Error"
};

export const channelType = [
  {
    value: "CHAT",
    label: "Chat"
  },
  {
    value: "EMAIL",
    label: "Email"
  },
  {
    value: "PHONE",
    label: "Phone"
  },
  {
    value: "ONLINE",
    label: "Online"
  }
];

export const AllTicketEnum = [
  { title: "All tickets", value: "ALL_TICKETS" },
  { title: "My Overdue Tickets", value: "MY_OVERDUE" },
  { title: "Open Tickets in My Groups", value: "MY_GROUP_TICKETS" },
  { title: "Urgent and High priority Tickets", value: "URGENT_TICKETS" },
  // { title: "All undelivered messages" ,value:"" },
  { title: "Due today", value: "DUE_TODAY" },
  { title: "All unresolved tickets", value: "ALL_UNRESOLVED_TICKETS" },
  { title: "New and my open tickets", value: "NEW_AND_MY_OPEN_TICKETS" },
  { title: "Tickets I raised", value: "MY_RAISED_TICKETS" },
  { title: "Tickets I'm mentioned in", value: "MENTIONED_IN_TICKETS" },
  { title: "Tickets I'm watching", value: "WATCHING_TICKETS" },
  { title: "Spam", value: "SPAM" },
  { title: "Trash", value: "TRASH" }
];

export const priorityList = [
  { title: "Low", value: "LOW" },
  { title: "Medium", value: "MEDIUM" },
  { title: "High", value: "HIGH" }
];

export const taskTypeList = [
  { title: "Already Assigned", value: "Already Assigned" },
  { title: "New Assigned", value: "New Assigned" },
  { title: "Moved Forward", value: "Moved Forward" },
  { title: "On Hold", value: "On Hold" },
];

export const SubTaskList = [
  { title: "Web", value: "Web" },
  { title: "App", value: "App" },
  { title: "Support", value: "Support" },
  { title: "Web & App", value: "Web & App" },
  { title: "Miscellaneous", value: "Miscellaneous" },
]

export const TaskCategoryList = [
  { title: "Backend", value: "Backend" },
  { title: "Frontend", value: "Frontend" },
  { title: "Testing", value: "Testing" },
  { title: "Designing", value: "Designing" },
  { title: "Documentation", value: "Documentation" },
  { title: "R&D", value: "R&D" },
  { title: "App Coding", value: "App Coding" },
  { title: "App Designing", value: "App Designing" },
  { title: "Management", value: "Management" },
  { title: "Client Meeting", value: "Client Meeting" },
  { title: "Internal Meeting", value: "Internal Meeting" },
  { title: "Server Deployment", value: "Server Deployment" },
  { title: "Client Training", value: "Client Training" },
  { title: "Internal Training", value: "Internal Training" },
  { title: "HR Task", value: "HR Task" },
  { title: "Miscellaneous", value: "Miscellaneous" },
  { title: "SEO", value: "SEO" },
  { title: "SMO", value: "SMO" },
  { title: "Content Writing", value: "Content Writing" },
]
export const complexityList = [
  { title: "Low", value: "Low" },
  { title: "Medium", value: "Medium" },
  { title: "High", value: "High" },
]

export const hoursTypeList = [
  { title: "Productive", value: "Productive" },
  { title: "Unproductive", value: "Unproductive" },
]


export const workTypeList = [
  { title: "New", value: "New" },
  { title: "Backlog", value: "Backlog" },
  { title: "Planned", value: "Planned" },
  // { title: "Unplanned New Task after Scrum", value: "Unplanned New Task after Scrum" },
  { title: "Delegate", value: "Delegate" },
  { title: "Change Request", value: "Change Request" },
  { title: "Rework", value: "Rework" },
];

export const actutalStatusList = [
  { title: "Done", value: "Done" },
  { title: "InProgress", value: "InProgress" },
  { title: "On Hold", value: "On Hold" },
  { title: "Delegate", value: "Delegate" },
  { title: "Not Started", value: "Not Started" },
]

export const ReminderList = [
  { title: 0, value: 0 },
  { title: 10, value: 10 },
  { title: 15, value: 15 },
  { title: 30, value: 30 },
  { title: 45, value: 45 },
  { title: 60, value: 60 }
];

export const YearList = [];
for (let year = 2000; year <= 2099; year++) {
  YearList.push({ title: year, value: year });
}

export const statusList = [
  { color: "#97FFF4", title: "Open", value: "OPEN" },
  { color: "#97FFF4", title: "Re-Open", value: "REOPEN" },
  { color: "#B71375", title: "Pending", value: "PENDING" },
  // { color: '#7091F5', title: 'Resolved', value: 'RESOLVED' },
  { color: "#793FDF", title: "Closed", value: "CLOSED" },
  {
    color: "#8D9EFF",
    title: "Waiting for Client",
    value: "WAITING FOR CLIENT"
  },
  {
    color: "#898121",
    title: "Waiting for Third Party",
    value: "WAITING FOR THIRD PARTY"
  },
  { color: "#EC53B0", title: "Spam", value: "SPAM" },
  { title: 'In Progress', value: 'IN PROGRESS' },
  { title: 'No Issue', value: 'NO ISSUE' },
];

export const LeaveTypeList = [
  { title: "Normal", value: "NORMAL" },
  { title: "Short Leave", value: "SHORT" },
]
export const LeaveType = [
  { title: "Full Day", value: "FULL" },
  { title: "Half Day", value: "HALF" },
]
export const SubLeaveType = [
  { title: "First Half", value: "FIRSTHALF" },
  { title: "Second Half", value: "SECONDHALF" },
]

export const TypeList = [
  { title: "Paid", value: "PAID" },
  { title: "UnPaid", value: "UNPAID" },
]

export const EntitledLeavesList = [];
for (let entitled = 1; entitled <= 300; entitled++) {
  EntitledLeavesList.push({ title: entitled, value: entitled });
}

export const ShortLeaveDurationList = [
  { title: '0 (Minute)', value: 0 },
  { title: '30 (Minutes)', value: 30 },
  { title: '60 (Minutes)', value: 60 },
  { title: '90 (Minutes)', value: 90 },
  { title: '120 (Minutes)', value: 120 },
  { title: '150 (Minutes)', value: 150 },
  { title: '180 (Minutes)', value: 180 },
  { title: '210 (Minutes)', value: 210 },
  { title: '240 (Minutes)', value: 240 },
  { title: '270 (Minutes)', value: 270 },
  { title: '300 (Minutes)', value: 300 },
  { title: '330 (Minutes)', value: 330 },
  { title: '360 (Minutes)', value: 360 },
  { title: '400 (Minutes)', value: 400 },
];

export const ApprovalLevels = [
  { title: 'Role', value: 'Role' },
  { title: 'Reporting Manager', value: 'Reporting Manager' },
  { title: 'Team Lead', value: 'Team Lead' },
];
export const attendanceStatus = [
  { title: 'P', value: 'P' },
  { title: 'A', value: 'A' },
  // { title: 'Mis', value: 'MIS' },
];
export const HolidayType = {
  OffDays: "OffDays",
  RestDays: "RestDays",
  Compensatoryoff: "Compensatoryoff",
  PublicHolidays: "PublicHolidays",
}

export const SandwichList = [
  { title: "Holidays", value: "HOLIDAYS" },
  { title: "WeekDays", value: "WEEKDAYS" },
]
export const RelationList = [
  { title: "Father", value: "FATHER" },
  { title: "Mother", value: "MOTHER" },
  { title: "Child", value: "CHILD" },
  { title: "Spouse", value: "SPOUSE" },
  { title: "Siblings", value: "SIBLINGS" },
  { title: "Cousins", value: "COUSINS" },
]
export const EmployeeType = [
  { title: "Permanent", value: "PERMANENT" },
  { title: "Contractual", value: "CONTRACTUAL" },
  { title: "Trainee", value: "TRAINEE" },
  { title: "Intern", value: "INTERN" },
]

export const timeZonelist = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    timezone: "Pacific/Wallis"
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    timezone: "Pacific/Pago_Pago"
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    timezone: "US/Hawaii"
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -9,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    timezone: "US/Alaska"
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -8,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    timezone: "America/Pacific"
  },
  {
    value: "Pacific Standard Time",
    abbr: "PDT",
    offset: -8,
    isdst: true,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    timezone: "America/Pacific"
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    timezone: "America/Mountain"
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    timezone: "America/Chihuahua"
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    timezone: "US/Mountain"
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    timezone: "America/Managua"
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -6,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    timezone: "US/Central"
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -6,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    timezone: "America/Mexico_City"
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    timezone: "Canada/Saskatchewan"
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    timezone: "America/Lima"
  },
  {
    value: "Eastern Standard Time",
    abbr: "EDT",
    offset: -5,
    isdst: true,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    timezone: "US/Eastern"
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -5,
    isdst: true,
    text: "(UTC-05:00) Indiana (East)",
    timezone: "US/East-Indiana"
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    timezone: "America/Caracas"
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    timezone: "America/Santiago"
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -4,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    timezone: "Canada/Atlantic"
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    timezone: "America/Sao_Paulo"
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    timezone: "America/La_Paz"
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    timezone: "America/Santiago"
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -3.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    timezone: "Canada/Newfoundland"
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    timezone: "America/Sao_Paulo"
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    timezone: "America/Argentina/Buenos_Aires"
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    timezone: "America/Cayenne"
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    timezone: "America/Godthab"
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    timezone: "America/Montevideo"
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    timezone: "America/Montevideo"
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    timezone: "America/Godthab"
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -2,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    timezone: "America/Noronha"
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: -1,
    isdst: true,
    text: "(UTC-01:00) Azores",
    timezone: "Atlantic/Azores"
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    timezone: "Atlantic/Cape_Verde"
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 0,
    isdst: true,
    text: "(UTC) Casablanca",
    timezone: "Africa/Casablanca"
  },
  {
    value: "UTC",
    abbr: "CUT",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    timezone: "Etc/Greenwich"
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 0,
    isdst: true,
    text: "(UTC) Dublin, Edinburgh, Lisbon, London",
    timezone: "Europe/London"
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    timezone: "Africa/Monrovia"
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    timezone: "Europe/Amsterdam"
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    timezone: "Europe/Budapest"
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    timezone: "Europe/Paris"
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    timezone: "Europe/Warsaw"
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    timezone: "Africa/Lagos"
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    timezone: "Africa/Windhoek"
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    timezone: "Europe/Athens"
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    timezone: "Asia/Beirut"
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    timezone: "Africa/Cairo"
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    timezone: "Asia/Damascus"
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) E. Europe",
    timezone: "Europe/London"
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    timezone: "Africa/Harare"
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    timezone: "Europe/Helsinki"
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Istanbul",
    timezone: "Europe/Istanbul"
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 2,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    timezone: "Asia/Jerusalem"
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    timezone: "Africa/Tripoli"
  },
  {
    value: " Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    timezone: "Asia/Amman"
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    timezone: "Asia/Baghdad"
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kaliningrad, Minsk",
    timezone: "Europe/Minsk"
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    timezone: "Asia/Kuwait"
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    timezone: "Africa/Nairobi"
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 3.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    timezone: "Asia/Tehran"
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    timezone: "Asia/Muscat"
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 4,
    isdst: true,
    text: "(UTC+04:00) Baku",
    timezone: "Asia/Baku"
  },
  {
    value: "Russian Standard Time",
    abbr: "RST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    timezone: "Europe/Moscow"
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    timezone: "Indian/Mauritius"
  },
  {
    value: "Georgian Standard Time",
    abbr: "GST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    timezone: "Asia/Tbilisi"
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    timezone: "Asia/Yerevan"
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    timezone: "Asia/Kabul"
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    timezone: "Asia/Tashkent"
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    timezone: "Asia/Karachi"
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    timezone: "Asia/Kolkata"
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    timezone: "Asia/Calcutta"
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    timezone: "Asia/Katmandu"
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Astana",
    timezone: "Asia/Dhaka"
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    timezone: "Asia/Dhaka"
  },
  {
    value: "Ekaterinburg Standard Time",
    abbr: "EST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Ekaterinburg",
    timezone: "Asia/Yekaterinburg"
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    timezone: "Asia/Rangoon"
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    timezone: "Asia/Bangkok"
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    timezone: "Asia/Novosibirsk"
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    timezone: "Asia/Hong_Kong"
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    timezone: "Asia/Krasnoyarsk"
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    timezone: "Asia/Kuala_Lumpur"
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    timezone: "Australia/Perth"
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    timezone: "Asia/Taipei"
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    timezone: "Asia/Ulaanbaatar"
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Irkutsk",
    timezone: "Asia/Irkutsk"
  },
  {
    value: "Tokyo Standard Time",
    abbr: "TST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    timezone: "Asia/Tokyo"
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    timezone: "Asia/Seoul"
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    timezone: "Australia/Adelaide"
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    timezone: "Australia/Darwin"
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    timezone: "Australia/Brisbane"
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    timezone: "Australia/Melbourne"
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    timezone: "Pacific/Port_Moresby"
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    timezone: "Australia/Hobart"
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Yakutsk",
    timezone: "Asia/Yakutsk"
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    timezone: "Pacific/Guadalcanal"
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    timezone: "Asia/Vladivostok"
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    timezone: "Pacific/Auckland"
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    timezone: "Pacific/Kwajalein"
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    timezone: "Pacific/Fiji"
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    timezone: "Asia/Magadan"
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    timezone: "Asia/Kamchatka"
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    timezone: "Pacific/Tongatapu"
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    timezone: "Pacific/Samoa"
  }
];
