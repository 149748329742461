import React from "react";
import moment from 'moment'
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useOutletContext } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const TaskTimeLine = () => {
    const { data,methods } = useOutletContext();
    const { timeLineListData } = data;
    const {fetchMoreData}=methods
    const TimeLine = () => {
        return (
            timeLineListData && timeLineListData?.length > 0 && timeLineListData?.map((item, index) =>
                <TimelineItem key={index}>
                    <TimelineOppositeContent color="text.secondary">
                        {moment(item?.createdAt).calendar()}
                        <div>
                            By : {item?.createdby_id?.firstName ? `${item?.createdby_id?.firstName} ${item?.createdby_id?.lastName}` : "--"}
                        </div>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{item?.title}
                        <div>
                            {Object?.keys(item?.data)?.map((key) => {
                                let newKey = key?.split('_')?.join(' ')?.toUpperCase()
                                // if (item?.data?.hasOwnProperty('breaks')) {
                                //     return (
                                //         <div>
                                //             {item?.data[key]?.map((dataObject, index) => (
                                //                 !dataObject?.isDeleted && < div >
                                //                     <b>Break.no : {index + 1}</b><br />
                                //                     <b>Start Time :</b> {moment(dataObject?.startTime)?.calendar()}<br />
                                //                     <b>End Time :</b> {moment(dataObject?.endTime)?.calendar()}<br />
                                //                     <b>Reason:</b><span dangerouslySetInnerHTML={{ __html: dataObject?.reason }} />
                                //                 </div>
                                //             ))}
                                //         </div>
                                //     );
                                // }
                                // else 
                                return <div key={key}>
                                    <strong>{newKey} : </strong>{" "}<span className="text-break" dangerouslySetInnerHTML={{ __html: item?.data[key] }} />
                                </div>
                            })}
                        </div>
                    </TimelineContent>
                </TimelineItem >
            ))
    }
    return (
        <InfiniteScroll
            dataLength={timeLineListData?.length}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableDiv"
        >
        <div>
            {timeLineListData?.length > 0 ? <Timeline position="alternate">
                {TimeLine()}
            </Timeline> :
                <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                    <span>No record found</span>
                </div>
            }
        </div>
        </InfiniteScroll>
    );
};

export default TaskTimeLine;
