import moment from 'moment';
import React from 'react'
import { useOutletContext } from "react-router-dom";

const BreakTime = () => {
    const { data, methods } = useOutletContext();
    const { taskDetails, TaskPermission } = data;
    const { setBreakModal, setBreakHoursInput, setDeleteBreakHoursModal } = methods
    return (
        <div>
            {taskDetails?.breaks?.length > 0 && (
                <div className="row border mx-1 mt-4" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <div className="col-3 p-3"> <b>Start Time</b> </div>
                    <div className="col-3 p-3"><b>End Time</b></div>
                    <div className="col-5 p-3"><b>Reason </b></div>
                    <div className="col-1 p-3 "><b>Action</b></div>
                    <hr />
                    <div className="col-12 mt-3">
                        {taskDetails?.breaks?.map((item, index) => (
                            !item?.isDeleted && <div className="row" key={index}>
                                <div className="col-3 p-2"><span>{item?.startTime ? moment(item?.startTime).format("lll") : "--"}</span></div>
                                <div className="col-3 p-2"><span>{item?.endTime ? moment(item?.endTime).format("lll") : "--"}</span></div>
                                <div className="col-5 p-2" style={{ wordWrap: "break-word" }}>
                                    <span className="mt-2" dangerouslySetInnerHTML={{ __html: item?.reason || "--" }}></span>
                                </div>
                                <div className="col-1 p-2"><span>{
                                    (taskDetails?.assignTo_id?._id === TaskPermission?.userInfo?._id) && !taskDetails.isTimeLogged ?
                                        <div className="dropdown p-0 align-items-center justify-content-center">
                                            <i className="fas fa-ellipsis-v pointer p-2" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                {
                                                    <li style={{ cursor: 'pointer' }} className="dropdown-item"
                                                        onClick={() => {
                                                            setBreakHoursInput([{
                                                                startTime: new Date(item?.startTime),
                                                                endTime: new Date(item?.endTime),
                                                                reason: item?.reason,
                                                                _id: item?._id
                                                            }])
                                                            setBreakModal({ data: item?._id, open: true, })
                                                        }}
                                                    >  Edit</li>}
                                                <li className="dropdown-item" onClick={() => setDeleteBreakHoursModal({ data: item?._id, open: true, })}>Delete</li>
                                            </ul>
                                        </div>
                                        :
                                        (<div className='pt-3' style={{ minHeight: '50px' }}>
                                            <i className="fa-solid fa-ellipsis-vertical px-3" style={{ cursor: "not-allowed" }} />
                                        </div>)
                                }</span>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default BreakTime