import React, { createRef, useState } from 'react'
import { useNavigate, Outlet, useLocation, NavLink, useParams } from 'react-router-dom'
import { Button, TextField, InputLabel, Chip, Tabs, Tab, AppBar } from '@mui/material'
import TextInput from '../../components/TextField'
import Modal from '../../components/Modal'
import useTicketAddUpdate from './Hooks/useTicketAddUpdate'
import SelectInput from '../../components/SelectInput'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import TimePicker from 'rc-time-picker'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DesktopDateTimePicker from '../../components/DesktopDateTimePicker'
import FileImg from '../../assets/icons/file.png'
import VideoIcon from '../../assets/icons/video.png'
import PDFIcon from '../../assets/icons/pdf.png'
import ExcelIcon from '../../assets/icons/excel.png'
import TextFileIcon from '../../assets/icons/textfile.png'
import Switch from '@mui/material/Switch'
import Fab from '@mui/material/Fab'
import { CKEditorCustomToolbar, ReminderList } from '../../constants'
import { uploadFileUrlTicket } from '../../repository/ticketRepo'
import { uploadFileUrlTask } from '../../repository/taskRepo'
import MultipleSelect from "../../components/MultipleSelect"

export const fileType = (file, fileIndex, urlType) => {

  let type = file?.split('.').pop()

  if (['png', 'jpeg', 'jpg'].includes(type)) {
    return (
      <>
        <img key={fileIndex} src={(urlType === "TASK") ? uploadFileUrlTask + file : uploadFileUrlTicket + file} alt="" width="100%" height="100%" />
      </>
    )
  } else if (['mp4'].includes(type)) {
    return (
      <>
        <img key={fileIndex} src={VideoIcon} alt="" width="100%" height="100%" />
      </>
    )
  } else if (['pdf'].includes(type)) {
    return (
      <>
        <img key={fileIndex} src={PDFIcon} alt="" width="100%" height="100%" />
      </>
    )
  } else if (['xlsx', 'xls'].includes(type)) {
    return (
      <>
        <img key={fileIndex} src={ExcelIcon} alt="" width="100%" height="100%" />
      </>
    )
  } else if (['txt', 'doc'].includes(type)) {
    return (
      <>
        <img key={fileIndex} src={TextFileIcon} alt="" width="100%" height="100%" />
      </>
    )
  } else {
    return (
      <>
        <img key={fileIndex} src={FileImg} alt="" width="100%" height="100%" />
      </>
    )
  }
}

const Email = () => {
  // const fileInputRef = useRef(null)
  const location = useLocation()
  const { id } = useParams()

  const Navigate = useNavigate()
  const { data, methods } = useTicketAddUpdate()

  const [showPDFModal, setShowPDFModal] = useState({ open: false, data: '' })

  const {
    spinner,
    error,
    ticketsInputs,
    allTagsList,
    fullTicketList,
    priorityList,
    soucrsList,
    replyModel,
    noteModel,
    deleteModal,
    mergeModel,
    searchTickets,
    slotId,
    assignModel,
    assignInputs,
    unAssignModel,
    projectListData,
    tagListData,
    assignToListData,
    assignErrors,
    taskTypeList,
    SubTaskList,
    TaskCategoryList,
    complexityList,
    hoursTypeList,
    workTypeList,
    selectedFiles
  } = data
  const {
    setTicketsInputs,
    handleInputChange,
    handleBlur,
    handleupdateSubmit,
    handleFileChange,
    handleRemoveFile,
    setSearchTickets,
    handleCheckSlots,
    addReply,
    setReplyModel,
    setNoteModel,
    setDeleteModal,
    AddNote,
    handleDelete,
    TicketMerge,
    setMergeModel,
    setAssignModel,
    AssignMultipleTask,
    removeAssign,
    handleDateChange,
    handleAssignInputChange,
    MultipleAgentsAssignTicket,
    CloseFucntion,
    UnassignAgent,
    setUnAssignModel,
    setAssignErrors,
    handleTicketFileChange,
    handleTicketRemoveFile,
    setCount
  } = methods

  const fileInputRef = assignInputs.map(() => createRef());

  const statusList = [
    { title: 'Open', value: 'OPEN' },
    { title: 'Pending', value: 'PENDING' },
    { title: 'Resolved', value: 'RESOLVED' },
    { title: 'Closed', value: 'CLOSED' },
    { title: 'Waiting for Client', value: 'WAITING FOR CLIENT' },
    { title: 'Waiting for Third Party', value: 'WAITING FOR THIRD PARTY' },
    { title: 'Spam', value: 'SPAM' },
    { title: 'In Progress', value: 'IN PROGRESS' },
    { title: 'No Issue', value: 'NO ISSUE' },
  ]

  const allTabs = [
    {
      label: 'Reply',
      value: `/tickets/email/${id}`,
    },
    {
      label: 'Note',
      value: `/tickets/email/${id}/note`,
    },
    {
      label: 'Time Line',
      value: `/tickets/email/${id}/time-line`,
    },
  ]

  const filePreview = (file) => {
    let type = file?.split('.').pop()
    if (['png', 'jpeg', 'jpg'].includes(type)) {
      setShowPDFModal({ open: true, data: file })
    } else window.open(`${uploadFileUrlTicket}${file}`)
  }
  const reportedDate = (date) => {
    if (!date) return "--";
  
    return moment(date).calendar(null, {
      sameDay: '[Today at] hh:mm A',       
      nextDay: '[Tomorrow at] hh:mm A', 
      nextWeek: 'dddd [at] hh:mm A',      
      lastDay: '[Yesterday at] hh:mm A',   
      lastWeek: '[Last] dddd [at] hh:mm A',
      sameElse: 'DD/MM/YYYY hh:mm A'
    });
  }
  return (
    <>
      {spinner && <Spinner />}
      <ToastContainer />
      <div className="row mx-auto">
        <div className="col-9">
          <div className="container-fluid contact">
            <div className="row bg">
              <div className="col-lg-12">
                <h6 className="m-0 mt-4">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-end">
                      <span style={{ fontSize: '16px' }} className="table-link"
                      // onClick={() => Navigate(`/team-management/${ticketsInputs?.ticketFullDetails?.reportedByData?._id}/ticket`)}
                      >
                        {`${ticketsInputs?.firstName.toUpperCase()} ${ticketsInputs?.lastName.toUpperCase()} `}
                      </span>
                      <span className="me-2 ms-2"> Reported Via</span> <span className="ticket-view-chanel">{ticketsInputs?.source}</span>
                    </div>
                    <div className="d-flex align-items-end">
                      {/* <small>{ticketsInputs?.reportDate ? moment(ticketsInputs?.reportDate).calendar() : "--"}</small> */}
                      <small>{ticketsInputs?.reportDate ?reportedDate(ticketsInputs?.reportDate): "--"}</small>
                    </div>
                  </div>
                  <hr />
                </h6>
                <b className='table-link'>#{ticketsInputs?.ticketId}</b>
                <h6 style={{ wordWrap: "break-word" }} className="mt-2">
                  <b>Subject :</b> {ticketsInputs?.subject}
                </h6>
                <div className="mt-4">
                  <b>Description :</b>
                </div>
                <div className="my-2 text-break" style={{ minHeight: '14vh' }} dangerouslySetInnerHTML={{ __html: ticketsInputs?.description }}></div>
                <div className="mb-4">
                  <span>
                    <b>Attachments : </b>
                  </span>
                  {ticketsInputs?.ticketFullDetails?.attachments?.length > 0 ? (
                    <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {ticketsInputs?.ticketFullDetails?.attachments?.map((file, fileIndex) => (
                          <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative pointer" onClick={() => filePreview(file)}>
                            {fileType(file, fileIndex, "TICKET")}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center border border-1" style={{ margin: 0, height: '100px' }}>
                      <span>No Attachments</span>
                    </div>
                  )}
                </div>
                <div>
                  <h6 className="mt-3">
                    <b>Other Details </b>
                  </h6>
                  <b>Project :</b> {ticketsInputs?.ticketFullDetails?.projectData?.name || "--"}
                  <h6 className="my-3">
                    <b>Created By :</b> {ticketsInputs?.ticketFullDetails?.createdByData?.firstName ?
                      `${ticketsInputs?.ticketFullDetails?.createdByData?.firstName} ${ticketsInputs?.ticketFullDetails?.createdByData?.lastName}` : "--"}
                  </h6>
                </div>

                {ticketsInputs?.ticketFullDetails && ticketsInputs?.ticketFullDetails?.agentData?.length ? (
                  <>
                    <hr />
                    <p className="mb-0">
                      <b>Assigned : </b>
                    </p>
                    <div className="mb-3">
                      {ticketsInputs?.ticketFullDetails?.agentData?.map((item) => (
                        <span key={item?._id}>
                          <Chip className="m-1" color="secondary" variant="outlined" size="small" label={`${item?.firstName} ${item?.lastName}`} onDelete={() => setUnAssignModel({ data: item?._id, open: true })} />
                        </span>
                      ))}
                    </div>
                  </>
                ) : null}
                {ticketsInputs?.ticketFullDetails?.mergeToTicket?._id && (
                  <>
                    <p className="mb-0">
                      <b>Merge Details : </b>
                    </p>
                    <div className="d-flex justify-content-between merged-detials-border" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                      <div>
                        <span>
                          <b>{ticketsInputs?.ticketFullDetails?.mergeToTicket?.subject}</b>
                        </span>
                      </div>
                      <span className="table-link">{ticketsInputs?.ticketFullDetails?.mergeToTicket?.ticketId}</span>
                    </div>
                    <div className="d-flex justify-content-between merged-detials-border mb-4" style={{ borderTop: '0', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                      <div>
                        <span>
                          <b>By : </b>
                        </span>
                        <span className="table-link">
                          {ticketsInputs?.ticketFullDetails?.mergedByData?.firstName} {ticketsInputs?.ticketFullDetails?.mergedByData?.lastName}
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>Merged On :</b>
                          {moment(ticketsInputs?.ticketFullDetails?.mergeToTicket?.createdAt).format('lll')}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex mt-2 mb-3">
                  <div className="">
                    <Button variant="contained" className="px-4" onClick={() => setReplyModel({ description: '', open: true })}>
                      Reply
                    </Button>
                  </div>
                  <div className="mx-3">
                    <Button variant="contained" className="px-4" onClick={() => setNoteModel({ description: '', open: true })}>
                      Add Note
                    </Button>
                  </div>
                  {/* <div className="">
                  <Button variant="contained" className="px-4">
                    Forward
                  </Button>
                </div> */}
                  {!ticketsInputs?.ticketFullDetails?.isMerged && (
                    <div className="me-3">
                      <Button variant="contained" className="px-4" onClick={() => setMergeModel((prev) => ({ ...prev, open: true }))}>
                        Merge
                      </Button>
                    </div>
                  )}
                  {!ticketsInputs?.ticketFullDetails?.isDeleted && <div className="">
                    <Button
                      variant="contained"
                      className="px-4"
                      onClick={() =>
                        setDeleteModal({ data: id, open: true })
                      }
                    >
                      Delete
                    </Button>
                  </div>}
                  <div className="ms-3">
                    <Button variant="contained" className="px-4">
                      Print
                    </Button>
                  </div>
                  {ticketsInputs?.ticketFullDetails?.status !== "SPAM" && <div className="ms-3">
                    <Button variant="contained" className="px-4" onClick={(e) => handleupdateSubmit(e, 'SPAM')}>
                      Spam
                    </Button>
                  </div>}
                  <div className="ms-3">
                    <Button variant="contained" className="px-4" onClick={(e) => setAssignModel((prev) => ({ ...prev, open: true }))}>
                      Assign
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Reply ,time line and Note Tab's */}
          <div className="mt-2 " style={{ backgroundColor: 'white', borderRadius: '5px' }}>
            <AppBar position="static" sx={{ background: '#fff' }}>
              <Tabs value={location.pathname}>
                {allTabs.map((item, i) => (
                  <Tab key={i} label={item.label} value={item.value} component={NavLink} to={item.value} onClick={()=>setCount(10)}/>
                ))}
              </Tabs>
            </AppBar>
            <Outlet context={{ data, methods, Navigate,fileType,filePreview }} />
          </div>

          {/* {ticketsInputs?.replies?.map((item) => (
            <div className="container-fluid contact my-4">
              <div className="row bg">
                <div className="col-lg-12">
                  <h6 className="m-0 mt-4">
                    <span className="table-link" onClick={() => Navigate(`/contacts/1234`)}>
                      {`${item?.createdByData?.firstName} ${item?.createdByData?.lastName}`}
                    </span>
                    replied
                  </h6>
                  <small>
                    <i>{moment(item?.createdAt).calendar()}</i>
                  </small>
                  <br />
                  <div className="my-2 d-flex">
                    <p>
                      <b>To : </b> {` ${ticketsInputs?.email}`}
                    </p>
                  </div>
                  <h6>
                    Subject: <b>{ticketsInputs?.subject}</b>
                  </h6>
                  <div>{item.reply}</div>
                  <div className="d-flex mt-5 mb-4">
                    <div className="">
                      <Button variant="contained" className="px-4" onClick={() => setReplyModel({ description: '', open: true })}>
                        Reply
                      </Button>
                    </div>
                    <div className="mx-3">
                      <Button variant="contained" className="px-4" onClick={() => setNoteModel({ description: '', open: true })}>
                        Add Note
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
        <div className="col-3">
          <div className="rounded bg raduus py-2" style={{ background: '#fff' }}>
            <div className="col-lg-12">
              <h5 className="text-center">Details</h5>
            </div>
            <div className="filter2">
              <div className="col-lg-11 mx-auto mb-2">
                <SelectInput
                  Lable={'Tag'}
                  multiple={true}
                  Options={allTagsList}
                  keyName={'tagName'}
                  Name="tags"
                  SelectValue={ticketsInputs.tags}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.tags}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div>
              <div className="col-lg-11 mx-auto mb-2">
                <SelectInput
                  Lable={'Source'}
                  Options={soucrsList}
                  keyName={'title'}
                  Name="source"
                  SelectValue={ticketsInputs.source}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.source}
                  variant={'outlined'}
                  isRequired={true}
                />
              </div>

              <div className="col-lg-11 mx-auto mb-2">
                <SelectInput
                  Lable={'Status'}
                  Options={statusList}
                  keyName={'title'}
                  Name="status"
                  SelectValue={ticketsInputs.status}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.status}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div>

              {ticketsInputs.status === 'CLOSED' && (
                <div className="col-11 mx-auto mb-2">
                  <InputLabel sx={{ fontSize: '13px' }}>
                    {'Close time'}
                    {/* <span className="secondery"> *</span> */}
                  </InputLabel>

                  {/* <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      // Label={'Due Date'}
                      handleDateChange={(date) =>  setTicketsInputs((prevProps) => ({ ...prevProps, close: date }))}
                      SelectedDate={ticketsInputs?.close ? moment(ticketsInputs.close) : null}
                    /> */}

                  <TimePicker
                    placeholder="Select Time"
                    use24Hours
                    showSecond={false}
                    focusOnOpen={true}
                    format="hh:mm A"
                    name={`close`}
                    onChange={(e) => {
                      setTicketsInputs((prevProps) => ({ ...prevProps, close: e }))
                    }}
                    // value={moment(ticketsInputs?.close)}
                    value={ticketsInputs?.close ? moment(ticketsInputs.close) : null}
                    className="w-100"
                  />
                </div>
              )}

              <div className="col-lg-11 mx-auto mb-2">
                <SelectInput
                  Lable={'Priority'}
                  // width={"310px"}
                  Options={priorityList}
                  keyName={'title'}
                  Name="priority"
                  SelectValue={ticketsInputs.priority}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.priority}
                  variant={'outlined'}
                // isRequired={true}
                />
              </div>
              {/* <div className="col-lg-11 mx-auto mb-2 ">
                <SelectInput
                  Lable={"Group"}
                  Options={groupList}
                  // keyName={"reportedBy.id"}
                  Name="team"
                  SelectValue={ticketsInputs.team}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.team}
                  variant={"outlined"}
                  isRequired={true}
                />
              </div>
              <div className="col-lg-11 mx-auto mb-2">
                <SelectInput
                  Lable={"Agent"}
                  // width={"310px"}
                  Options={agentList}
                  keyName={"firstName"}
                  Name="agent"
                  SelectValue={ticketsInputs.agent}
                  HandleChange={handleInputChange}
                  onBlur={handleBlur}
                  Error={error.agent}
                  variant={"outlined"}
                  isRequired={true}
                />
              </div> */}
            </div>
            <div className=" d-flex justify-content-center">
              <div className="m-2">
                <Button variant="contained" onClick={handleupdateSubmit}>
                  Update
                </Button>
              </div>
              {/* <div className="m-2">
                <Button variant="contained">Cancel</Button>
              </div> */}
            </div>
          </div>
          {ticketsInputs?.ticketFullDetails?.linkedTickets?.length ? (
            <div className="rounded bg raduus p-2 mt-4" style={{ background: '#fff' }}>
              <div className="col-lg-12">
                <h5 className="text-center">Merged Tickets</h5>
              </div>
              <hr />
              {ticketsInputs?.ticketFullDetails?.linkedTickets?.map((ticket) => (
                <div key={ticket._id} className="d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <p className="table-link mb-0">
                      {ticket?.reportedByData?.firstName} {ticket?.reportedByData?.lastName}
                    </p>
                    <p className="mb-0">
                      <span className="me-1">via</span>
                      {ticket?.source}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="table-link mb-0">{ticket?.ticketId}</p>
                    <p className="mb-0">
                      <span className="me-1">
                        <b>Created :</b>
                      </span>
                      {moment(ticket?.reportDate).format('lll')}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">{ticket?.projectData?.name}</p>
                    <p className="mb-0">
                      <span className="me-1">
                        <b>Merged :</b>
                      </span>
                      {moment(ticket?.mergeDate).format('lll')}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">{ticket?.subject}</p>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      {/* Reply Modal */}
      <Modal
        size="lg"
        show={replyModel.open}
        onHide={() => setReplyModel({ description: '', open: false })}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2">Reply</h5>
          </div>
        }
        bodyContent={
          <div className="App">
            <p className="m-0 mb-2">Description </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: CKEditorCustomToolbar,
              }}
              data={replyModel.description} // Set CKEditor data to projectInputs.notes
              onChange={(event, editor) => {
                const data = editor.getData()
                setReplyModel((prev) => ({ ...prev, description: data }))
              }}
            />
            <div className='col-12 d-flex justify-content-between align-items-center mt-4 mb-4'>
              <h5 className="">Attachments</h5>
              <Button variant="contained" onClick={() => fileInputRef.current.click()}>choose file</Button>
              <input 
               accept=".jpg,.jpeg,.png,.xls,.xlsx,.mp4,.mov,.wmv"
              type="file" ref={fileInputRef} name={'attachments'} id="choosefile" multiple onChange={handleTicketFileChange} style={{ display: 'none' }} />
            </div>
            {selectedFiles.length > 0 && <div className="col-lg-12 ">
              <div className="row border border-1" style={{ margin: 0, borderRadius: '5px' }}>
                <div>
                  <h4>Selected Files:</h4>
                  <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                    {selectedFiles.map((file, fileIndex) => (
                      <div key={fileIndex} style={{ width: '80px', height: '80px' }} className="position-relative">
                        {fileType(file, fileIndex)}
                        <Fab
                          className="m-2"
                          sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                          onClick={() => handleTicketRemoveFile(fileIndex)}
                        >
                          <ClearIcon sx={{ fontSize: '15px' }} />
                        </Fab>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>}

            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button variant="contained" onClick={addReply}>
                  Add
                </Button>
              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => setReplyModel({ description: '', open: false })}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        }
      />
      {/* Attachment Modal */}
      <Modal
        size="lg"
        show={showPDFModal.open}
        onHide={() => setShowPDFModal((pre) => ({ ...pre, open: false }))}
        headerContent={''}
        bodyContent={
          <div className="">
            {showPDFModal?.data ? (
              showPDFModal?.data?.split('.').pop() === 'pdf' ? (
                <iframe src={uploadFileUrlTicket + showPDFModal?.data} title={showPDFModal?.data} style={{ width: '100%', height: '100%' }} />
              ) : (
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={uploadFileUrlTicket + showPDFModal?.data} alt="" />
              )
            ) : (
              <div>No attachment available</div>
            )}
          </div>
        }
      />

      {/* Note Modal */}
      <Modal
        size="lg"
        show={noteModel.open}
        onHide={() => setNoteModel({ description: '', open: false })}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2">Note</h5>
          </div>
        }
        bodyContent={
          <div className="App">
            <p className="m-0 mb-2">Description </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: CKEditorCustomToolbar,
              }}
              data={noteModel?.description} // Set CKEditor data to projectInputs.notes
              onChange={(event, editor) => {
                const data = editor.getData()
                setNoteModel((prev) => ({ ...prev, description: data }))
              }}
            />
            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button variant="contained" onClick={AddNote}>
                  Add
                </Button>
              </div>
              <div className="m-2">
                <Button variant="outlined" onClick={() => setNoteModel({ description: '', open: false })}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* Merge Modal */}
      <Modal
        size="md"
        show={mergeModel.open}
        onHide={() => setMergeModel((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Merge to</h5>}
        bodyContent={
          <div className="">
            <div className="p-3">
              {/* <div className="border rounded my-2 p-3"> */}
              {/* <h5>Tickets</h5>
                <hr /> */}
              <div className="row">
                <div className="col-12 mt-2 text-center">
                  <label htmlFor="" className="w-100">
                    <TextField sx={{ width: '380px' }} type="text" onChange={(e) => setSearchTickets(e.target.value.toLowerCase())} value={searchTickets} placeholder="Search" size="small" />
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-11 mt-2 mx-auto" style={{ height: '350px', overflowY: 'auto' }}>
                  {fullTicketList
                    .filter((cur) => cur.ticketId.toLowerCase().includes(searchTickets))
                    .map((item, index) => (
                      <article key={index} className={`${slotId === index ? 'slot_InActive' : 'slot_Active'} article_show_status `} id={item?.status !== 'Available' && 'Booked_slot'}>
                        <input
                          type="checkbox"
                          id="feature1"
                          defaultChecked={item?.isChecked}
                          onChange={(e) => handleCheckSlots(e.target.checked, item, index)}
                        // disabled={DisableTimeSlot(item)}
                        />
                        <div className="row p-2">
                          <div className="col-12  d-flex justify-content-between ">
                            <p className="mb-0">Ticket Id: {item.ticketId}</p>
                            <p className="mb-0">{moment(item.createdAt).calendar()}</p>
                          </div>

                          <div style={{ wordWrap: "break-word" }} className="col-12 d-flex justify-content-start ">
                            <p className="mb-0" >Subject: {item.subject.slice(0, 80)}</p>
                          </div>
                        </div>
                      </article>
                    ))}
                </div>
              </div>
              {/* </div> */}

              {!ticketsInputs?.ticketFullDetails?.isMerged && (
                <div className="text-center mt-2">
                  <Button variant="contained" onClick={!ticketsInputs?.ticketFullDetails?.isMerged ? TicketMerge : toast.error('Already merged')}>
                    Yes
                  </Button>
                  <Button variant="outlined" className="ms-2" onClick={() => setMergeModel((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                    No
                  </Button>
                </div>
              )}
            </div>

            {/* <div className="col-lg-10">
              <SelectInput
                Lable={"Tickets"}
                // width={"310px"}
                multiple={true}
                Options={fullTicketList}
                keyName={"ticketId"}
                Name="ticket"
                SelectValue={ticketsInputs.ticket}
                HandleChange={handleInputChange}
                onBlur={handleBlur}
                // Error={error.tags}
                variant={"outlined"}
                isRequired={true}
              />
            </div>
            <div>
              <Button variant="contained" onClick={TicketMerge}>
                Yes
              </Button>
              <Button
                variant="outlined"
                className="ms-2"
                onClick={() =>
                  setMergeModel((prev) => ({ ...prev, open: false }))
                }
                data-bs-dismiss="modal"
              >
                No
              </Button>
            </div> */}
          </div>
        }
      />

      {/* Delete Modal */}
      <Modal
        size="md"
        show={deleteModal.open}
        onHide={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Delete?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to delete? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={handleDelete}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setDeleteModal((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />

      {/*  Assign Modal */}
      <Modal
        size="lg"
        show={assignModel.open}
        onHide={() => {
          CloseFucntion('assignModel')
        }}
        headerContent={
          <div className="d-flex justify-content-between pb-2">
            <h5 className="me-2">Assign</h5>
          </div>
        }
        bodyContent={
          <div className="App">
            {assignInputs?.map((item, index) => (
              <div key={index}>
                <div className="row">
                  {/* <span className='border rounded '><b>{index + 1}</b></span> */}
                  <div className="col-lg-12 my-2">
                    <TextInput
                      Lable="Task Name"
                      Name="taskName"
                      variant="outlined"
                      ClassName="form-field-design "
                      Require={true}
                      HandleInputChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      Value={item.taskName}
                      width={100}
                    //   Error={error.ticketId}
                    />
                    {assignErrors[index]?.taskName && <span className='text-danger'>{assignErrors[index].taskName}</span>}
                  </div>

                  <div className="col-lg-12 my-2">
                    <label htmlFor="">
                      <sub>Description </sub>
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: CKEditorCustomToolbar,
                      }}
                      data={item.description}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        handleAssignInputChange({ target: { name: 'description', value: data } }, index);

                        setAssignErrors((prevErrors) => {
                          const updatedErrors = [...prevErrors];
                          updatedErrors[index] = { description: '' };
                          return updatedErrors;
                        });
                      }}
                      onBlur={(event, editor) => {
                        if (!item.description) {
                          // Set the description error if it's empty on blur
                          setAssignErrors((prevErrors) => {
                            const updatedErrors = [...prevErrors];
                            updatedErrors[index] = { description: 'Please enter description' };
                            return updatedErrors;
                          });
                        }
                      }}
                    />
                    {assignErrors[index]?.description && <p className="text-danger">{assignErrors[index]?.description}</p>}
                  </div>

                  <div className="col-lg-6 my-2">
                    <MultipleSelect
                      label="Project"
                      isRequired={true}
                      // multiple={true}
                      options={projectListData}
                      searchBy={"name"}
                      keyName={"name"}
                      name={"project_id"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "project_id", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item?.project_id}
                    // disabled={true}
                    // error={error.project_id}
                    />

                    {/* <SelectInput
                      Lable={'Project '}
                      // width={"310px"}
                      Options={projectListData}
                      keyName={'name'}
                      Name="project_id"
                      ClassName="form-field-design"
                      SelectValue={item.project_id}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    {assignErrors[index]?.project_id && <span className='text-danger'>{assignErrors[index]?.project_id}</span>}
                  </div>

                  <div className="col-lg-6 my-2">
                    <MultipleSelect
                      label="Priority"
                      isRequired={true}
                      // multiple={true}
                      options={priorityList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"priority"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "priority", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.priority}
                    // disabled={true}
                    // error={error.priority}
                    />
                    {/* <SelectInput
                      Lable={'Priority'}
                      // width={"310px"}
                      Options={priorityList}
                      keyName={'title'}
                      Name="priority"
                      ClassName="form-field-design"
                      SelectValue={item.priority}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    {assignErrors[index]?.priority && <span className='text-danger'>{assignErrors[index]?.priority}</span>}
                  </div>

                  <div className="col-lg-6 my-2">
                    <MultipleSelect
                      label="Assign To"
                      isRequired={true}
                      // multiple={true}
                      options={assignToListData}
                      searchBy={"name"}
                      keyName={"name"}
                      name={"assignTo_id"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "assignTo_id", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item?.assignTo_id}
                    // disabled={true}
                    // error={error.assignTo_id}
                    />

                    {/* <SelectInput
                      Lable={'Assign To '}
                      extrakey={"isDeleted"}
                  message={"Inactive"}
                      Options={assignToListData}
                      keyName={'title'}
                      Name="assignTo_id"
                      ClassName="form-field-design"
                      SelectValue={item.assignTo_id}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      //   onBlur={handleBlur}
                      //   Error={error.priority}
                      variant={'outlined'}
                      isRequired={true}
                    /> */}
                    {assignErrors[index]?.assignTo_id && <span className='text-danger'>{assignErrors[index]?.assignTo_id}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">

                    <MultipleSelect
                      label="Tag"
                      // isRequired={true}
                      multiple={true}
                      options={tagListData}
                      searchBy={"tagName"}
                      keyName={"tagName"}
                      name={"tags"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "tags", value: e } }, index)}
                      value={"_id"}
                      selectedValues={item.tags}
                    // disabled={true}
                    // error={error.tags}
                    />
                    {/* <SelectInput
                      Lable={'Tag'}
                      multiple={true}
                      Options={tagListData}
                      keyName={'tagName'}
                      Name="tags"
                      SelectValue={item.tags}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      // Error={error.tags}
                      variant={'outlined'}
                    /> */}
                  </div>

                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Start Date '}
                      handleDateChange={(date) => handleDateChange(date, 'assignStartTime', index)}
                      SelectedDate={assignInputs[index]?.assignStartTime}
                    />
                    {assignErrors[index]?.assignStartTime && <span className='text-danger'>{assignErrors[index]?.assignStartTime}</span>}
                  </div>
                  <div className="col-lg-6 my-2">
                    <DesktopDateTimePicker
                      className="w-100 form-field-design"
                      isRequired={true}
                      Label={'Due Date'}
                      handleDateChange={(date) => handleDateChange(date, 'assignEndTime', index)}
                      SelectedDate={assignInputs[index]?.assignEndTime}
                    />
                    {assignErrors[index]?.assignEndTime && <span className='text-danger'>{assignErrors[index]?.assignEndTime}</span>}
                  </div>
                  <div className="col-lg-12 my-2 position-relative mt-3">
                    <div className="col-4 position-absolute text-end pe-3 pb-3 " style={{ right: '0', bottom: '20px', zIndex: '1' }}>
                      <Switch name="ReminderAction" checked={item?.ReminderAction} onChange={(e) => handleAssignInputChange(e, index)} />
                    </div>
                    <SelectInput
                      LabelButton
                      Lable={'Reminder'}
                      // multiple={true}
                      Options={ReminderList}
                      keyName={'title'}
                      Name="minutesBeforeReminder"
                      SelectValue={item.minutesBeforeReminder}
                      HandleChange={(e) => handleAssignInputChange(e, index)}
                      // onBlur={handleBlur}
                      // Error={error.tags}
                      variant={'outlined'}
                      // isRequired={true}
                      disabled={item?.ReminderAction === false}
                    />
                  </div>

                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Task Type"
                      isRequired={true}
                      // multiple={true}
                      options={taskTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"taskType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "taskType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.taskType}
                    />
                    {assignErrors[index]?.taskType && <span className='text-danger'>{assignErrors[index]?.taskType}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="SubTask"
                      isRequired={true}
                      // multiple={true}
                      options={SubTaskList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"subTask"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "subTask", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.subTask}
                    />
                    {assignErrors[index]?.subTask && <span className='text-danger'>{assignErrors[index]?.subTask}</span>}
                  </div>

                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Task Category"
                      isRequired={true}
                      // multiple={true}
                      options={TaskCategoryList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"taskCategory"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "taskCategory", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.taskCategory}
                    />
                    {assignErrors[index]?.taskCategory && <span className='text-danger'>{assignErrors[index]?.taskCategory}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Complexity"
                      isRequired={true}
                      // multiple={true}
                      options={complexityList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"complexity"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "complexity", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.complexity}
                    />
                    {assignErrors[index]?.complexity && <span className='text-danger'>{assignErrors[index]?.complexity}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Hours Type"
                      isRequired={true}
                      // multiple={true}
                      options={hoursTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"hoursType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "hoursType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.hoursType}
                    />
                    {assignErrors[index]?.hoursType && <span className='text-danger'>{assignErrors[index]?.hoursType}</span>}
                  </div>
                  <div className="col-lg-6 mt-2">
                    <MultipleSelect
                      label="Work Type"
                      isRequired={true}
                      // multiple={true}
                      options={workTypeList}
                      searchBy={"title"}
                      keyName={"title"}
                      name={"workType"}
                      onChange={(e) => handleAssignInputChange({ target: { name: "workType", value: e } }, index)}
                      value={"value"}
                      selectedValues={item?.workType}
                    />
                    {assignErrors[index]?.workType && <span className='text-danger'>{assignErrors[index]?.workType}</span>}
                  </div>


                </div>

                <div className="col-12 d-flex justify-content-between align-items-center">
                  <h5 className="mt-4 mb-2">Attachments</h5>
                  {item?.attachments?.length < 5 && <Button variant="contained" onClick={() => fileInputRef[index].current.click()}>
                    choose file
                  </Button>}
                  <input type="file" ref={fileInputRef[index]} name={'attachments'} id="choosefile" multiple onChange={(e) => handleFileChange(e, index)} style={{ display: 'none' }} />
                </div>
                <div className="col-lg-12">
                  {item?.attachments?.length > 0 && (
                    <div className="row border border-1" style={{ margin: 0 }}>
                      {/* <h4>Selected Files:</h4> */}
                      {/* <ul> */}
                      <div className="d-flex justify-content-start w-100 py-4 " style={{ gap: '20px' }}>
                        {item?.attachments?.map((file, fileIndex) => (
                          <div style={{ width: '80px', height: '80px' }} className="position-relative">
                            {fileType(file, fileIndex, "TASK")}
                            <Fab
                              className="m-2"
                              sx={{ color: '#de092ed1', position: 'absolute', right: '-15px', top: '-5px', width: '20px', height: '20px', minHeight: '10px' }}
                              onClick={() => handleRemoveFile(index, fileIndex, file)}
                            >
                              <ClearIcon sx={{ fontSize: '15px' }} />
                            </Fab>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-12 my-2">
                  <label htmlFor="">
                    <sub>Notes </sub>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: CKEditorCustomToolbar,
                    }}
                    data={item.notes} // Set CKEditor data to projectInputs.notes
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      handleAssignInputChange({ target: { name: 'notes', value: data } }, index)
                    }}
                  />
                </div>
                {/* {!taskModel.data && ( */}
                <div className="col-lg-12 text-end mt-3">
                  <Button className="dropDownButtons11 me-2" onClick={() => AssignMultipleTask(index)}>
                    <AddIcon />
                  </Button>
                  {assignInputs.length > 1 && ( // Only show the button if there is more than one task
                    <Button variant="outlined" color="error" onClick={() => removeAssign(index)}>
                      {' '}
                      <ClearIcon />
                    </Button>
                  )}
                </div>
                {/* )} */}
              </div>
            ))}

            <div className=" d-flex justify-content-end ">
              <div className="m-2">
                <Button
                  variant="outlined"
                  onClick={() => {
                    CloseFucntion('assignModel')
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="m-2">
                <Button variant="contained" onClick={MultipleAgentsAssignTicket}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        }
      />

      {/* Un-Assign Modal */}
      <Modal
        size="md"
        show={unAssignModel.open}
        onHide={() => setUnAssignModel((prev) => ({ ...prev, open: false }))}
        headerContent={<h5>Un-Assign?</h5>}
        bodyContent={
          <div className="">
            <p>Are you sure you want to Un-Assign? </p>
            <div className="d-flex justify-content-end mt-4">
              <Button variant="contained" onClick={UnassignAgent}>
                Yes
              </Button>
              <Button variant="outlined" className="ms-2" onClick={() => setUnAssignModel((prev) => ({ ...prev, open: false }))} data-bs-dismiss="modal">
                No
              </Button>
            </div>
          </div>
        }
      />
    </>
  )
}

export default Email
