import React, { useState } from 'react'
import Table from '../../../components/Table/Table';
import { Pagination } from '../../../components/pagination/Pagination';
import UseBreakReport from './Hooks/useBreakReport';
import BreakFilter from './Hooks/breakFilter';
import { Spinner } from '../../../components/Spinner/Spinner';
import moment from 'moment';

const BreakReport = () => {

  const { data, methods } = UseBreakReport()
  const { spinner, breaksData, controller, filterDate, totalCount } = data
  const { handlePagination } = methods
  const users = [
    {
      name: "Aashish",
      date: "July 22, 2024",
      teaBreak: "10:00 AM - 10:15 AM",
      lunchBreak: "1:00 PM - 2:00 PM",
      afternoonTeaBreak: "4:00 PM - 5:00 PM"
    },
    {
      name: "Shivam",
      date: "July 22, 2024",
      teaBreak: "10:30 AM - 10:45 AM",
      lunchBreak: "12:30 PM - 1:30 PM",
      afternoonTeaBreak: "4:00 PM - 5:00 PM"
    }
    // Add more users as needed
  ];

  const TableHead = [
    { label: 'Name', id: '' },
    { label: 'Date', id: '' },
    { label: 'Morning Tea Break', id: '' },
    { label: 'Lunch Break', id: '' },
    { label: 'Afternoon Tea Break', id: '' },
  ]
  // const formatBreakTime = (breaks, breakType) => {
  //   const breakInfo = breaks?.find(breakItem => breakItem?.name?.includes(breakType));
  //   if (breakInfo && breakInfo.breakstartTime && breakInfo.breakendTime) {
  //     const startTime = moment(breakInfo.breakstartTime).format('LT');
  //     const endTime = moment(breakInfo.breakendTime).format('LT');
  //     return `${startTime} - ${endTime}`;
  //   }
  //   return '--';
  // };

  const formatBreakTime = (breaks, breakType) => {
    const breakInfo = breaks?.find(breakItem => breakItem?.name?.includes(breakType));
    if (breakInfo && breakInfo.breakstartTime && breakInfo.breakendTime) {
      const startTime = moment(breakInfo.breakstartTime);
      const endTime = moment(breakInfo.breakendTime);

      // Round down to the nearest minute for comparison
      const actualDuration = moment.duration(endTime.startOf('minute').diff(startTime.startOf('minute'))).asMinutes();
      const specifiedDuration = breakInfo.duration;

      const startTimeFormatted = startTime.format('LT');
      const endTimeFormatted = endTime.format('LT');

      const textColor = actualDuration > specifiedDuration ? 'red' : 'black';

      return (
        <span style={{ color: textColor }}>
          {`${startTimeFormatted} - ${endTimeFormatted} (${actualDuration} Minutes)`}
        </span>
      );
    }
    return '--';
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="container-fluid py-3">
        <div className='row'>
          <div className='col-lg-10'>
            <div className="mt-2">
              <table className="table">
                <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                <tbody>
                  {breaksData?.map((item, index) => {
                    return (
                      <tr
                        key={item._id + index}
                        className={item.isDeleted ? 'table-active text-start rounded-row tablebess border-top-0 border-start-0 border-end-0 border-light' : `text-start rounded-row tablebess `}>
                        <td > {item?.name || '--'} </td>
                        <td> {moment(filterDate).format('DD-MM-YYYY') || '--'} </td>
                        <td> {formatBreakTime(item.shiftBreaks, 'Morning Tea Break')} </td>
                        <td>{formatBreakTime(item.shiftBreaks, 'Lunch Break')}</td>
                        <td> {formatBreakTime(item.shiftBreaks, 'Afternoon Tea Break')} </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!users.length && <div className="text-center">No record found</div>}
              <Pagination count={totalCount} controller={controller} setController={handlePagination} />
            </div>

          </div>
          <div className='col-lg-2'>
            <BreakFilter data={data} methods={methods} />
          </div>
        </div>
      </div>
    </>
  )
}

export default BreakReport
