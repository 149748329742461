import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField, InputLabel } from '@mui/material'


export default function index({ SelectedDate, handleDateChange, Label, name, variant, className, size, isRequired, minDate, views }) {
    return (
        <>
            <div className={`${className} m-3`}>
                <InputLabel id="demo-simple-select-label" sx={{ fontSize: "13px" }}>{Label}{isRequired && <span className="text-danger"> *</span>}</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        views={views}
                        className='customDatePickerWidth'
                        minDate={minDate}
                        inputFormat="MM/dd/yyyy"
                        value={SelectedDate || null}
                        onChange={handleDateChange}
                        onBlur={handleDateChange}
                        name={name}
                        size={size}
                        renderInput={(params) => <TextField variant={variant ? variant : "standard"}  {...params} error={false}
                        
                        />}
                    />
                </LocalizationProvider>
            </div>
        </>
    )
}
