import React, { useRef } from 'react'
import Table from '../../components/Table/Table'
// import { ToastContainer, toast } from 'react-toastify'
import { Spinner } from '../../components/Spinner/Spinner'
import moment from 'moment'
import IdleUserFilter from './idleUserFilter'
import UseIdleUser from './Hooks/useIdleUser'
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { ToastContainer } from 'react-toastify'
const IdleUsers = () => {
    const TableHead = [
        { label: '#', id: '' },
        { label: 'Name', id: '' },
        { label: 'Email', id: '' },
        { label: 'Role Title', id: '' },
    ]

    const { data, methods } = UseIdleUser()
    const {
        idleListData,
        spinner,
    } = data
   const {idleUserExports}=methods



    function capitalize(str) {
        return str.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <div>
            <ToastContainer/>
            {spinner && <Spinner />}
            <div className="container-fluid py-3">
                <div className="row py-3">

                    <div className="col-lg-10 ticet">
                        <div className="bg shadow raduus">
                            {
                                idleListData?.length>0 &&
                                <div className='d-flex justify-content-end p-3'>
                                    <Button variant="contained" startIcon={<LocalPrintshopIcon />}  className="px-3 me-2" onClick={handlePrint}>
                                        Print
                                    </Button>
                                    <Button startIcon={<ExitToAppIcon />} variant="contained" onClick={idleUserExports}>Export</Button>
                                </div>
                            }
                            <div ref={componentRef}>
                                <div className="mt-2">
                                    {idleListData?.map((item) => {
                                        return (
                                            <div key={item._id} >
                                                <div className="d-flex flex-column p-2 pointer">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <div className="mt-3">
                                                            <b>Date :</b> {moment(item?.date).format('MM-DD-YYYY')}
                                                        </div>
                                                        <span className='mt-3'>
                                                            <b>Total Idle Users :</b> {item?.items?.length}
                                                        </span>
                                                    </div>
                                                    <div className="mt-2 myTable" >
                                                        <table className="table">
                                                            <Table TableHeadData={TableHead} rowCount={TableHead.length} />
                                                            <tbody>
                                                                {
                                                                    item?.items?.map((item, index) =>
                                                                        <tr className={index % 2 === 0 ? 'light-background' : 'dark-background'} key={index}>
                                                                            <td className='text-start'>{index + 1}</td>
                                                                            <td className='text-start ps-1'>{`${capitalize(item?.firstName)} ${capitalize(item?.lastName)}`}</td>
                                                                            <td className='text-start ps-1'>{item?.email}</td>
                                                                            <td className='text-start ps-1'>{item?.roleTitle?.roleTitle}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <hr style={{ margin: '0.01rem' }} />
                                            </div>
                                        );
                                    })}
                                    {!idleListData?.length && <div className="text-center py-5">No record found</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 ticet mt-2">
                        <IdleUserFilter data={data} methods={methods} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IdleUsers
